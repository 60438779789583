<template>
  <div class=''>
    <v-card :height="this.height + 150" class='branco a pl-6'>
      <DownloadIcon v-bind:data="data.download" v-if="isClient"/>

      <v-tabs
        class='intNumber'
        background-color='#FAFAFA'
        color="#0000008a"
        active-class='activetab3'
        hide-slider
        v-model="intN"
        height="38"
      >
        <v-tab class='nomargin'>
          <v-icon> mdi-numeric-1-box-outline </v-icon>
        </v-tab>
        <v-tab class='nomargin'>
          <v-icon>  mdi-percent  </v-icon>
        </v-tab>
      </v-tabs>

      <v-card-title class='title ml-n4'>
        Qr Codes lidos
      </v-card-title>

      <v-card class="branco mt-2" width="423" height="48" elevation="0">
        <v-row>
          <p class='readNumber my-auto mr-2 ml-3'> {{data.total ? data.total : null}} </p>
          <div class='readText my-auto'>
            <p class='my-auto'> Total de QR <br>
          Codes lidos </p>
          </div>
          <v-spacer/>
          <v-tabs v-model="tab" :color="blue" class='readtabs' background-color="branco">
            <v-tab class='readtab capitalize'>
              Total
            </v-tab>
            <v-tab  class='readtab capitalize'>
              Semana
            </v-tab>
            <v-tab  class='readtab capitalize'>
              Dia
            </v-tab>
        </v-tabs>
        </v-row>
      </v-card>

      <v-card :height="height" class='mt-3 u branco' elevation="0">
        <bar-chart :stacked="true" :discrete="true" :legend="false" :colors="[blue1rgba, lightbluerba]" :height="height + 'px'" class='read' :data="intedData[tabed]" color/>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  computed: {
    intedData () {
      const obj = JSON.parse(JSON.stringify(this.data.chart))
      if (this.intNumber) { return obj } else {
        // eslint-disable-next-line no-unused-vars
        for (const [key1, value1] of Object.entries(this.data.chart)) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(this.data.chart[key1][0].data)) {
            const base = this.data.chart[key1][0].data[key] + this.data.chart[key1][1].data[key]
            const holder1 = (this.data.chart[key1][0].data[key] / base) * 100
            const holder2 = (this.data.chart[key1][1].data[key] / base) * 100
            obj[key1][0].data[key] = holder1
            obj[key1][1].data[key] = holder2
          }
        }
      }
      return obj
    },
    height () {
      let numb = 0
      if (typeof this.data.chart[this.tabed] === 'undefined') {
        return (50)
      }
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.data.chart[this.tabed][0].data)) {
        numb = numb + 1
      }
      return (numb * 49)
    },
    intNumber () {
      return !this.intN
    },
    tabed () {
      if (this.tab === 0) {
        return 'Total'
      }
      if (this.tab === 1) {
        return 'Semana'
      }
      return 'Dia'
    }
  },
  props: ['data', 'isClient'],
  data: () => ({
    tab: 0,
    intN: false,
    settings: {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    },
    eko: '150px',
    blue: '#134B97',
    blue1rgba: 'rgba(5,94,169,1)',
    bluergba: 'rgba(19,75,151,1)',
    lightbluerba: 'rgba(198,206,226,1)',
    grey1rgba: 'rgba(189,189,189,1)',
    red: '#d2232a',
    redrgba: 'rgba(210,35,42,1)',
    tamanho: '600px'
  })
}
</script>

<style scoped>
.u{
  width: 919px;
}
.a{
  width: 1060px;
}
@media screen and (max-width: 1359px) {
.a{
  width: calc(100vw - 242px - 52px);
}
.u{
  width: calc(100vw - 242px - 52px - 141px);
}
}
@media screen and (max-width: 1100px) {
  .intNumber{
  top: 6px !important;
  right: 216px !important;
  z-index: 999;
}
.a{
  width: 475px;
}
.u{
  width: 380px;
}
}

.intNumber{
  position: absolute;
  top: 62px;
  right: 116px;
  width: 80px !important;
}
/* read */
.read{
  width: 919px;
  height: 231px;
}
.readNumber{
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: -0.5px;
color: #134B97;
}
.readtab{
  width: 74px;
}
.readtabs{
  width: 222px;
}
.readText{
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 120%;
/* or 17px */

display: flex;
align-items: flex-end;
letter-spacing: -0.5px;

color: #000000;
}
.title{
font-family: 'Co Headline Corp' !important;
font-size: 18px;
line-height: 22px;
color: #000000;
}
  .activetab3{
    background-color: #0000001f;
  }
</style>
