<template>
  <div>
    <!-- A linha com os cards dentro -->
    <div>
      <v-row class='mt-0'>

        <!-- A repetição do componente html para cada empresa -->
        <v-col v-for="item in this.cards.list" :key="item.id" class='mx-auto'>
          <v-card width="340" height="240" class='mx-auto'>

            <!-- A maioria dos dados de um card estão amarrados nesse v-card para tornar possível
            desabilitar eles com o switch -->
            <v-card class='nomargin' :disabled='!item.situation' elevation="0">

            <!-- O chip com inicial e nome -->
              <v-card-title class='cardTitle'>
                <v-card width="40" height="40" color="azul" class='rounded-circle mr-5 chip'>
                  <div class='chip py-1'>
                    <label class='txtwhite chiptxt'> {{item.name.substr(0,1)}} </label>
                  </div>
                </v-card>
                  {{item.name}}
              </v-card-title>

              <!-- as informações -->
              <div class='cardText'>
                <v-row>
                  <v-icon class='mr-8 ml-5 my-4'> mdi-account </v-icon>
                  <p class='cardtxt'> {{ item.nameUser }} </p>
                </v-row>
                <v-row>
                  <v-icon class='mr-8 ml-5 my-6'> mdi-clipboard-account </v-icon>
                  <div class='line'>
                    <p class='cardtxt'> {{ item.seller }} </p>
                    <p class='mt-0 regional'>{{item.regional}} </p>
                  </div>
                </v-row>
              </div>

              <!-- as ações -->
              <v-card-actions class='cardOptions'>
                <v-icon class='mr-6' @click='opendialog (item, "edit")' color='azul'>
                  mdi-pencil
                </v-icon>
                <v-icon @click='opendialog (item, "sendEmail")' class='mr-6' color='azul'>
                  mdi-email
                </v-icon>
                <v-icon @click='opendialog (item, "delete")' class='' color='azul'>
                  mdi-delete
                </v-icon>
              </v-card-actions>

            </v-card>

            <!-- o botão para atilet/ desatileta empresa -->
            <v-switch v-if='render' @click='situationChanger(item)' v-model='item.situation' :ripple='false' color=' roxo' class='mr-4 switch' />

          </v-card>
        </v-col>
      </v-row>

      <!-- A linha com detalhes da visualização -->
      <v-row  v-if='this.List.length >= 1' class='nomargin'>
        <v-spacer/>
        <p class=filtertext> Elementos por Página: </p> <div> <v-select @change='updatepage(0)' v-model="numbercards" :items="[ 2, 6, 12, 18]" color="blue" height="12" class='filterselect'/> </div>
        <p class='filtertext pr-5'> {{ cards.number[0] + 1 }}-{{ topCard }} de {{List.length}} </p>
        <v-icon class='icon' color='pr-1' @click='changepagination(-1)' :ripple="false">
          mdi-chevron-left
        </v-icon>
        <v-icon class='icon' color='mr-4' @click='changepagination(1)' > mdi-chevron-right </v-icon>
      </v-row>

    </div>

    <!-- O aviso -->
    <div :hidden='this.List.length >= 1' class='centertxt'>
      <div class='spacervertical' />
      <h1 class='CoHead centertxt'> Nenhum resultado </h1>
      <p> O filtro aplicado não retornou nenhum resultado, tente alterá-lo </p>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    render: true,
    pagination: 0,
    numbercards: 6
  }),

  watch: {
    List: function () {
      this.pagination = 0
    }
  },

  computed: {
    List () { return this.$store.getters.Filteredclients },
    config () { return { headers: { Access_Token: this.msal.idToken } } },
    topCard () {
      // Pegando qual o card aonde a visualização atual começa
      return Math.max.apply(Math, this.cards.number) + 1
    },
    cards () {
      // Calculando e separando qual o primeiro e qual o último card que devem ser mostrados
      const holder = { list: [], number: [] }
      const start = this.numbercards * this.pagination
      const finish = this.numbercards * (this.pagination + 1)
      for (let i = start; i < this.List.length; i++) {
        holder.list.push(this.List[i])
        holder.number.push(i)
        if (i >= (finish - 1)) { break }
      }
      return holder
    }
  },

  methods: {

    opendialog (item, dialog) {
      this.$store.commit('EditedClientEdit', Object.assign({}, item))
      this.$store.commit('dialogEdit', this.$bases.adminPortal.dialogs[dialog])
    },

    // Para evitar bugs de visualização, essa função avisa quando devemos puxar a tela pra cima
    updatepage (item) {
      if (item !== null) {
        this.pagination = item
      }
      this.$store.commit('adminOptions', { temp: { update: true } })
    },

    // Há alguns problemas com o switch de empresas, aqui temos alguns workarounds para funcionar
    async situationChanger (item) {
      if (this.$store.state.adminPortal.options.filter.situacao != null) {
        this.render = false
        await this.$store.commit('adminOptions', { temp: { deleted: true } })
        this.render = true
        this.updatepage()
      }
      const holder = item
      holder.situation = holder.situation ? 1 : 0
      await this.$functions.put('/updateSituation/' + item.id, holder, this.config)
    },

    changepagination (item) {
      this.pagination = this.pagination + parseInt(item)
      if (this.pagination <= -1) {
        this.pagination = 0
      } else if ((this.pagination * this.numbercards) + 1 > this.List.length) {
        this.pagination = this.pagination - 1
      } else { this.updatepage(this.pagination) }
    }
  }
}
</script>

<style scoped>

  /* Classes para posicionamento / tamanho */
  .cardTitle {
    height: 70px;
    width: 340px;
  }

  .cardText {
    padding-left: 16px;
    height: 120px;
    width: 340px;
  }

  .cardOptions {
    height: 48px;
    width: 170px;
    margin-top: 4px;
    margin-left: 80px
  }

  .switch {
    position: absolute;
    bottom: 0px;
    left: 16px;
    z-index: 1;
  }

  .chip {
    justify-content: center !important;
    text-align: center !important;
    width: 40px;
    height: 40px;
  }

  .icon {
    margin-top: 12px;
    height: 24px;
    width: 24px;
  }

  .filterselect {
    margin: 0px;
    padding-left: 5px;
    padding-top: 20px;
    padding-right: 20px;
    width: 75px !important;
    font-size: 12px !important;
    line-height: 166%;
    letter-spacing: 0.4px;
  }

  .filterline {
    height: 36px;
    width: 346px;
  }

  .spacervertical{
    margin: 1px;
    min-height: calc(50vh - 200px);
  }

  .line{
    height: 72px;
  }

  /* Classes para formatação de texto */
  .filtertext{
    margin-top: 16px;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
  }

  .cardtxt{
    font-size: 16px;
    line-height: 150%;
    margin-top: 14px;
    margin-bottom: 0px;
    letter-spacing: 0.15px;
  }

  .chiptxt{
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 100%;
    align-self: center;
  }

  .regional{
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
  }

</style>
