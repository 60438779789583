<template>
    <div>
        <FilterBar v-bind:filter="filter"/>
        <ClientsTable v-bind:data="filteredList"/>

        <!-- Testar passar uma função para as de delete e confirm, ver se isso funciona através de componentes
          Se funcionar, mudar o padrão usado atualmente-->

        <!-- O único diálogo vai ser de criação, edição e deletar (que pode ser separado dos outros) -->

        <!-- Usar state global para controlar o componente de diálogo, mas conectar ele a um state só dele,
          pensar em como tornar isso o mais leve possível  -->
        <AccessDialogs v-bind:getInfo="getInfo"/>
    </div>
</template>

<script>
import ClientsTable from './ClientsTable.vue'
import FilterBar from './FilterBar.vue'
import AccessDialogs from './AccessDialogs.vue'

export default {
  data: () => ({
    accessTypeTEMP: [{ name: 'Admin', bdColor: '#F6BEC1', textColor: '#d2232a' }, { name: 'Cliente', bdColor: '#ffe28a', textColor: '#ff860e' }, { name: 'Promotor', bdColor: '#e6ecf4', textColor: '#00428a' }, { name: 'Promotor Mestre', bdColor: '#e6ecf4', textColor: '#00428a' }],
    data: [],
    filter: {
      search: '',
      createdBy: null,
      accessType: null,
      createdByArray: [],
      accessTypeArray: ['Admin', 'Promotor Mestre']
      // accessTypeArray: ['Admin', 'Cliente', 'Promotor', 'Promotor Mestre']
    }
  }),
  components: {
    ClientsTable,
    FilterBar,
    AccessDialogs
  },

  computed: {
    filteredList () {
      const array = []
      this.data.forEach(element => {
        const filterCreatedBy = (!this.filter.createdBy || this.filter.createdBy === element.name)
        const filterAccessType = ((!this.filter.accessType || this.filter.accessType === element.accessTypeName) && (element.accessTypeName !== 'Promotor' && element.accessTypeName !== 'Cliente'))
        const filterSearch = JSON.stringify(Object.values(element)).toLowerCase().includes(this.filter.search.toLowerCase())
        if (filterCreatedBy && filterAccessType && filterSearch) {
          array.push(element)
        }
      })

      return array
    }
  },

  async created () {
    await this.getInfo()
  },

  methods: {
    async getInfo () {
      const r = await this.$functions.get('/v0/accounts/accesses', { headers: { Access_Token: this.msal.idToken } })
      const array = []
      const tempObj = {}
      const accountsObj = {}

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(r)) {
        accountsObj[value.idAccount] = value.name
        tempObj[value.name] = true
      }

      for (const [key, value] of Object.entries(r)) {
        array.push({ ...value, idAccess: key, accessTypeName: this.accessTypeTEMP[value.accessType].name, createdBy: accountsObj[value.createdBy] || 'SISTEMA' })
      }

      const responsavelArray = []
      for (const [key, value] of Object.entries(tempObj)) {
        if (value) { responsavelArray.push(key) }
      }

      this.filter.createdByArray = responsavelArray
      this.data = array
      return true
    }
  }
}
</script>
