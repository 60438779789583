<template>
  <div class='fullwidth' style="margin-top: 28px;" v-if="show">
    <v-slide-x-transition>
      <div>
    <div v-show="step === 1" v-for="item in entries" :key="item.index" class="option" @click="next(item)">
        <div class="optionChip" :style="pattern[item.level].chipColor">
            <v-icon class='mr-6 my-4 icon' :style="pattern[item.level].color"> {{pattern[item.level].icon}} </v-icon>
        </div>
        <p class="text">
            {{pattern[item.level].name}}
        </p>
    </div>
  </div>
    </v-slide-x-transition>
    <v-slide-x-transition>
      <div>
        <div v-show="step === 2" v-for="item in array" :key="item.index" class="option" @click="finish(item)">
            <div class="optionChip" :style="pattern[item.accessType].chipColor">
                <v-icon class='mr-6 my-4 icon' :style="pattern[item.accessType].color"> {{pattern[item.accessType].icon}} </v-icon>
            </div>
            <div>
              <p class="text mt-3">
                  {{item.company || item}}
              </p>
              <p class="desc mt-n5">
                {{ item.canEdit? 'Editor': 'Visualizador' }}
              </p>
            </div>
       </div>
      </div>
</v-slide-x-transition>
  </div>
</template>

<script>
export default {
  props: {
    options: Array

  },

  data: () => ({
    show: true,
    step: 1,
    array: [],
    pattern: {
      0: { name: 'Administrador', chipColor: 'background-color: #F6BEC1', color: 'color: #D2232A', icon: 'mdi-settings' },
      1: { name: 'Cliente', chipColor: 'background-color: #FFE28A', color: 'color: #FF860E', icon: 'mdi-domain' },
      2: { name: 'Promotor', chipColor: 'background-color: #B8D5ED', color: 'color: #1E1C77', icon: 'mdi-briefcase-variant' },
      3: { name: 'Promotor Mestre', chipColor: 'background-color: #B8D5ED', color: 'color: #1E1C77', icon: 'mdi-briefcase-variant' }

    }
  }),
  methods: {
    async finish (item) {
      this.$emit('choose', item)
    },

    async next (item) {
      this.$emit('screenControl', { loading: true })
      this.show = false
      await this.$functions.sleep(500)
      this.step = 2
      const holder = []
      this.options.forEach(async element => {
        // eslint-disable-next-line eqeqeq
        if (element.accessType == item.level) {
          if (element.accessType === 1) {
            const r = await this.$http.get(this.$config.url_backend + '/v0/account/' + element.idAccount + '/access/' + element.id, { headers: { Access_Token: element.token } })
            if (r.body.name) { holder.push({ ...element, ...r.body }) }
          } else if (element.accessType === 2 || element.accessType === 3) {
            this.$emit('choose', { accessType: 2 })
          } else {
            this.$emit('choose', { accessType: 0 })
          }
        }
      })

      this.$emit('screenControl', { loading: false })
      this.show = true
      this.array = holder
    },
    choose (item) {
      this.$emit('choose', item)
    }
  },
  computed: {
    entries () {
      const array = []
      const obj = {}

      this.options.forEach(element => {
        obj[element.accessType] = true
      })

      for (const [key, value] of Object.entries(obj)) {
        array.push({ level: key, exist: value })
      }

      return array
    }
  }
}
</script>

<style scoped>
.desc{
font-family: 'Noto Sans';
font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: 0.15px;

color: #959595;

}

.icon{
    height: 33px;
    font-size: 38px;
    margin-top: 24px;
    margin-left: 14px;
}
.optionChip{
    margin-top: 9px;
    margin-left: 16px;
    margin-right: 32px;
    height: 65px;
    width: 65px;
    border-radius: 33px;
}
.option{
    margin-bottom: 24px;
    display: flex;
    width: 100%;
    height: 83px;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.text{
margin-top: 24px;
font-family: 'Co Headline';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 150%;
/* identical to box height, or 36px */

letter-spacing: 0.15px;

color: #757575;
}

.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 354px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.goback{
  z-index: 999;
  position: absolute;
  left: 30px;
  top: 62px;
}
.link{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

letter-spacing: 0.4px;

/* palette / text / secondary */

color: #134B97 !important;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.footer{
  bottom: 0px;
}
.scrollareacolab{
  width: 100vw;
  min-height: 100vh;
    max-height: 100vh;
}
.smollimg{
    height: 47px;
    width: 198px;
    margin-top: 46px;
    margin-bottom: 23px;
}
.bigimg{
  width: 100%;
  max-height: calc(100vh - 137px);

}
.txtfield2{
    margin-top: 32px;
    width: 252px;
    height: 154px;
    margin-bottom: 48px;
}
.butao{

}
.size30{
    padding-left: 100px !important;
    padding-right: 105px !important;
    width: 50%;
    margin-top: 8%;
}
@media screen and (max-width: 820px) {
 .size30{
    padding-left: 10% !important;
    padding-right: 10% !important;
    width: 100%;
    margin-top: 8%;
    min-height: calc(100vh - 105px);
}
}
.colabtxt{
    font-size: 12px;
line-height: 156%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.errore{
  margin-top: 60px;
  color: #CC2027;
}
.sub{
font-size: 20px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: rgba(0, 0, 0, 0.54);

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.sub2{
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: #D2232A;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.linktxt{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

letter-spacing: 0.4px;

/* palette / text / secondary */

color: rgba(0, 0, 0, 0.54);

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
</style>

<style>
  /* Classes para tamanho / margem */
  .heightt{
    height:  calc(100vh - 187px)
    }

  .table{
    height: calc(100vh - 218px)
    }

  .fullwidth{
    width: 100%
    }
.fullwidthmax{
    min-width: 100%
    }
  .fullheight {
    height: 100%
    }

  .nomargin{
    margin: 0px !important;
    padding: 0px !important
    }
  /* Classes para estilo / centralização */
  .centertxt{
    text-align: center;
  }

  .capitalize{
    text-transform: capitalize !important
  }

  .CoHead{
    font-family: 'Co Headline Corp'
  }

  /* Classes para cores / os exemplos de cores "azul, branco, cinza, preto, roxo" então no arquivo do vuetify */
  .txtblack{
    color: #000000;
  }

  .txtgrey{
    color: #0000008a !important;
  }

  .txtwhite{
    color: #FAFAFA;
  }

  .txtred{
    color:#662B83;
  }

  .txtblue{
    color:#134B97 !important;
  }

/* A variável size2 é mostrada se a largura da tela > 660, e a size2off é escondida no msm ponto */
@media screen and (max-width: 660px) {
  .size2off {
    display: none !important;
  }
  .size2 {
    display: flex !important;
  }
}

/* A variável size1 é mostrada se a largura da tela > 940, e a size1off é escondida no msm ponto */
@media screen and (max-width: 940px) {
  .size1off {
    display: none !important;
  }
  .size1 {
    display: flex !important;
  }
}
.size1{
  display: none
}

@media screen and (max-width: 820px) {
  .size3off {
    display: none !important;
  }
  .size3 {
    display: flex !important;
  }
}
.size3{
  display: none
}

@media screen and (max-width: 1280px) {
  .size5off {
    display: none !important;
  }
  .size5 {
    display: flex !important;
  }
}
.size5{
  display: none
}

</style>
