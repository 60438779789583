import { render, staticRenderFns } from "./ChartSix.vue?vue&type=template&id=685a43f7&scoped=true"
import script from "./ChartSix.vue?vue&type=script&lang=js"
export * from "./ChartSix.vue?vue&type=script&lang=js"
import style0 from "./ChartSix.vue?vue&type=style&index=0&id=685a43f7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685a43f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardTitle,VDataTable})
