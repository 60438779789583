<template>
    <div style="padding: 32px; height: 100vh; overflow: auto;">
        <Privacidade></Privacidade>
    </div>
</template>

<script>
import Privacidade from '../components/sobre/tabs/TabPrivacidade.vue'
export default {
  data: () => ({
    details: 0
  }),
  components: {
    Privacidade
  }
}
</script>
