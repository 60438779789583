/* eslint-disable no-extra-boolean-cast */
<template>
    <div class='fullwidth relative'>
       <v-dialog v-model='noauth' max-width='400px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>Espere um segundo <v-progress-circular
        class='ml-5'
        :size="20"
        :color="this.company.primaryColor"
        indeterminate
      ></v-progress-circular></v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Carregando os dados da página </p>
        </v-card-text>
      </v-card>
    </v-dialog>
        <MainColab :hidden='noauth'   v-bind:id="id" v-bind:company="company"/>
    </div>
</template>

<script>
import MainColab from '../components/colab/ColabMain.vue'
import router from '../router'

export default {
  async created () {
    const obj = await this.$functions.get('/v0/companies/CustomPages')
    await obj.data.forEach(element => {
      if (element.nome === this.$route.params.empresa) {
        this.id = element.idCompany
      }
    })
    if (this.id === 0) {
      router.push('/error/404')
    }
    this.company.base64logo = this.$config.url_blobs + 'logo_empresa_' + this.id + '.png?dummy=' + Date.now()
    this.company.base64 = this.$config.url_blobs + 'apresentacao_empresa_' + this.id + '.png?dummy=' + Date.now()
    this.setcompanydetails()
  },
  components: {
    MainColab
  },
  data: () => ({
    id: 0,
    base64logo: '',
    base64: '',
    warning: false,
    fieldnome: '',
    fieldemail: '',
    company: { email: null, mainKey: '', secondaryKey: '', presentationText: '', base64logo: '', base64: '', primaryColor: '#134B97', secondaryColor: '#D2232A' },
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false
    },
    noauth: true,
    valid: true,
    imagemQRCode: '',
    buya: true,
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ]
  }),
  methods: {
    async setcompanydetails () {
      const h = await this.$functions.get(`/v0/company/${this.id}/CustomPage/`)
      const holder = h.data
      if (holder[0].mainKey === 'registration') {
        holder[0].mainKey = 'Matrícula'
      }
      if (holder[0].secondaryKey === 'registration') {
        holder[0].secondaryKey = 'Matrícula'
      }
      if (holder[0].mainKey === 'telephone') {
        holder[0].mainKey = 'Telefone'
      }
      if (holder[0].secondaryKey === 'telephone') {
        holder[0].secondaryKey = 'Telefone'
      }
      if (holder[0].mainKey === 'email') {
        holder[0].mainKey = 'E-mail'
      }
      if (holder[0].secondaryKey === 'email') {
        holder[0].secondaryKey = 'E-mail'
      }
      if (holder[0].mainKey === 'cpf') {
        holder[0].mainKey = 'CPF'
      }
      if (holder[0].secondaryKey === 'cpf') {
        holder[0].secondaryKey = 'CPF'
      }
      this.company = Object.assign(this.company, holder[0])
      // await this.$store.commit('editcolab', this.Empresas[this.id])
      if (!this.company.primaryColor) { this.company.primaryColor = '#134B97' }
      if (!this.company.secondaryColor) { this.company.secondaryColor = '#D2232A' }
      this.noauth = false
    }
  }
}
</script>

<style scoped>
.relative{
  position: relative;
}
.errore{
   margin-top: 50px;
  color: #CC2027;
}
.horizontal{
  margin-bottom: 78px;
}
.transition{
  transition: width 2s;
}
.footer{
  position: absolute;
  bottom: 0px;
}
.scrollareacolab{
  min-height: 100%;
    max-height: 100%;
}
.smollimg{
    max-height: 123px;
    max-width: 234px;
    margin-top: 56px;
    margin-bottom: 32px;
}
.bigimg{
  margin-left: -38px;
  margin-bottom: 20px;
    width: 650px;
    height: 768px;
}
.imgqrcode{
    margin-left: calc(16vw - 137px);
}
.txtfield{
    margin-top: 32px;
    width: 252px;
    height: 104px;
    margin-bottom: 48px;
}
.butao{
    margin-top: 48px;
}
.size30{
    width: 34%;
    margin-top: 8%;
}
.colab2t{
    font-size: 20px;
line-height: 123.5%;
letter-spacing: 0.25px;
color: #134B97;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.colabTitle{
    font-size: 34px;
line-height: 123.5%;
letter-spacing: 0.25px;
color: #134B97;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.colabtxt{
    font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
</style>
