<template>
    <v-app-bar color="branco" elevation="0" height='72px'>

      <!-- o texto -->
      <div class='my-auto'>
        <p class='headertext my-auto'> {{changes.name}} </p>
      </div>

      <v-spacer/>

      <!-- o botão -->
      <div v-if="changes.appBarButton">
        <v-btn v-if="this.$store.state.generic.currentPage === 0" color='roxo'  dark class='my-auto px-n2' rounded @click='openCreateDialog' right>
          + <p class='ml-2 size2off capitalize my-auto'> Cadastrar Empresa </p>
        </v-btn>
        <v-btn v-if="this.$store.state.generic.currentPage === 1" color='roxo'  dark class='my-auto px-n2' rounded @click='openNewAcess' right >
          + <p class='ml-2 size2off capitalize my-auto'> Cadastrar Acesso </p>
        </v-btn>
      </div>
    </v-app-bar>
</template>

<script>
export default {
  computed: {
    list () {
      return this.$store.state.generic.pages
    },
    changes () {
      const holder = this.list[this.$store.state.generic.currentPage] || this.list[0]
      return holder
    }
  },
  methods: {
    // O envio do item padrão com valor nulo, e a abertura do diálogo
    async openCreateDialog () {
      this.$store.commit('EditedClientEdit', {})
      this.$store.commit('dialogEdit', this.$bases.adminPortal.dialogs.create)
    },

    async openNewAcess () {
      this.$store.commit('editAccessDialog', { create: { show: true } })
    }
  }
}
</script>

<style scoped>

  /* Classe para formatação de texto */
  .headertext {
    font-family: 'Co Headline Corp';
    font-size: 20px;
    line-height: 100%;
    color: #134b97;
  }

</style>
