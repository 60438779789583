<template>
  <div>
    <v-data-table
      @update:items-per-page='updatepage()'
      @update:page='updatepage()'
      class='size2off'
      mobile-breakpoint="10"
      calculate-widths:true
      :headers='headers'
      :items='this.List'
      :hidden='this.List.length < 1'
      :loading='!$store.state.adminPortal.ClientData.refreshed'
      :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[3,6,12,18]}"
    >

      <!-- Essas templates possuem cada uma das colunas da tabela -->
      <template v-slot:item.company="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' :disabled='!item.situation' class='tabs'>
          <p class='empresa capitalize my-2'> {{ item.name }} </p> <v-spacer/>
        </v-tab>
      </template>

      <template  v-slot:item.responsavel="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' :disabled='!item.situation' class=tabs>
          <p class='capitalize my-2 vendedor'> {{ item.nameUser }} </p> <v-spacer/>
        </v-tab>
      </template>

      <template v-slot:item.sellerdetails="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' :disabled='!item.situation' class='tabs'>
          <div class='my-auto divs'>
            <p class='vendedor capitalize my-2'> {{ item.seller }} </p>
            <p class='start regional capitalize mb-2'> {{ item.regional }} </p>
          </div>
        </v-tab>
      </template>

      <template v-slot:item.situation="{ item }">
        <div v-if="($store.state.adminPortal.ClientData.refreshed == true) && render" :disabled='!item.situation' >
          <v-switch
            class='start mx-3'
            v-model='item.situation'
            @click='situationChanger(item)'
            :ripple='false'
            color=' roxo'
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tab :ripple="false" class='tabs' v-if="$store.state.adminPortal.ClientData.refreshed == true" :disabled='!item.situation' >
          <v-spacer/>

          <v-icon class='mx-0 mr-4' @click='opendialog (item, "edit")' color='azul' >
            mdi-pencil
          </v-icon>
          <v-spacer/>

          <v-icon @click='opendialog (item, "sendEmail")' class='mx-0 mr-4' color='azul'>
            mdi-email
          </v-icon>
          <v-spacer/>

          <v-icon @click='opendialog (item, "delete")' class='mx-0' color='azul'>
            mdi-delete
          </v-icon>
          <v-spacer/>

        </v-tab>
      </template>

    </v-data-table>

    <!-- O aviso -->
    <div :hidden='this.List.length >= 1' class='centertxt'>
      <div class='spacervertical' />
      <h1 class='CoHead centertxt'> Nenhum resultado </h1>
      <p> O filtro aplicado não retornou nenhum resultado, tente alterá-lo </p>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    render: true,
    headers: [
      { text: 'EMPRESAS', sortable: false, align: 'center', value: 'company', width: '20%' },
      { text: 'NOME RESPONSÁVEL', sortable: false, align: 'center', value: 'responsavel', width: '20%' },
      { text: 'VENDEDOR', align: 'center', sortable: false, value: 'sellerdetails', width: '20%' },
      { text: 'Área', align: 'center', sortable: false, value: 'typeName', width: '15%' },
      { text: 'STATUS', align: 'center', sortable: false, value: 'situation', width: '10%' },
      { text: 'AÇÕES', align: 'center', sortable: false, value: 'actions', width: '15%' }
    ]
  }),

  computed: {
    List () { return this.$store.getters.Filteredclients },
    config () { return { headers: { Access_Token: this.msal.idToken } } }
  },
  methods: {
    opendialog (item, dialog) {
      this.$store.commit('EditedClientEdit', Object.assign({}, item))
      this.$store.commit('dialogEdit', this.$bases.adminPortal.dialogs[dialog])
    },

    // Para avisar a página quando temos que puxar ela pra cima
    updatepage () {
      this.$store.commit('adminOptions', { temp: { update: true } })
    },

    // Um refresh da lista, com um pequeno delay para evitar alguns bugs
    async refreshlist () {
      await this.$functions.sleep(1000)
      this.$store.commit('RefreshAdminPortalData', this.msal.idToken)
    },

    // A função conectada nos switchs, como essa parte é problemática fizemos diversos workarounds
    async situationChanger (item) {
      if (this.$store.state.adminPortal.options.filter.situacao != null) {
        this.render = false
        await this.$store.commit('adminOptions', { temp: { deleted: true } })
        this.render = true
        this.updatepage()
      }
      const holder = item
      holder.situation = holder.situation ? 1 : 0
      await this.$functions.put('/updateSituation/' + item.id, holder, this.config)
    }
  }
}
</script>

<style scoped>

  /* Classes para posicionamento / tamanho */
  .divs {
    width: 100%;
  }

  .tabs {
    padding-right: 10%;
    height: 64px;
    width: 100%;
  }

  .spacervertical{
    margin: 1px;
    min-height: calc(50vh - 200px);
  }

  .start{
    text-align: start !important;
  }

  /* Classes para formatação de texto */
  .empresa{
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #D2232A;
    }

  .vendedor{
    margin: 0px;
    font-size: 12px;
    line-height: 166%;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
  }

  .regional{
    margin: 0px;
    padding: 0px;
    font-size: 12px !important;
    line-height: 166% !important;
    letter-spacing: 0.4px !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }
</style>
