/* eslint-disable no-extra-boolean-cast */
<template>
  <v-card class='branco a positionrelative'>

    <v-select :items="list" v-model="selected" :menu-props="{offsetY: true}" outlined class='select' dense>
    </v-select>
    <DownloadIcon v-bind:data="data.download" v-if="isClient" class='position'/>

    <v-card-title class='title CoHead pt-5 mb-n3'>
      Total de kits entregues
    </v-card-title>

    <v-row class="nomargin">
      <v-card class='ml-3 branco' height="230" width="230" elevation="0">
        <pie-chart  :suffix="intNumber ? null : '%'" class='donut' :data="calculatedData" :legend="false" :donut="true" :colors="[redrgba]" />
      </v-card>
      <v-card height="288" class='pl-4 branco' elevation="0">
        <div class="donutDivText">
          <p class="donutDivTitle"> {{calculatedData.Feitos}}{{intNumber ? null : '%'}} </p>
          <p class='donutDivInfo'> {{intNumber ? null : 'de '}} kits entregues <br>
            total: {{calculatedData.Feitos + calculatedData.Faltantes}} </p>
        </div>
      </v-card>
    </v-row>
    <v-tabs
      class='intNumber'
      background-color='#FAFAFA'
      color="#0000008a"
      active-class='activetab3'
      hide-slider
      v-model="intN"
      height="38"
    >
      <v-tab class='nomargin'>
        <v-icon> mdi-numeric-1-box-outline </v-icon>
      </v-tab>
      <v-tab class='nomargin'>
        <v-icon>  mdi-percent  </v-icon>
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  props: ['data', 'isClient'],
  data: () =>
    ({
      selected: 'Todas',
      intN: false,
      blue: '#134B97',
      blue1rgba: 'rgba(5,94,169,1)',
      bluergba: 'rgba(19,75,151,1)',
      lightbluerba: 'rgba(198,206,226,1)',
      grey1rgba: 'rgba(189,189,189,1)',
      red: '#d2232a',
      redrgba: 'rgba(210,35,42,1)',
      tamanho: '600px'
    }),
  methods: {
  },
  computed: {
    intNumber () {
      return !this.intN
    },
    list () {
      const array = []
      if (typeof this.data.chart !== 'undefined') {
        for (const [key] of Object.entries(this.data.chart)) {
          array.push(key)
        }
      }
      return array
    },
    calculatedData () {
      if (this.intNumber) {
        return this.data.chart[this.selected]
      } else {
        const total = this.data.chart[this.selected].Feitos + this.data.chart[this.selected].Faltantes
        return { Feitos: (Math.round(this.data.chart[this.selected].Feitos / total * 1000) / 10), Faltantes: (Math.round(this.data.chart[this.selected].Faltantes / total * 1000) / 10) }
      }
    }
  }
}
</script>

<style scoped>
.select {
  position: absolute;
  right: 16px;
  width: 150px;
  top: 16px;
  z-index: 99999;
  height: 48px;
}
.a{
  min-width: 435px;
  height: 335px;
}
@media screen and (max-width: 1359px) {
  .a{
  min-width: 378px;
}
}
@media screen and (max-width: 1100px) {
  .a{
  min-width: 475px;
}
}

/* DONUT */
.positionrelative{
  position: relative;
}
.intNumber{
  position: absolute;
  bottom: 21px;
  right: 25px;
  width: 80px !important;
}
.title{
font-family: 'Co Headline Corp' !important;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.donutDivText{
  margin-top: calc((288px - 66px)/2);
  height: 66px;
  width: 159px;
}
.donutDivTitle{
  margin-bottom: 7px;
font-family: 'Co Headline Corp' !important;
font-size: 36px;
line-height: 32px;
color: #d2232a;
}
.donutDivInfo{
font-family: "Noto Sans Medium" !important;
font-size: 12px;
line-height: 14px;
color: #000000;
opacity: 0.6;
letter-spacing: 0.4px;
}

  .activetab3{
    background-color: #0000001f;
  }
  .position{
    right: 12px;
    top: 72px;
  }
</style>
