import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=2d81a16a&scoped=true"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=2d81a16a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d81a16a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDivider,VIcon,VImg,VNavigationDrawer,VRow,VSpacer,VTab,VTabs})
