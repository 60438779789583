<template>
    <div class='generaltxt'>
        <label class='text'> Como utilizar a plataforma? </label> <br>
            Se você tem dúvidas de como utilizar o portal da BRF, leia o manual abaixo e siga as instruções. Caso ainda precise de ajuda, entre em contato com <a href='mailto:suportebrfcode@brf.com'> suportebrfcode@brf.com </a>
        <iframe v-if="type === 'admin' " class='mt-4' src="/pdfs/TutorialAdm.pdf" width="100%" height="500px"> </iframe>
        <iframe v-if="type !== 'admin'" class='mt-4' src="/pdfs/TutorialClientes.pdf" width="100%" height="500px">    </iframe>
    </div>
</template>

<script>
export default {
  props: ['type'],
  mounted () {
  }
}
</script>

<style>

</style>

<style scoped>
.generaltext{
font-size: 16px;
line-height: 150%;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
}
.text{
    font-family: Co Headline Corp;
font-size: 20px;
line-height: 160%;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
}
</style>
