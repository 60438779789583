<template>
  <div class='nomargin'>
    <v-dialog persistent v-model='this.$store.state.clientPortal.dialogs[0].name' max-width="810">
      <v-card class='nomargin mr-0' width="810px">
        <v-card width="810" height="86" elevation="0" class='px-6 pt-3 pb-2'>
          <v-card-title class='CoHead txtblue nomargin mb-6'>Renomear a pasta</v-card-title>
          <v-card-text class='mt-4 nomargin'>
            <p> Preencha com o nome abaixo e confirme. </p>
          </v-card-text>
        </v-card>

        <v-card class='nomargin' elevation="0" height="88" width="810">
            <v-stepper  class='my-2 mx-auto namestepper' elevation="0" :value="stepper">
              <v-stepper-header >
                <v-stepper-step class='ma-2' step="1" color="red">
                  Nomear
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" color="red">
                Confirmar
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>

          <div >
            <v-card height="101" width="810" elevation="0" class="px-6">
              <v-row class='nomargin' v-if="stepper === 1 ">
                <div class="half">
                  <p class='mt-5 mb-0'> Preencha o campo ao lado com o nome desejado, podendo ser por cidade, planta ou nomeclatura da própria empresa.</p>
                </div>
                <v-spacer/>
                <v-form ref='form' v-model='valid' lazy-validation class='half2'>
                  <v-text-field v-model='namefolder' :rules='nameRules' label='Nome da pasta*' required hint="*Obrigatório" persistent-hint>
                  </v-text-field>
                </v-form>
    <v-row class='nomargin wut'>
                    </v-row>

              </v-row>
              <v-row class='half my-auto'  v-if="stepper === 2">   <v-icon class='mr-8 ml-16 my-6'> mdi-folder-outline </v-icon>
                <div class='line'> <p class='cardtxt'>{{namefolder}} </p> <p class='mt-0 regional'> Nome </p> </div>

            </v-row>
            </v-card>
          </div>

        <v-card width="810" height="75" elevation="0" class='nomargin'>
          <v-row class="nomargin pt-8 pr-1">
            <v-spacer/>
            <v-btn color='azul' @click='back' rounded outlined class='capitalize'>
              Cancelar
            </v-btn>
            <v-btn class="ml-5" color='azul' :disabled='!valid' @mouseover='validate' @click='nexte()' rounded >
              <p class='my-auto txtwhite capitalize'> Seguinte </p>
            </v-btn>
          </v-row>
        </v-card>

      </v-card>
    </v-dialog>
    <v-dialog v-model='waitdialog' max-width='430px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>
          Carregando a sua requisição
          <v-progress-circular class='ml-5' :size="20" color="azul" indeterminate > </v-progress-circular>
        </v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Aguarde um segundo </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model='alarmDialog' max-width='498px' max-height='296px' persistent>
      <v-card>
        <v-card-title class='CoHead'> Problemas no preenchimento de dados!!</v-card-title>
        <v-card-text>
          <v-card-text><p> Alguns dos dados dos colaboradores não estão completos, faltando a matricula e/ou o email, caso escolha por continuar iremos ignorar essas linhas, deseja continuar?</p></v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color='azul' outlined @click='close' rounded class='mr-1'><p class='my-auto capitalize'>Cancelar Envio</p></v-btn>
          <v-btn color='azul' @click='Continue' rounded><p class='txtwhite my-auto capitalize'>Continuar</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

       <v-dialog v-model='minorDialog' max-width='810px' max-height='576px' persistent>
      <v-card>
        <v-card-title class='CoHead txtblue ml-4'> Ops! Foram encontrados erros</v-card-title>
        <v-card-text>
          <v-card-text class='pb-0'><p>Os campos não obrigatórios não foram totalmente preenchidos, é possível continuar mas isso pode trazer erros em logins futuros.</p></v-card-text>
                      <label class='ml-6 txtblack CoHead'> matrículas com dados incompletos </label>
          <v-card-text class='pa-0 ma-0' v-for="item in minorDetails.list" :key="item.id">
            <v-row class='my-5 ml-3' v-if="minorDetails[item.id].length > 0">
              <v-icon class='mr-4 my-auto' color="azul"> {{item.icon}} </v-icon>
              <div>
                <p class='pb-0 mb-0 txtblack'> {{item.name}} ({{minorDetails[item.id].length}} Faltantes) </p>
                <p class='pb-0 mb-0'> <label  v-for="k in minorDetails[item.id]" :key="k.index">  {{k}} - </label> </p>
              </div>
            </v-row>
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color='azul' outlined @click='close' rounded class='mr-1'><p class='my-auto capitalize'>Cancelar Envio</p></v-btn>
          <v-btn color='azul' @click='(minorDialog = false)' rounded><p class='txtwhite my-auto capitalize'>Continuar</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model='dialogcreate' max-width="810">
      <v-card class='nomargin mr-0' width="810px">
        <v-card width="810" height="126" elevation="0" class='px-6 pt-3 pb-2'>
          <v-card-title class='CoHead txtblue nomargin mb-6'>Cadastrar nova entrega</v-card-title>
          <v-card-text class='mt-4 nomargin'>
            <p> Para cadastrar uma nova entrega, baixe os modelos de planilha, preencha-os e faça o cadastro no <br> campo de lista de entrega e promotores</p>
          </v-card-text>
        </v-card>

        <v-card class='nomargin' elevation="0" height="88" width="810">
            <v-stepper class='ma-2' elevation="0" :value="stepper">
              <v-stepper-header >
                <v-stepper-step class='ma-2' step="1" color="red">
                  Nomear
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" color="red">
                Lista de entrega
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3" color="red">
                Lista de promotores
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4" color="red">
                Confirmar
              </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card>

          <div v-if="stepper === 1 ">
          <v-card height="101" width="810" elevation="0" class="px-6">
            <v-row class='nomargin'>
              <div class="half">
                 <p class='mt-5 mb-0'> Preencha o campo ao lado com o nome desejado, podendo ser por cidade, planta ou nomeclatura da própria empresa.</p>
              </div>
            <v-spacer/>
          <v-form
              ref='form'
              v-model='valid'
              lazy-validation
              class='half2'>
            <v-text-field
                v-model='namefolder'
                :rules='nameRules'
                label='Nome da pasta*'
                required
                hint="*Obrigatório"
                persistent-hint
            ></v-text-field>
          </v-form>
          </v-row>
          </v-card>
          </div>
          <div v-if="stepper === 2">
            <v-card height="101" width="810" elevation="0" class="px-6">
              <v-row class='nomargin'>
                <div class="half">
                  <v-card-title class='nomargin CoHead minit'>LISTA DE ENTREGA</v-card-title>
                  <p class='mt-5 mb-0'> A lista de entrega deve conter somente os dados dos funcionários que irão retirar os kits, seguindo o modelo fornecido.</p>
                </div>
                <v-divider vertical class='mx-auto mt-9'> </v-divider>
                <div class='half2 mt-9'>
                  <v-spacer/>
                    <v-form ref='form' v-model='valid' lazy-validation >
                      <v-file-input :rules='nameRules' persistent-hint hint="*Obrigatório" class='flinput mx-auto' prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model='listfolder' label="Arquivo xls.*" outlined/>
                    </v-form>
                </div>
              </v-row>
            </v-card>
          </div>
          <div v-if="stepper === 3">
            <v-card height="101" width="810" elevation="0" class="px-6">
              <v-row class='nomargin'>
                <div class="half">
                  <v-card-title class='nomargin CoHead minit'>LISTA DE PROMOTORES</v-card-title>
                  <p class='mt-5 mb-0'> A lista deve conter somente os dados dos promotores responsáveis, seguindo o modelo fornecido. O cadastro dos promotores também poderá ser feito posteriormente, dentro da própria lista de entrega</p>
                </div>
                <v-divider vertical class='mx-auto mt-9'> </v-divider>
                <div class='half2 mt-9'>
                  <v-spacer/>
                    <v-form ref='form' v-model='valid' lazy-validation >
                      <v-file-input persistent-hint hint="*Obrigatório" class='flinput mx-auto' prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model='workersfolder' label="Arquivo xls.*" outlined/>
                    </v-form>
                </div>
              </v-row>
            </v-card>
          </div>
           <div v-if="stepper === 4">
            <v-card height="248" width="810" elevation="0" class="px-6">
              <v-row class='nomargin wut'>
                <v-row class='half my-auto'>   <v-icon class='mr-8 ml-16 my-6'> mdi-folder-outline </v-icon>
                <div class='line'> <p class='cardtxt'>{{namefolder}} </p> <p class='mt-0 regional'> Nome </p> </div>
              </v-row>
              </v-row>
                <v-row class='nomargin wut'>
                <v-row class='half my-auto'>   <v-icon class='mr-8 ml-16 my-6'> mdi-paperclip </v-icon>
                <div class='line'> <p class='cardtxt'>{{listfolder.name}} </p> <p class='mt-0 regional'> {{readyLists.colaboradores.length}} linhas </p> </div>
              </v-row>
                <v-divider vertical class='mx-auto'> </v-divider>
                <div class='half3'>
                    <p class=''> As listas de entrega e promotores devem conter somente os dados dos colaboradores e promotores respectivamente, seguindo o modelo fornecdo. </p>
                </div>
              </v-row>
              <v-row class='nomargin wut' v-if="workersfolder">
                <v-row class='half my-auto'>   <v-icon class='mr-8 ml-16 my-6'> mdi-paperclip </v-icon>
                <div class='line'> <p class='cardtxt'>{{workersfolder? workersfolder.name : null}} </p> <p class='mt-0 regional'> {{workersfolder? readyLists.promotores.length : null}} linhas </p> </div>
              </v-row>
              </v-row>
              <v-row class='nomargin wut' v-if="!workersfolder">
                <v-row class='half my-auto'>   <v-icon class='mr-8 ml-16 my-6'> mdi-alert-outline </v-icon>
                <div class='line'> <p class='cardtxt txtgrey'> Promotores </p> <p class='mt-0 regional'> Não cadastrada </p> </div>

              </v-row>
                 <v-divider vertical class='mx-auto'> </v-divider>
                              <div class='half3 pa-0 ma-0'>
                    <p class='pa-0 ma-0'> A lista de promotores não foi cadastrada, mas não se preocupe. Você poderá enviá-la em um outro momento, dentro da lista de entrega. </p>
                </div>
              </v-row>
            </v-card>
          </div>
          <v-card width="810" height="75" elevation="0" class='nomargin'>
            <v-row class="nomargin pt-8 pr-1">
              <v-spacer/>
                <v-btn
                  color='azul'
                  @click='back'
                  rounded
                  outlined
                  class='capitalize'
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="ml-5"
                  color='azul'
                  :disabled='!valid'
                  @mouseover='validate'
                  @click='next()'
                  rounded
                >
                  <p class='my-auto txtwhite capitalize'> Seguinte </p>
                </v-btn>
            </v-row>
          </v-card>
<!--
            <p class=txtred> Lista de promotores </p>
            <v-file-input v-model='workersfolder' label="Arquivo xls." outlined/> -->
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model='dialogUpdate' max-width="810">
      <v-card class='nomargin mr-0' width="810px">
        <v-card width="810" height="126" elevation="0" class='px-6 pt-3 pb-2'>
          <v-card-title class='CoHead txtblue nomargin mb-6'>Cadastrar {{this.$store.state.clientPortal.page? 'nova lista' : 'promotores'}}</v-card-title>
          <v-card-text class='mt-4 nomargin'>
            <p> Para cadastrar uma nova lista, baixe os modelos de planilha, preencha-os e faça o cadastro no <br> campo abaixo</p>
          </v-card-text>
        </v-card>

        <v-card class='mx-auto' elevation="0" height="88" width="410">
            <v-stepper class='my-2 mx-auto' elevation="0" :value="stepper">
              <v-stepper-header >
                <v-stepper-step class='ma-2' step="1" color="red">
                  Lista de {{this.$store.state.clientPortal.page? 'entrega' : 'promotores'}}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" color="red">
                  Confirmar
              </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-card>
          <div v-if="stepper === 1">
            <v-card height="101" width="810" elevation="0" class="px-6">
              <v-row class='nomargin'>
                <div class="half">
                  <v-card-title class='nomargin CoHead minit'>LISTA DE {{this.$store.state.clientPortal.page? 'ENTREGA' : 'PROMOTORES'}}</v-card-title>
                  <p class='mt-5 mb-0'>
                    {{this.$store.state.clientPortal.page?
                    'A lista de entrega deve conter somente os dados dos funcionários que irão retirar os kits, seguindo o modelo fornecido.'
                    :
                    'A lista deve conter somente os dados dos promotores responsáveis, seguindo o modelo fornecido.'}}
                  </p>
                </div>
                <v-divider vertical class='mx-auto mt-9'> </v-divider>
                <div class='half2 mt-9'>
                  <v-spacer/>
                    <v-form ref='form' v-model='valid' lazy-validation >
                      <v-file-input :rules='nameRules' persistent-hint hint="*Obrigatório" class='flinput mx-auto' prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model='listfolder' label="Arquivo xls.*" outlined/>
                    </v-form>
                </div>
              </v-row>
            </v-card>
          </div>
           <div v-if="stepper === 2 && dialogUpdate">
            <v-card height="101" width="810" elevation="0" class="px-5 pt-6">
                <v-row class='nomargin wut'>
                <v-row class='half my-auto'>   <v-icon class='mr-8 ml-16 my-6'> mdi-paperclip </v-icon>
                <div class='line'> <p class='cardtxt'>{{listfolder.name.length > 23 ? listfolder.name.substring(0, 21) + '...': listfolder.name}} </p> <p class='mt-0 regional'> {{this.$store.state.clientPortal.page? readyLists.colaboradores.length : readyLists.promotores.length}} linhas </p> </div>
              </v-row>
                <v-divider vertical class='mx-auto'> </v-divider>
                <div class='half3'>
                    <p class=''> As listas de entrega e promotores devem conter somente os dados dos colaboradores e promotores respectivamente, seguindo o modelo fornecdo. </p>
                </div>
              </v-row>
            </v-card>
          </div>
          <v-card width="810" height="75" elevation="0" class='nomargin'>
            <v-row class="nomargin pt-8 pr-1 uhul">
              <v-spacer/>
                <v-btn
                  color='azul'
                  @click='back'
                  rounded
                  outlined
                  class='capitalize'
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="ml-5"
                  color='azul'
                  :disabled='!valid'
                  @mouseover='validate'
                  @click='nexto()'
                  rounded
                >
                  <p class='my-auto txtwhite capitalize'> Seguinte </p>
                </v-btn>
            </v-row>
          </v-card>
<!--
            <p class=txtred> Lista de promotores </p>
            <v-file-input v-model='workersfolder' label="Arquivo xls." outlined/> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  computed: {
    dialogcreate () {
      return this.$store.state.clientPortal.dialogs[0].create
    },
    dialogUpdate () {
      return this.$store.state.clientPortal.dialogs[0].update
    }
  },
  data: () => ({
    minorDetails: {
      list: [
        { name: 'Telefone', icon: 'mdi-phone', id: 'TELEFONE' },
        { name: 'CPF', icon: 'mdi-account', id: 'CPF' },
        { name: 'Nome Completo', icon: 'mdi-clipboard-account', id: 'NOME' },
        { name: 'Email', icon: 'mdi-email', id: 'EMAIL' }

      ],
      TELEFONE: [],
      UNIDADE: [],
      CPF: [],
      EMAIL: [],
      NOME: []
    },
    minorDialog: false,
    minorAlarm: false,
    waitdialog: false,
    alarmDialog: false,
    alarm: false,
    listholder: {},
    stepper: 1,
    namefolder: '',
    listfolder: null,
    readyLists: {},
    workersfolder: null,
    valid: true,
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ]
  }),

  methods: {
    async nexte () {
      this.stepper = this.stepper + 1
      if (this.stepper === 3) {
        const url = '/v0/company/' + this.$store.state.clientPortal.login.idCompany + '/delivery/' + this.$store.state.clientPortal.opendetail.id + '/name'
        await this.$functions.put(url, { nome: this.namefolder, id: this.$store.state.clientPortal.opendetail.id }, this.$store.state.clientPortal.config)
        this.stepper = 1
        this.$store.commit('dialogseditclient', [{ name: false }])
        this.RefreshDetails()
      }
    },
    async RefreshDetails () {
      this.$store.commit('RefreshClientPortalData')
      let holder = {}
      holder.collaborators = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators`, this.$store.state.clientPortal.config)
      holder.promoters = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/promoter/`, this.$store.state.clientPortal.config)
      holder = { id: this.$store.state.clientPortal.opendetail.id, collaborators: holder.collaborators.data, promoters: holder.promoters.data }
      this.$store.commit('clientdetail', holder)
    },
    async next () {
      let holder = this.stepper + 1
      if (this.stepper === 2) {
        await this.excelToJson(this.listfolder)
        while (typeof this.listholder.A1 === 'undefined') {
          await this.$functions.sleep(1000)
        }
        this.readyLists.colaboradores = await this.formatCollab(this.listholder, { A: 'MATRICULA', B: 'NOME', C: 'CPF', D: 'TELEFONE', E: 'EMAIL', F: 'UNIDADE', G: 'OBSERVACAO' }, 3)
        const isUndefined = (element) => typeof element.CPF === 'undefined'
        let findUndefined = this.readyLists.colaboradores.findIndex(isUndefined)
        while (findUndefined !== -1) {
          this.readyLists.colaboradores.splice(findUndefined, 1)
          findUndefined = this.readyLists.colaboradores.findIndex(isUndefined)
        }

        this.readyLists.colaboradores.forEach(element => {
          let str = ''
          str = str + element.CPF
          while (str.length < 11) { str = '0' + str }
          element.CPF = str
        })
        if (this.alarm) { this.alarmDialog = true }
        if (this.minorAlarm) { this.minorDialog = true }
        this.listholder = {}
      }
      if (this.stepper === 3 && this.workersfolder !== null) {
        await this.excelToJson(this.workersfolder)
        while (typeof this.listholder.A1 === 'undefined') {
          await this.$functions.sleep(100)
        }
        this.readyLists.promotores = await this.formatCollab(this.listholder, { A: 'NOME', B: 'EMAIL' }, 3, true)
      }
      if (this.stepper === 4) {
        this.readyLists.nome = this.namefolder
        this.waitdialog = true
        await this.$functions.post('/v0/company/' + this.$store.state.clientPortal.login.idCompany + '/delivery', this.readyLists, this.$store.state.clientPortal.config)
        this.waitdialog = false
        holder = 1
        this.$store.commit('dialogseditclient', [{ create: false }])
        this.$store.commit('RefreshClientPortalData')
      }
      this.stepper = holder
    },

    async nexto () {
      let holder = this.stepper + 1
      if (this.stepper === 1) {
        await this.excelToJson(this.listfolder)
        while (typeof this.listholder.A1 === 'undefined') {
          await this.$functions.sleep(100)
        }
        if (this.$store.state.clientPortal.page) {
          this.readyLists.colaboradores = await this.formatCollab(this.listholder, { A: 'MATRICULA', B: 'NOME', C: 'CPF', D: 'TELEFONE', E: 'EMAIL', F: 'UNIDADE' }, 3)
          this.readyLists.colaboradores.forEach(element => {
            let str = ''
            str = str + element.CPF
            while (str.length < 11) { str = '0' + str }
            element.CPF = str
          })
          if (this.alarm) { this.alarmDialog = true }
          if (this.minorAlarm) { this.minorDialog = true }
        } else {
          this.readyLists.promotores = await this.formatCollab(this.listholder, { A: 'NOME', B: 'EMAIL' }, 3, true)
        }

        this.listholder = {}
      }
      if (this.stepper === 2) {
        this.waitdialog = true
        if (this.$store.state.clientPortal.page) {
          await this.$functions.post('/v0/' + this.$store.state.clientPortal.login.idCompany + '/uploadListCollaborators/' + this.$store.state.clientPortal.opendetail.id, this.readyLists, this.$store.state.clientPortal.config)
        } else {
          await this.$functions.post('/v0/' + this.$store.state.clientPortal.login.idCompany + '/uploadListPromoters/' + this.$store.state.clientPortal.opendetail.id, this.readyLists, this.$store.state.clientPortal.config)
        }
        let txt
        if (this.$store.state.clientPortal.page) {
          txt = 'O cadastro da lista de colaboradores foi realizado com sucesso'
        } else {
          txt = 'O cadastro da lista de promotores foi realizado com sucesso'
        }
        this.$store.commit('showSucessDialog', { show: true, title: 'Cadastro realizado', text: txt })
        this.waitdialog = false
        this.$store.commit('RefreshClientPortalData')
        this.$store.commit('clearclientdetail')
        this.$store.commit('editclientdetails', { isOpen: false, data: [] })
        this.$store.commit('dialogseditclient', [{ update: false }])
        holder = 1
      }
      this.stepper = holder
    },
    async formatCollab (list, entradas, corte, isPromoter) {
      delete list['!margins']
      delete list['!merges']
      const listSize = list['!ref'].slice(4)
      let i = corte
      const holder = []
      while (i <= listSize) {
        let obj = {}
        let getout = false
        for (const [key, value] of Object.entries(entradas)) {
          const str = key + i
          if (getout) { break }
          if (typeof list[str] !== 'undefined') {
            obj[value] = list[str].v
          } else {
            if (isPromoter) {
              if (value in { EMAIL: 1, NOME: 1 }) {
                obj = {}
                getout = true
                this.alarm = true
              } else {
                obj[value] = '00000000000'
              }
            } else {
              if (value in { MATRICULA: 1, NOME: 1 }) {
                obj = {}
                getout = true
                this.alarm = true
              } else {
                this.minorAlarm = true
                obj[value] = '00000000000'
                this.minorDetails[value].push(obj.MATRICULA)
              }
            }
          }
        }
        if (obj !== {}) { holder.push(obj) }
        i += 1
      }
      return holder
    },

    back () {
      this.stepper = this.stepper - 1
      if (this.stepper === 0) {
        this.stepper = 1
        this.$store.commit('dialogseditclient', [{ create: false, update: false }])
      }
    },

    validate () {
      if (this.stepper !== 4 && (!this.dialogUpdate && this.stepper !== 2)) {
        this.$refs.form.validate()
      }
    },

    async excelToJson (item) {
      const fileReader = new FileReader()
      await fileReader.readAsBinaryString(item)
      fileReader.onload = async (event) => {
        const data = event.target.result
        const workbook = await XLSX.read(data, { type: 'binary' })
        await workbook.SheetNames.forEach(async sheet => {
          this.listholder = workbook.Sheets[sheet]
        })
      }
    },
    clearForm () {
      this.dialog = true
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    close () {
      this.minorDialog = false
      this.alarm = false
      this.alarmDialog = false
      this.listholder = {}
      this.stepper = 1
      this.namefolder = ''
      this.listfolder = null
      this.readyLists = {}
      this.workersfolder = null
      this.$store.commit('RefreshClientPortalData')
      this.$store.commit('dialogseditclient', [{ update: false }])
    },
    Continue () {
      this.alarmDialog = false
    }
  }
}
</script>

<style scoped>

  .flinput{
    width: 199px;
    height: 50px;
  }
  .minit{
    position: static;
left: 10.5%;
right: -1.5%;
top: 0%;
bottom: 0%;

/* typography/subtitle1 */

font-family: Co Headline Corp;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 175%;
/* identical to box height, or 28px */

letter-spacing: 2px;
text-transform: uppercase;

/* palette / secondary / light */

color: #d2232a;

/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;

  }
  .half{
    z-index: 10;
    width: 340px;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
    .half3{
      margin-top: 6px;
      margin-bottom: 0px !important;
    z-index: 10;
    width: 340px;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }
    .half2{
    width: 371px;
    z-index: 10;
  }
  .empresa{
font-weight: 500;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5px;
color: #D2232A;
margin-top: 19px;
  }
  .vendedor{
font-size: 14px;
line-height: 100%;
letter-spacing: 0.4px;
margin-top: 14px;
margin-bottom: 4px;
  }
  .sellerregion{
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.54);
  }
  .dialogtext{
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
margin-top: 14px;
margin-bottom: 10px;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
  }
  .container{
  max-width: 501px;
  margin-top: 0px !important;
  }
  .margin{
    margin-left: 12px;
    margin-right: 0px;
    margin-top: 16px;
    margin-bottom: 0px;
  }
    .cardtxt{
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
margin-top: 14px;
margin-bottom: 0px;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
  }
    .regional{
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.54);
  }
  .wut{
    height: 72px;
  }

.uhul{
  z-index: 999;
}
</style>
