<template>
  <div>

      <vue-custom-scrollbar id='scrollbar' class="scrollAreaclient nomargin"  :settings="settings">
        <div v-for="item in this.$store.state.clientPortal.Access" :key="item.id">
          <v-row>
            <p class='Titulo'> {{item.name}} </p>
            <v-spacer />
            <div class='select'>
              <v-select v-model="selectedKey" :items="['Nome' , 'Email' , 'Permissão']" :menu-props="{offsetY: true}" v-show="item.name === 'Membros'" dense label="Ordenar por"> </v-select>
            </div>
          </v-row>
          <div :hidden='!(item.list.length === 0)' class='centertxt'>
        <div class='spacervertical' />
        <h1 class='CoHead centertxt'> Ainda não há nenhum membro cadastrado </h1>
        <p> Para conceder acesso à plataforma para outros usuários, clique no botão abaixo </p>
        <v-btn rounded color="vermelho" @click='addone()'>  <v-icon class='mr-2' color="branco"> mdi-account-plus </v-icon> <p class='capitalize txtwhite mt-4'>  Compartilhar acessos </p> </v-btn>
      </div>
          <div :hidden='item.list.length === 0'>
          <v-data-table
          :loading="re"
          class='mx-4 mb-4'
         calculate-widths:true
          :headers='headers'
          :items='item.list'
          :sort-by='sorting'
          :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[4,8,12]}"
          :hide-default-footer="!item.footer"
        >
          <template v-slot:item.company="{ item }">
             <v-row> <v-checkbox color="vermelho"></v-checkbox> <p class='empresa'> {{ item.matricula }} </p> </v-row>
          </template>
           <template v-slot:item.canEdit="{ item }">
            <p v-if="item.isOwner" class='mt-4'> Proprietário </p>
            <v-select class="mt-2" :disabled="!$store.state.clientPortal.login.permissao" v-if="!item.isOwner" dense v-model="item.permissao" @change='permChange(item)' :items="['Pode visualizar', 'Pode editar']"> </v-select>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
            <v-row class='t'>
            <v-spacer/>
            <v-icon
            :disabled="!$store.state.clientPortal.login.permissao"
              class='mx-0'
              @click='editItem(item)'
              color='azul'
            >
              mdi-pencil
            </v-icon>
            <v-spacer/>
            <v-icon
            :disabled="!$store.state.clientPortal.login.permissao"
              @click='SendEmail(item)'
              class='mx-0'
              color='azul'
            >
              mdi-email
            </v-icon>
            <v-spacer/>
            <v-icon
            :disabled="!$store.state.clientPortal.login.permissao"
              @click='deleteItem(item)'
              class='mx-0'
              color='azul'
            >
              mdi-delete
            </v-icon>
            <v-spacer/>
            </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            - Informação não encontrada - <br>
           Contate o suporte!
          </template>
        </v-data-table>
          </div>
        </div>
      </vue-custom-scrollbar>
  </div>
  </template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export default {
  components: {
    vueCustomScrollbar
  },
  async created () {
    await this.$store.commit('refreshAccess')
  },
  methods: {
    addone () {
      this.$store.commit('generalchangesclient', {})
      this.$store.commit('dialogDetail', { name: 'add', range: 'MemberUser', type: 'Form', title: 'Compartilhar acesso', text: 'Para que outras pessoas possam acessar a plataforma, compartilhe o acesso fazendo o cadastro abaixo.' })
    },
    permChange (item) {
      const obj = { permissao: false }
      obj.permissao = (item.permissao !== 'Pode editar') + 1
      this.$functions.put('/v0/company/' + this.$store.state.clientPortal.login.idCompany + '/member/' + item.id + '/permission', obj, this.$store.state.clientPortal.config)
    },

    SendEmail (item) {
      this.$store.commit('generalchangesclient', item)
      this.$store.commit('dialogDetail', { type: 'Confirm', range: 'MemberUser', name: 'email', title: 'Gostaria de enviar os dados do login novamente para esse contato?', text: '' })
    },
    deleteItem (item) {
      this.$store.commit('generalchangesclient', item)
      this.$store.commit('dialogDetail', { type: 'Confirm', range: 'MemberUser', name: 'delete', title: 'Tem Certeza?', text: 'Tem certeza que deseja excluir? Essa ação não poderá ser desfeita' })
    },
    editItem (item) {
      this.$store.commit('generalchangesclient', item)
      this.$store.commit('dialogDetail', { name: 'edit', range: 'MemberUser', type: 'Form', title: 'Compartilhar acesso', text: 'Para que outras pessoas possam acessar a plataforma, compartilhe o acesso fazendo o cadastro abaixo.' })
    }
  },
  computed: {
    sorting () {
      const array = { Nome: 'nome', Email: 'email', Permissão: 'permition' }

      for (const [key, value] of Object.entries(array)) {
        if (key === this.selectedKey) {
          return value
        }
      } return 'nome'
    }
  },
  data () {
    return {
      selectedKey: 0,
      headers: [
        { text: 'NOME COMPLETO', align: 'center', sortable: false, value: 'nome', width: '25%' },
        { text: 'E-MAIL', align: 'center', value: 'email', sortable: false, width: '25%' },
        { text: 'PERMISSÃO', align: 'center', sortable: false, value: 'canEdit', width: '10%' },
        { text: 'AÇÕES', align: 'center', value: 'actions', sortable: false, width: '15%' }
      ],
      users: [],
      re: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      }
    }
  }
}
</script>

  <style scoped>
  .select{
    position: relative;
    top: 8px;
    width: 240px;
    margin-right: 32px;
  }
   .spacervertical{
      margin: 1px;
      min-height: calc(50vh - 300px);
    }
  .Titulo{
    margin-left: 16px;
    margin-top: 26px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #D2232A;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .Table{
    margin-left: 16px;
    margin-bottom: 10px;
  }
  </style>
