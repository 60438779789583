import { render, staticRenderFns } from "./LoginError.vue?vue&type=template&id=2cf7d500&scoped=true"
import script from "./LoginError.vue?vue&type=script&lang=js"
export * from "./LoginError.vue?vue&type=script&lang=js"
import style0 from "./LoginError.vue?vue&type=style&index=0&id=2cf7d500&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf7d500",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VIcon,VRow,VSlideYTransition})
