<template>
  <div class='div'>
    <v-tab @click='func()' class='tab'>
        <v-icon> mdi-table-of-contents </v-icon>
    </v-tab>
    <v-slide-y-transition>
      <v-card width="160" v-show="card" @click='download' height="48" class='card'>
        <p class='text my-3 ml-4'> Baixar relatório </p>
      </v-card>
    </v-slide-y-transition>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default {
  props: ['data'],
  data: () => ({
    card: false
  }),
  methods: {
    func () {
      this.card = !this.card
    },
    download () {
      const wb = XLSX.utils.book_new()
      wb.props = {
        Title: 'BRFCode - Relátorio',
        Subject: 'Dados de Entrega',
        Author: 'BRF'

      }
      wb.SheetNames.push('Primeira Pagina')
      wb.Sheets['Primeira Pagina'] = this.sheetcreate(this.data)
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
      FileSaver.saveAs(new Blob([this.convert(wbout)], { type: 'application/octet-stream' }), 'QRCodeKit_Relatório.xlsx')
    },
    convert (s) {
      const buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
      const view = new Uint8Array(buf) // create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF // convert to octet
      return buf
    },
    sheetcreate (obj) {
      const wsData = obj
      const ws = XLSX.utils.json_to_sheet(wsData)
      return ws
    }
  }
}
</script>

<style>
.div{
    height: 78px;
    width: 160px;
    position: absolute;
    top: 32px;
    right: 32px;
}
.text{
    font-weight: 500;
font-size: 14px;
line-height: 143%;
/* or 20px */

display: flex;
align-items: center;
letter-spacing: 0.15px;

/* palette / text / primary */

color: rgba(0, 0, 0, 0.87);

}
.tab{
    width: 0px;
    position: absolute;
    right: 0px;
        z-index: 999;
}
.card{
    position: absolute;
    right: 0px;
    bottom: 0px;
        z-index: 999;
}
</style>
