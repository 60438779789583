import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        azul: '#134B97',
        branco: '#FAFAFA',
        cinza: '#EFEFEF',
        roxo: '#D2232A',
        laranja: '#F26522',
        vermelho: '#d2232a',
        preto: '#000000'
      }
    }
  }
})

export default vuetify
