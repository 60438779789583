import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeLogin.vue'
import clientportal from '../views/ClientPortal.vue'
import adminportal from '../views/AdminPortal.vue'
import error404 from '../views/Errors/ErrorN404.vue'
import error from '../views/Errors/ErrorN.vue'
import colab from '../views/ColabPortal.vue'
import genericError from '../views/Errors/genericError.vue'
import PrivacyScreen from '../views/PrivacyScreen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/brf',
    name: 'AdminPortal',
    component: adminportal
  },
  {
    path: '/erroGeral/:id/:info',
    name: 'Erro',
    component: genericError
  },
  {
    path: '/error/:id',
    name: 'Erro',
    component: error
  },
  {
    path: '/error/:id/:info',
    name: 'Erro',
    component: error
  },
  {
    path: '/colaborador/:empresa',
    name: 'ColabPortal',
    component: colab
  },
  {
    path: '/Privacy',
    name: 'Privacidade e termos',
    component: PrivacyScreen
  },
  {
    path: '/natal2022/:empresa',
    name: 'Natal2022',
    component: colab
  },
  {
    path: '/clientes',
    name: 'ClientsPortal',
    component: clientportal
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    name: 'erro 404',
    component: error404
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
