import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import calls from './general/functions.js'

Vue.use(Vuex, axios)

const AdminOpt = { page: 0, temp: {}, tab: 0, filter: { vendedor: null, regional: null, situacao: null, type: null }, order: 'name' }

function comparelist (list, order) {
  function compare (a, b) {
    if (a[order] < b[order]) {
      return -1
    }
    if (a[order] > b[order]) {
      return 1
    }
    return 0
  }
  if (list.length !== 0) {
    list.sort(compare)
  }
  return list
}

function filterArrayByStr (list, filter) {
  const array = []
  list.forEach(element => {
    const holder = (JSON.stringify(element))
    if (holder.includes(filter)) {
      array.push(element)
    }
  })
  return array
}

const generic = {
  state: {
    pages: [],
    currentPage: 0
  },
  mutations: {
    changeCurrentPage (state, payload) {
      state.currentPage = payload
    },
    setPages (state, payload) {
      state.pages = payload
    }
  }
}

const AdminPortal = {
  state: {
    pages: [
      { name: 'Empresas', link: '', appBarButton: true, icon: 'mdi-briefcase-variant', index: 0 },
      { name: 'Acesso Geral', link: '', appBarButton: true, icon: 'mdi-account', index: 1 },
      { name: 'Dashboard', link: '', appBarButton: false, icon: 'mdi-poll', index: 2 },
      { name: 'Sobre', link: '', appBarButton: false, icon: 'mdi-information-outline', index: 3 }
    ],
    options: { page: 0, temp: { page: 'off' }, tab: 0, filter: { vendedor: null, regional: null, situacao: null, type: null }, order: 'name' },
    dialog: { type: 'wait' },
    EditedClient: { },
    ClientData: {
      type: [],
      refreshed: true,
      body: [{ name: 'null' }],
      sellers: ['null'],
      regional: ['null']
    },
    AccessDialog: { create: { show: false }, delete: { show: false } }
  },
  getters: {
    Filteredclients: state => {
      const holder = []
      for (let index = 0; index < state.ClientData.body.length; index++) {
        if (state.options.filter.vendedor === null || state.options.filter.vendedor === state.ClientData.body[index].seller) {
          if (state.options.filter.regional === null || state.options.filter.regional === state.ClientData.body[index].regional) {
            // eslint-disable-next-line eqeqeq
            if (state.options.filter.situacao === null || state.options.filter.situacao == state.ClientData.body[index].situation) {
              // eslint-disable-next-line eqeqeq
              if (state.options.filter.type === null || state.options.filter.type == state.ClientData.body[index].typeName) {
                holder.push(state.ClientData.body[index])
              }
            }
          }
        }
      }
      comparelist(holder, state.options.order)
      return holder
    }
  },
  mutations: {
    editAccessDialog (state, payload) {
      state.AccessDialog = { ...state.AccessDialog, ...payload }
    },
    adminOptions (state, payload) {
      const obj = Object.assign(AdminOpt, payload)
      state.options = obj
    },
    async RefreshAdminPortalData (state, payload) {
      const config = { headers: { Access_Token: payload } }
      state.ClientData.refreshed = false
      const holder = await calls.get('/v0/companies', config)
      state.ClientData.body = holder.data
      const lists = { sellers: [], regional: [] }
      state.ClientData.body.forEach(element => {
        if (!lists.sellers.includes(element.seller)) { lists.sellers.push(element.seller) }
        if (!lists.regional.includes(element.regional)) { lists.regional.push(element.regional) }
      })
      const typesList = []
      for (let index = 0; index < holder.types.length; index++) {
        const element = holder.types[index]
        typesList.push(element.name)
      }
      state.ClientData.type = typesList
      state.ClientData.sellers = lists.sellers
      state.ClientData.regional = lists.regional
      state.ClientData.status = ['Todas', 'Ativadas', 'Desativadas']
      state.ClientData.refreshed = true
    },
    dialogEdit (state, payload) {
      state.dialog = Object.assign({ type: '', name: '', title: '', text: '', range: '' }, payload)
    },
    EditedClientEdit (state, payload) {
      state.EditedClient = payload
    }
  }
}

const clientPortal = {
  state: {
    loading: false,
    dialogDetail: { type: '', name: '', title: '', text: '', range: '' },
    sucessDialog: { show: false, title: '', text: '' },
    isDetailList: false,
    config: { headers: { Access_Token: null } },
    pagination: 0,
    opendetail: { id: 0 },
    page: 0,
    clientData: [],
    pageclient: { list: 'list' },
    login: { name: 'João João', company: 'Sadia', token: null },
    auth: { isAuth: false, token: null },
    clientdetails: { isOpen: false, data: [] },
    general: { index: null },
    order: '',
    ordergeral: '',
    orderpromoters: '',
    filter: '',
    filterpromoters: '',
    detailtab: false,
    lists: [{}],
    dialogs: [{ }],
    EditedClient: { },
    Access: {}
  },
  getters: {
    FilteredCollaborator: state => {
      let lists = comparelist(state.opendetail.collaborators, state.order)
      lists = filterArrayByStr(lists, state.filter)
      return lists
    },
    FilteredPromoters: state => {
      if (!state.opendetail.id) { return [null] }
      let lists = comparelist(state.opendetail.promoters, state.orderpromoters)
      lists = filterArrayByStr(lists, state.filterpromoters)
      return lists
    },
    FilteredList: state => {
      if (state.opendetail.id) { return [null] }
      const lists = comparelist(state.clientData, state.ordergeral)
      return lists
    }
  },
  mutations: {
    changeLoading (state, payload) {
      state.loading = payload
    },
    flipLoading (state) {
      state.loading = !state.loading
    },
    async refreshAccess (state) {
      const holder = { owner: { list: [], name: 'Proprietário', footer: false }, members: { list: [], name: 'Membros', footer: true } }
      const h = await calls.get('/v0/company/' + state.login.idCompany + '/members', state.config)
      holder.members.list = h.data
      holder.owner.list[0] = holder.members.list[0]
      holder.owner.list[0].isOwner = true
      holder.members.list.shift()
      holder.members.list.forEach(element => {
        element.permissao = element.accessLevel === 1 ? 'Pode editar' : 'Pode visualizar'
      })
      state.Access = holder
    },
    dialogDetail (state, payload) {
      state.dialogDetail = Object.assign({ type: '', name: '', title: '', text: '', range: '' }, payload)
    },
    showSucessDialog (state, payload) {
      state.sucessDialog = payload
    },
    clearSucessDialog (state) {
      state.sucessDialog = { show: false, text: '', title: '' }
    },
    async clearCheck (state) {
      state.opendetail.collaborators.forEach(element => {
        element.check = false
      })
      state.opendetail.promoters.forEach(element => {
        element.check = false
      })
    },
    async allCheck (state) {
      state.opendetail.collaborators.forEach(element => {
        element.check = true
      })
      state.opendetail.promoters.forEach(element => {
        element.check = true
      })
    },
    async RefreshClientPortalData (state) {
      const holder = await calls.get('/v0/Company/' + state.login.idCompany + '/delivery', state.config)
      state.clientData = holder.data
    },
    changepagination (state, payload) {
      state.pagination = payload
    },
    clearclientdetail (state) {
      state.opendetail = { id: 0 }
    },
    changepage (state, payload) {
      state.page = payload
    },
    flipDetail (state) {
      state.isDetailList = !state.isDetailList
    },
    clientdetail (state, payload) {
      state.opendetail = payload
    },
    updatelogin (state, payload) {
      state.login = payload
      state.pagination = 0
      state.opendetail = { id: 0 }
      state.config = { headers: { Access_Token: payload.token } }
    },
    pageclientchange (state, payload) {
      state.pageclient = payload
    },
    pushlist (state, payload) {
      state.lists.push(payload)
    },
    editIsAuth (state, payload) {
      state.auth.isAuth = payload
    },
    editAuthToken (state, payload) {
      state.auth.token = payload
    },
    editclientdetails (state, payload) {
      state.clientdetails = payload
    },
    generalchangesclient (state, payload) {
      state.general = payload
    },
    changeorderclient (state, payload) {
      state.order = payload
    },
    changeordergeral (state, payload) {
      state.ordergeral = payload
    },
    changefilterclient (state, payload) {
      state.filter = payload
    },
    changeorderpromoter (state, payload) {
      state.orderpromoters = payload
    },
    changefilterpromoter (state, payload) {
      state.filterpromoters = payload
    },
    dialogseditclient (state, payload) {
      state.dialogs = payload
    }
  }
}

const store = new Vuex.Store({
  modules: {
    adminPortal: AdminPortal,
    clientPortal,
    generic
  }

})

export default store
