<template>
   <v-navigation-drawer color="branco" permanent class='drawer'>
      <div>
         <!-- A imagem com o router pra voltar -->
         <router-link to='/' class='a'>
            <div class='imageBig size1off whiteBGC'>
            <v-img
               max-height='36'
               max-width='141'
               class='imgs'
               src='@/assets/img/BRFCode.png'></v-img>
            </div>

            <!-- Para o caso da tela estar pequena é usado essa imagem -->
            <div class='imageSmall size1'>
            <v-img
               max-height='36'
               max-width='36'
               class='imgs'
               src='@/assets/img/BoxBRFCode.png'></v-img>
            </div>
         </router-link>

         <v-divider/>

         <!-- As tabs com os routers para trocar de página -->
         <v-tabs vertical color='roxo' @change="changepagination" class='whiteBGC' v-model="value">
            <v-tab v-for="item in pagesArray" :key="item.index" active-class="activeTab" class='capitalize tabText whiteBGC'>
               <v-icon v-if="$store.state.generic.currentPage === item.index" class='pr-8' color="vermelho"> {{item.icon}} </v-icon>
               <v-icon v-if="$store.state.generic.currentPage !== item.index" class='pr-8'> {{item.icon}} </v-icon>
               {{item.name}}
               <v-spacer/>
            </v-tab>
         </v-tabs>

         <!-- As informações na base da nav -->
         <div class='bottomDiv'>
            <v-row class='nomargin'>

               <!-- O avatar e descrição do usuário -->
               <v-img
                  class='rounded-circle avatar'
                  max-height='40px'
                  max-width='40px'
                  contain :src="user.photo" />
               <div class='size1off'>
                  <p class='bottomText'>{{user.name || 'Espere...'}}</p>
                  <p class='bottomText txtgrey'>{{user.profile.jobTitle || 'Waiting...'}}</p>
               </div>

            </v-row>

            <v-divider/>

            <v-tab class=logOutTab @click="$msal.signOut()">
               <v-icon class='mr-8 my-4'> mdi-logout </v-icon>
               <p class='my-4 logOutText capitalize size1off'> Sair </p> <v-spacer/>
            </v-tab>

         </div>
      </div>
   </v-navigation-drawer>
</template>

<script>
export default {
  methods: {
    changepagination (item) {
      this.$store.commit('changeCurrentPage', item)
    }
  },
  watch: {
    currentPage () {
      this.value = this.$store.state.generic.currentPage
    }
  },
  computed: {
    currentPage () {
      return this.$store.state.generic.currentPage
    },
    pagesArray () {
      return this.$store.state.generic.pages
    },
    // Objeto com fotos e dados padrões
    user () {
      let user = null
      if (this.$msal.isAuthenticated()) {
        user = {
          ...this.msal.user,
          profile: {},
          photo: require('@/assets/img/avatarDefault.png')
        }
        if (this.msal.graph) {
          if (this.msal.graph.profile) {
            user.profile = this.msal.graph.profile
            user.photo = URL.createObjectURL(this.msal.graph.photo)
          }
        }
      }
      return user
    }
  },
  data: () => ({ value: 0 })
}
</script>

<style scoped>

   /* Classes para tamanho / centralização */

   .imgs{
      margin: auto;
   }

   .imageBig{
       padding-top: 18px !important;
      min-height: 72px;
       align-content: center;
   }

   .imageSmall{
       padding-top: 0px !important;
      min-height: 72px;
       align-content: center;
   }

   .logOutTab {
      height: 42px !important;
   }

   .drawer {
      min-height: 320px;
   }

   .avatar{
      height: 40px;
      width: 40px;
      margin-left: 10px;
      margin-right: 20px;
      margin-bottom: 10px;
   }

   .bottomDiv{
      position: absolute;
      bottom: 0;
   }

   .firstTabSize{
      min-height: 64px;
   }

   /* Classes para cores / detalhes */
   .whiteBGC{
      background-color: #FAFAFA !important;
   }

   .linkNormalization{
      text-decoration: none;
      color: #504e4e;
   }

   .activeTab{
      background-color: #EFEFEF;
      color: #D2232A !important;
   }

   /* Classes para customização de texto */
   .tabText{
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      margin-bottom: 0px;
      color: #000000;
   }

   .bottomText{
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.3px;
      margin-bottom: 0px;
      margin-left: 0px;
   }

   .logOutText{
      font-size: 16px;
      line-height: 150%;
      color: #000000 !important;
   }

</style>
