import { render, staticRenderFns } from "./LoginColab.vue?vue&type=template&id=1d517718&scoped=true"
import script from "./LoginColab.vue?vue&type=script&lang=js"
export * from "./LoginColab.vue?vue&type=script&lang=js"
import style0 from "./LoginColab.vue?vue&type=style&index=0&id=1d517718&prod&scoped=true&lang=css"
import style1 from "./LoginColab.vue?vue&type=style&index=1&id=1d517718&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d517718",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})
