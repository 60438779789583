<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class='line'>
    <v-row class='cinza row10'>

      <!-- As três seleções de filtro -->

      <v-text-field
        v-model="filter.search"
        outlined
        label="Pesquisar"
        class="fullwidth nomargin tab1 mt-1 mr-5 nomargin"
        dense
        append-icon="mdi-magnify"
      > </v-text-field>

        <v-select class='fullwidth nomargin tab1 mt-1 mr-5 nomargin'
          :items="filter.accessTypeArray"
          label="Acesso"
          clearable
          @input="filter"
          v-model="filter.accessType"
          :menu-props="{offsetY: true}"
          color="vermelho"
        ></v-select>

        <v-select class='fullwidth nomargin  tab1 mt-1 mr-5 nomargin'
          :items="filter.createdByArray"
          label="Responsável"
          clearable
          @input="filter"
          v-model="filter.createdBy"
          :menu-props="{offsetY: true}"
          color="vermelho"
        ></v-select>

      <v-spacer/><v-spacer/>

      <!-- A opção de ordernar -->
      <!-- <v-col class='tab3 mt-1 pr-3 nomargin'>
        <v-select
        class='nomargin'
          v-model="order"
          :items="orderArray"
          label="Ordenar por"
          @input="ordernar"
          clearable
          :menu-props="{offsetY: true}"
          color="vermelho"
        ></v-select>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    filter: Object
  },
  computed: {
    alerta () {
      return this.$store.state.adminPortal.options.temp.deleted
    },
    info () {
      return this.selectedStatus === 'Ativas' ? 'desativada' : 'ativada'
    }
  },
  data: () => ({
    alerted: false,
    order: '',
    visual: '',
    selected: { regional: null, seller: null, status: null },
    orderArray: ['Empresa', 'E-mail', 'Id', 'Responsável', 'Regional', 'Vendedor', 'Situação', 'telefone'],
    orderobj: { Empresa: 'name', 'E-mail': 'email', Responsável: 'nameUser', Regional: 'regional', Vendedor: 'seller', Situação: 'situation', Telefone: 'telephone' },
    selectArray: [
      {
        item: 'regional',
        label: 'Regional',
        model: 'regional'
      },
      {
        item: 'sellers',
        label: 'Vendedor',
        model: 'seller'
      },
      {
        item: 'status',
        label: 'Status',
        model: 'status'
      }]
  }),
  // created () {
  //   const str = 'regional'
  // },
  methods: {
    // Para avisar a página que devemos puxar pra cima
    updatepage () {
      this.$store.commit('adminOptions', { temp: { update: true } })
    },

    // Desatileto alerta
    async alertoff () {
      this.alerted = false
      await this.$store.commit('adminOptions', { temp: { deleted: false } })
    },

    // Atualizar a visualização
    exporttabchange () {
      this.updatepage()
      this.$store.commit('adminOptions', { tab: this.visual })
    },

    // Atualizar o filtro
    // filter () {
    //   this.updatepage()
    //   let holder = true
    //   if (this.selected.status === 'Desativadas') { holder = 0 } else if (this.selected.status === 'Ativas') { holder = 1 } else { holder = null }
    //   this.$store.commit('adminOptions', { filter: { regional: this.selected.regional, vendedor: this.selected.seller, situacao: holder } })
    // },

    // Mudar a ordenação
    ordernar () {
      let holder = 'name'
      // Passamos aqui por um objeto conferindo cada propriedade, já que o nome das propriedades é diferente
      // do nome presente na array
      for (const [key, value] of Object.entries(this.orderobj)) {
        if (key === this.order) { holder = value }
      }
      this.$store.commit('adminOptions', { order: holder })
    }
  }
}
</script>

<style scoped>

  /* Classes para posicionamento / tamanho */
  .sizeFilter{
    width: 90px;
    margin-right: 16px;
  }

  .row10 {
    width: 100%;
    margin-left: 0px;
  }

  .alert {
    background-color: #C8E6C9;
    border: 2px solid #4CAF50;
    border-radius: 12px;
    position: absolute;
    left: 32px;
    top: 0px;
    z-index: 99;
    padding-top: 13px;
    padding-left: 18px;
    padding-bottom: 13px;
    width: calc(100% - 64px);
    margin-top: 90px;
    margin-bottom: 16px;
  }

  .tab1 {
    height: 40px;
    min-width: 15% !important;
    max-width: 15% !important;
  }

  .tab3{
    height: 40px;
    min-width: 18% !important;
    max-width: 18% !important;
  }

  .line {
    width: calc(100% - 64px);
    padding-top: 35px;
    padding-bottom: 15px;
    margin-bottom:-2px;
    margin-top:1px;
    height: 96px;
    margin-left: 0px;
  }

  /* Classes de formatação / cor */
  .alertxt {
    padding-top: 12px;
    padding-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 166%;
    color: #3b863e;
    letter-spacing: 0.4px;
  }

  .activetab3 {
    background-color: #0000001f;
  }

</style>
