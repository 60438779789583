<template>
<div :style="{ backgroundColor: '#3F51B526' }" class='tela' >
    <div>
    <v-card width="570" height="488" class='card pl-16 pr-0'>
    <p class='title29 CoHead mt-5'> Ops! Tivemos um problema. </p>
    <p class='text29 ml-0 mt-5' >
        Ocorreu um erro de comunicação com o <br>
        sistema. Mas não se preocupe, abaixo estão <br>
        algumas soluções possíveis. Caso a situação <br>
        persista, entre em contato com nosso suporte.
    </p>
    <div class='mt-8'>
        <li v-for="item in items" :key="item.message" class='text29 mt-2'>
            {{ item.message }}
        </li>
    </div>
     <v-row class='pr-10 mt-12'>
         <v-spacer/>
        <v-btn @click="open()" class='btn btntxt rounded-xl capitalize mr-3' color="azul" outlined >
             Suporte
        </v-btn>
        <v-btn @click="$router.go(-2)" class='btn rounded-xl' color="azul" >
            <p class='capitalize my-1 btntxt'> Recarregar página </p>
        </v-btn>
        </v-row>
    </v-card>
    </div>
    <div :hidden='!($route.params.id === "511")'>
<v-img
                  max-height='calc(70vh)'
                  max-width='calc(90vw)'
                    contain
                  class='standing'
                  src="@/assets/img/errors/511/people.png"
               />
    </div>
      <v-dialog v-model='suport' max-width='610px'>
      <v-card>
        <v-card-title class='CoHead mt-0'>
        Suporte
        </v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Sua mensagem será encaminhada para o suporte BRF e respondida o <br> mais breve possível. Se preferir entre em contato diretamente pelo <br> e-mail <a href='mailto:suportebrfcode@brf.com' class='contxt2'> suportebrfcode@brf.com </a> </p>
           <v-form class='form'>
          <v-text-field v-model="obj.nome" label="Nome completo"></v-text-field>
          <v-text-field v-model="obj.email" label="E-mail" class='mb-4'></v-text-field>
          <v-select
          :items="select"
            label="Assunto"
            clearable
            v-model="obj.assunto"
            :menu-props="{offsetY: true}"
            color="azul"
          ></v-select>
           <v-textarea height="72" full-width v-model="obj.txt" label="Mensagem" class='mb-4'></v-textarea>
           </v-form>
        </v-card-text>
          <v-row class='nomargin'>
            <v-spacer/>
            <v-btn color='azul' @click='(suport = false)' rounded outlined class='mr-3 capitalize mb-2'> Cancelar </v-btn>
            <v-btn color='azul' @click='send()' rounded class='mb-2 mr-2 capitalize'> <p class='txtwhite my-2'> Enviar </p> </v-btn>
          </v-row>
    </v-card>
    </v-dialog>

       <v-dialog v-model='sucess' max-width='340px' persistent>
      <v-card>
        <v-card-title class='CoHead mb-3'> Mensagem enviada </v-card-title>
        <v-card-text><p> Sua mensagem foi enviada para o suporte. A resposta será enviada para o e-mail informado no formulário. </p></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='roxo' rounded @click='(sucess = false)' class='capitalize'><p class='txtwhite my-auto capitalize'>Entendido</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>

export default ({
  data: () => ({
    select: ['Erros', 'Sugestões', 'Ajuda', 'Outros'],
    items: [
      { message: 'Atualize seu navegador web' },
      { message: 'Troque para outro navegador web' },
      { message: 'Recarregue a página' },
      { message: 'Verifique se sua conexão está estável' }
    ],
    suport: false,
    sucess: false,
    obj: {}
  }),
  methods: {
    open () {
      this.suport = true
    },
    send () {
      this.obj.info = this.$route.params.info
      this.obj.status = this.$route.params.id
      this.$http.post(this.$config.url_backend + '/Support/Email', this.obj)
      this.suport = false
      this.sucess = true
    }
  }
}
)
</script>

<style scoped>
.form{
    width: 500px;
}
 .contxt2 {
     text-decoration: none !important;
        text-align: right;
        margin-right: 0px;
    }
  .btn{
  }
    .tela{
      height: 100vh;
      width: 100vw;
    }
    .icon{
        font-size:20px
    }
    .btntxt{
        font-size: 12px;
line-height: 24px;
color: #FAFAFA;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;
    }
    .title29{
font-size: 34px;
line-height: 123.5%;
letter-spacing: 0.25px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;
    }
    .text29{
font-weight: 500;
font-size: 18px;
line-height: 157%;
letter-spacing: 0.1px;
color: rgba(0, 0, 0, 0.54);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;
    }
    .CoHead{
    font-family: 'Co Headline Corp'
  }
    .card{
      padding-top: 20px;
      padding-left: 50px;
      padding-right: 50px;
        z-index: 99;
        position: absolute;
     right: 98px;
     top: 91px;
    }
    .standing{
position: absolute;
     left: 32px;
     bottom: 6px;

    }
    @media screen and (max-width: 740px) {
  .card {
    position: relative;
    margin-left: 110px;
    margin-right: 5%;
    width: 80% !important;
    align-content: center;
  }
 .standing{
   position: relative;
 }
 .title29{
font-size: 25px;
 }
 .text29{
   font-size: 15px;
   margin: 1px;
 }
 .btn{
   bottom: 10px;
 }
}
</style>
