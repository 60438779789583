/* eslint-disable no-extra-boolean-cast */
<template>
  <v-slide-y-transition>
    <div v-show="show">
      <v-card-title class='CoHead mt-0'>Espere um segundo <v-progress-circular
      class='ml-5'
      :size="20"
      color="azul"
      indeterminate
      ></v-progress-circular></v-card-title>
      <v-card-text class='mx-0 my-0'>
        <p class=' mb-3'> Redirecionando... </p>
      </v-card-text>
    </div>
  </v-slide-y-transition>
</template>

<script>
export default {
  props: { show: Boolean }
}
</script>
