/* eslint-disable no-extra-boolean-cast */
<template>
  <div class='fullwidth'>

    <v-dialog v-model="screenDef.promoter" max-width='610px'>
      <v-card width="610" height="192" class='px-6 py-5'>
        <v-card-title class='CoHead mt-0 pa-0'>
          Acesso de Promotor
        </v-card-title>
        <v-card-text class='mt-6 pa-0'>
          <p class='pr-3'> o Acesso de promotor é válido apenas no aplicativo, para baixar-lo clique no botão abaixo ou procure "brfcode" na App Store do seu celular </p>
          </v-card-text>
        <v-card-actions class='mr-n6 mt-2'>
          <v-spacer/> <v-btn @click='goToProm()' rounded color="azul"> <p class='capitalize txtwhite mt-3'> Acessar </p> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <colabDialog :colabDialog="screenDef.colab.show" :colabData="screenDef.colab.array"></colabDialog>

    <vue-custom-scrollbar  class="scrollareacolab nomargin"  :settings="settings">
      <!-- <v-tab v-show="stermo" class='goback' @click="term()">
        <v-icon large class='icon'>mdi-chevron-left </v-icon>
      </v-tab> -->
      <v-row class="row">
        <div class='size30 nomargin'>
          <v-img class="smollimg mx-auto" contain src='@/assets/img/BRFCode.png'/>
          <vue-custom-scrollbar  class="nomargin options" :settings="settings">
            <div>
              <h1 class='colabTitle CoHead mt-4 mb-1'> {{ screenDef.intro.title }} </h1>
              <h1 class='colabTitle CoHead mb-3'> {{ screenDef.intro.title2 }} </h1>
              <p class='sub CoHead'> {{ screenDef.intro.subTitle }} </p>
              <p class='colabtxt'> {{ screenDef.intro.desc }} </p>
            </div>

            <div style="position: relative;">
              <error style="" :show="erro.show" :text="erro.text"></error>
              <loading style="" :show="screenDef.loading" ></loading>
            </div>

            <div style="position: relative;">
              <!-- <v-slide-y-transition style="position: absolute;"> -->
                <options  :options="options" @choose="choose"  @screenControl="screenControl"></options>
              <!-- </v-slide-y-transition> -->

              <!-- <v-slide-y-transition style="position: absolute;"> -->
                <inputLogin v-show="buya" @startLogin="Login" @openCompany="setcompanydetails"></inputLogin>
              <!-- </v-slide-y-transition> -->

              <!-- <v-slide-y-transition style="position: absolute;"> -->
                <temporary v-show="temporary.isOpen" @finish="showOptions" :data="temporary" @screenControl="screenControl"></temporary>
              <!-- </v-slide-y-transition> -->
            </div>
          </vue-custom-scrollbar>
        </div>

        <v-card width="50%" color="#" class='rounded-0 elevation-0 mt-3 size3off' >
          <div class='card2'>
            <img
              contain
              class="bigimg"
              src='@/assets/img/clientportal/banner1.png'/>
          </div>
        </v-card>
      </v-row>
      <UniqueFooter class='UniqueFooter'/>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import colabDialog from '../components/client/login/dialogs/LoginColab.vue'
import error from '../components/client/login/LoginError.vue'
import inputLogin from '../components/client/login/LoginInput.vue'
import UniqueFooter from '../components/shared/NewFooter.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import loading from '../components/client/login/LoginLoading.vue'
import temporary from '../components/client/login/LoginTemporary.vue'
import options from '../components/client/login/LoginOptions.vue'

export default {
  components: {
    colabDialog,
    options,
    error,
    loading,
    inputLogin,
    vueCustomScrollbar,
    UniqueFooter,
    temporary
  },

  data: () => ({
    colabData: {},
    screenDef: {
      colab: { show: false },
      promoter: false,
      loading: false,
      error: { show: false, text: '' },
      intro: {
        title: 'Login',
        subTitle: 'Bem vindo à plataforma de entrega de Kits das BRF!',
        desc: 'Para acessar, preencha os campos abaixo com suas credenciais:'
      }
    },
    temporary: { isOpen: false },
    holder: {},
    options: [],
    erro: { show: false, text: 'A credencial inserida está incorreta' },
    loginInfo: { array: '', options: '', dialog: '' },
    colabSelected: '',
    colabArray: [],
    colabBtn: true,
    colabItems: [],
    error: false,
    passwordform: '',
    emailform: '',
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false
    },
    colabDialog: false,
    buya: true
  }),
  methods: {
    screenControl (obj) {
      if (obj.error) { this.generateError(obj.error) } else {
        this.screenDef = { ...this.screenDef, ...obj }
      }
    },
    goToBRF () {
      this.$router.push('/brf')
    },
    async choose (n) {
      const element = n

      if (element.accessType === 0) {
        this.goToBRF()
      } else if (element.accessType === 2 || element.accessType === 3) {
        this.screenControl({ promoter: true })
      } else {
        const r = await this.$http.get(this.$config.url_backend + '/v0/account/' + element.idAccount + '/access/' + element.id, { headers: { Access_Token: element.token } })
        if (r.status === 200) {
          await this.$store.commit('editIsAuth', true)
          if (element.accessType === 1) {
            await this.$store.commit('updatelogin', { name: r.body.name, company: r.body.company, senhaTemporaria: r.body.isPasswordTemporary, permissao: r.body.canEdit, token: r.body.token, idCompany: r.body.idCompany, personalization: r.body.personalization })
            this.$router.push('/clientes')
          }
        }
      }
    },

    async showOptions () {
      this.temporary.isOpen = false
      this.screenControl({ intro: { title: 'Escolha qual perfil você deseja acessar no momento!', desc: 'Detectamos que você possue uma conta associada a diferentes tipos de acesso. Escolha uma das opções de acesso para entrar em seu perfil' } })

      this.screenControl({ loading: false })
      await this.$functions.sleep(200)

      this.options = this.holder
    },

    goToProm () {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.brf.qrcode'
    },

    async generateError (text) {
      this.erro.text = text
      this.erro.show = true
      await this.$functions.sleep(3000)
      this.erro.show = false
    },
    async colabChanges () {
      this.colabBtn = false
    },
    async setcompanydetails () {
      this.colabDialog = true
      const array = []
      const obj = await this.$functions.get('/v0/companies/CustomPages')
      obj.data.forEach(element => {
        array.push(element.nome)
      })
      this.colabData.colabArray = obj.data
      this.colabData.colabItems = array
      this.screenDef.colab = { show: true, array }
    },
    async showPasswordChange (n) {
      this.screenControl({
        intro: {
          title: 'Bem vindo(a),',
          title2: 'Para continuar, defina uma senha',
          desc: 'Para ter os próximos acessos de forma segura defina uma senha própria, com, no mínimo, 8 caracteres incluindo letras e numerais.'
        }
      })
      this.screenControl({ loading: false })
      await this.$functions.sleep(200)
      this.temporary.email = n.email
      this.temporary.password = n.password
      this.temporary.isOpen = true
    },
    validate () {
      this.$refs.form.validate()
    },
    async goToColab () {
      this.$router.push('/colaborador/' + this.colabSelected)
    },
    async Login (n) {
      this.buya = !this.buya
      await this.$functions.sleep(200)
      this.screenControl({ loading: true })
      await this.$functions.sleep(200)
      let holder
      await this.$http.post(this.$config.url_backend + '/v0/Login', {}, { headers: { email: n.email, password: n.password } })
        .then(res => { holder = res })
        .catch(err => {
          holder = err
        })

      if (holder.status === 200) {
        this.holder = holder.body.accesses
        if (holder.body.isPasswordTemporary) {
          this.showPasswordChange(n)
        } else if (holder.body.accesses.length === 1) {
          this.choose(holder.body.accesses[0])
        } else {
          this.showOptions()
        }
      } else {
        this.generateError('A credencial inserida está incorreta. Por favor confira e tente novamente.')
        this.screenControl({ loading: false })
        this.buya = !this.buya
        // clear the child
      }
    }
  },
  async mounted () {
    const self = this

    window.addEventListener('keyup', async function (event) {
      if (event.keyCode === 13) {
        await self.validate()
        if (self.valid) {
          self.Login()
        }
      }
    })
  }
}
</script>

<style scoped>
.card2{
  height: 100%;
  overflow: hidden;
}
.colabTitle{
    font-size: 30px;
    font-weight: 400;
line-height: 123.5%;
letter-spacing: 0.25px;
color: #F26522;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}

.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 354px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.goback{
  z-index: 999;
  position: absolute;
  left: 30px;
  top: 62px;
}
.link{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

letter-spacing: 0.4px;

/* palette / text / secondary */

color: #134B97 !important;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.UniqueFooter{
  bottom: 0px;
}
.scrollareacolab{
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}
.smollimg{
    height: 47px;
    width: 198px;
    margin-top: 46px;
    margin-bottom: 23px;
}
.bigimg{
  margin-top: -70px;
  width: 50vw;
  /* max-height: 100vh; */

}

@media screen and (max-height: 640px) {
  .bigimg {
    max-height: 100vh;
  }
}

.txtfield2{
    margin-top: 32px;
    width: 252px;
    height: 154px;
    margin-bottom: 48px;
}
.butao{

}

.options{
  width: 510px;
  height: calc(100vh - 200px)
}

.options div div{
  position: absolute;
  top: 0px;
  max-height: 400px;
  padding-right: 15px;
}

.size30{
    padding-left: 100px !important;
    padding-right: 90px !important;
    height: calc(100vh - 90px + 12px);
    margin-bottom: 12px;
    width: 50%;
    margin-top: 8%;
}
@media screen and (max-width: 820px) {
 .size30{
    padding-left: 10% !important;
    padding-right: 10% !important;
    width: 100%;
    margin-top: 8%;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
}
}
.colabtxt{
    font-size: 12px;
line-height: 156%;
letter-spacing: 0.4px;
color: #757575;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px 15px 0px;
}
.errore{
  margin-top: 60px;
  color: #CC2027;
}
.sub{
font-size: 16px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: #757575;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px 16px 0px;
}

.sub2{
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: #D2232A;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px 40px 0px;
}
.row{
  margin-bottom: 0px;
}
.linktxt{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

letter-spacing: 0.4px;

/* palette / text / secondary */

color: rgba(0, 0, 0, 0.54);

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 100px 0px 0px 0px;
}
</style>

<style>
  /* Classes para tamanho / margem */
  .heightt{
    height:  calc(100vh - 187px)
    }

  .table{
    height: calc(100vh - 218px)
    }

  .fullwidth{
    width: 100%
    }
.fullwidthmax{
    min-width: 100%
    }
  .fullheight {
    height: 100%
    }

  .nomargin{
    margin: 0px !important;
    padding: 0px !important
    }
  /* Classes para estilo / centralização */
  .centertxt{
    text-align: center;
  }

  .capitalize{
    text-transform: capitalize !important
  }

  .CoHead{
    font-family: 'Co Headline Corp'
  }

  /* Classes para cores / os exemplos de cores "azul, branco, cinza, preto, roxo" então no arquivo do vuetify */
  .txtblack{
    color: #000000;
  }

  .txtgrey{
    color: #0000008a !important;
  }

  .txtwhite{
    color: #FAFAFA;
  }

  .txtpurple{
    color:#662B83;
  }

  .txtblue{
    color:#134B97 !important;
  }

/* A variável size2 é mostrada se a largura da tela > 660, e a size2off é escondida no msm ponto */
@media screen and (max-width: 660px) {
  .size2off {
    display: none !important;
  }
  .size2 {
    display: flex !important;
  }
}

/* A variável size1 é mostrada se a largura da tela > 940, e a size1off é escondida no msm ponto */
@media screen and (max-width: 940px) {
  .size1off {
    display: none !important;
  }
  .size1 {
    display: flex !important;
  }
}
.size1{
  display: none
}

.size3off{
  height: calc(100vh - 90px);
}

@media screen and (max-width: 820px) {
  .size3off {
    display: none !important;
  }
  .size3 {
    display: flex !important;
  }
}
.size3{
  display: none
}

@media screen and (max-width: 1280px) {
  .size5off {
    display: none !important;
  }
  .size5 {
    display: flex !important;
  }
}
.size5{
  display: none
}

</style>
