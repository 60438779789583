/* eslint-disable no-extra-boolean-cast */
<template>
  <v-card class='positionrelative branco a pl-8' max-width="627">
    <DownloadIcon v-bind:data="data.download" v-if="isClient"/>

    <v-card-title class='title CoHead pt-5 mb-n3 ml-n4'>
      QR Codes gerados x cadastrados
    </v-card-title>

    <v-card class='branco' width="445" elevation="0">
      <v-row class='nomargin pr-2'>
        <v-tabs v-model="tab" color="#134B97" class='genXregTabs' background-color="branco">
          <v-tab  class='genXregTab capitalize'>
            Total
          </v-tab>
          <v-tab class='genXregTab capitalize'>
            Dia
          </v-tab>
          <v-tab  class='genXregTab capitalize'>
            Semana
          </v-tab>
        </v-tabs>
        <v-select :items="list" v-model="select" :menu-props="{offsetY: true}" outlined class='genXregSelect mt-2' dense>
        </v-select>
      </v-row>
    </v-card>

    <v-card class='genXreg' height="174" width="203" elevation="0">
      <column-chart :suffix="intNumber ? null : '%'" :stacked="true" :discrete="true" :legend="false" width="203px" :colors="[bluergba, grey1rgba]" height="174px" class='genXreg' :data="calculatedData" color/>
    </v-card>
      <v-tabs
        class='intNumber'
        background-color='#FAFAFA'
        color="#0000008a"
        active-class='activetab3'
        hide-slider
        v-model="intN"
        height="38"
      >
        <v-tab class='nomargin'>
          <v-icon> mdi-numeric-1-box-outline </v-icon>
        </v-tab>
        <v-tab class='nomargin'>
          <v-icon>  mdi-percent  </v-icon>
        </v-tab>
      </v-tabs>
  </v-card>
</template>

<script>
import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  props: ['data', 'isClient'],
  data: () => ({
    select: 'Todas',
    tab: 0,
    intN: false,
    eko: '150px',
    blue: '#134B97',
    blue1rgba: 'rgba(5,94,169,1)',
    bluergba: 'rgba(19,75,151,1)',
    lightbluerba: 'rgba(198,206,226,1)',
    grey1rgba: 'rgba(189,189,189,1)',
    red: '#d2232a',
    redrgba: 'rgba(210,35,42,1)',
    tamanho: '600px'
  }),
  methods: {
  },
  computed: {
    intNumber () {
      return !this.intN
    },
    tabed () {
      if (this.tab === 0) {
        return 'Total'
      }
      if (this.tab === 1) {
        return 'Dia'
      }
      if (this.tab === 2) {
        return 'Semana'
      }
      return this.tab
    },
    list () {
      const array = []
      if (typeof this.data.chart !== 'undefined') {
        for (const [key] of Object.entries(this.data.chart)) {
          array.push(key)
        }
      }
      return array
    },
    calculatedData () {
      if (this.intNumber) {
        return this.data.chart[this.select][this.tabed]
      } else {
        const total = this.data.chart[this.select][this.tabed][0].data.Gerados + this.data.chart[this.select][this.tabed][1].data.Cadastrados
        const holder = [
          { data: { Gerados: (Math.round(this.data.chart[this.select][this.tabed][0].data.Gerados / total * 1000) / 10) } },
          { data: { Cadastrados: (Math.round(this.data.chart[this.select][this.tabed][1].data.Cadastrados / total * 1000) / 10) } }
        ]
        return holder
      }
    }
  }
}
</script>

<style scoped>
.a{
  width: 610px;
  height: 335px;
}
@media screen and (max-width: 1359px) {
  .a{
  width: calc(100vw - 378px - 72px - 242px);
  height: 335px;

}
.genXregSelect{
  width: 100px !important;
}
.genXreg{
  margin-left: 40px !important;
  margin-top: calc((241px - 174px) /2);
}
}
@media screen and (max-width: 1100px) {
  .a{
  min-width: 475px;
}
}
 .activetab3{
    background-color: #0000001f;
  }
.intNumber{
  position: absolute;
  bottom: 21px;
  right: 25px;
  width: 80px !important;
}
.positionrelative{
  position: relative;
}
/* Gerados x cadastrados */
.genXregTabs{
  margin-right: 10px;
  width: calc(75px + 75px + 75px);
}
.genXregSelect{
  height: 40px !important;
  width: 200px;
}
.genXregTab{
  width: 75px;
}
.genXreg{
  margin: auto;
  margin-top: calc((241px - 174px) /2);
  background-color: #FAFAFA;
}
.title{
font-family: 'Co Headline Corp' !important;
font-size: 18px;
line-height: 22px;
color: #000000;
}
</style>
