<template>
    <div>
        <v-app-bar height="48px" elevation="0" color="branco">
            <v-tabs color="vermelho" class='ml-6' v-model="details">
                <v-tab class='capitalize' > Segurança </v-tab>
                <v-tab class='capitalize' > Termos de uso </v-tab>
                <v-tab class='capitalize' > Política de Privacidade </v-tab>
                <v-tab class='capitalize' > Tutorial </v-tab>
            </v-tabs>
        </v-app-bar>
        <div class='pa-8' v-if="details===0">
          <Seguranca/>
        </div>
        <div class='pa-8' v-if="details===1">
          <Termosdeuso/>
        </div>
        <div class='pa-8' v-if="details===2">
          <Privacidade/>
        </div>
         <div class='pa-8' v-if="details===3">
          <Tutorial v-bind:type="type"/>
        </div>
    </div>
</template>

<script>
import Tutorial from './tabs/TabTutorial.vue'
import Seguranca from './tabs/TabSeguranca.vue'
import Termosdeuso from './tabs/TabTermosDeUso.vue'
import Privacidade from './tabs/TabPrivacidade.vue'
export default {
  props: ['type'],
  data: () => ({
    details: 0
  }),
  components: {
    Privacidade,
    Termosdeuso,
    Seguranca,
    Tutorial
  }
}
</script>
