<template>
  <div class='nomargin'>

    <v-dialog v-model='Wait' max-width='430px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>
          Carregando a sua requisição
          <v-progress-circular class='ml-5' :size="20" color="azul" indeterminate > </v-progress-circular>
        </v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Aguarde um segundo </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model='this.Success' max-width='340px' persistent>
      <v-card>
        <v-card-title class='CoHead mb-3'>{{this.$store.state.clientPortal.dialogDetail.title}}</v-card-title>
        <v-card-text><p> {{this.$store.state.clientPortal.dialogDetail.text}} </p></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='roxo' rounded @click='close(true)' class='capitalize'><p class='txtwhite my-auto capitalize'>Entendido</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model='this.Form' max-width='610px' persistent class='dialogmargin'>
        <v-card>
          <v-card-title class='CoHead margin mt-0'>
            {{this.$store.state.clientPortal.dialogDetail.title}}
          </v-card-title>
          <v-card-text class='mx-0 my-0'>
            <p class='margin mb-4 pr-16 mr-16'>
              {{this.$store.state.clientPortal.dialogDetail.text}}
            </p>
            <v-container class='margin container pl-0 pt-0'>
              <v-form ref='form' v-model='valid' lazy-validation>
                <v-text-field v-model='$store.state.clientPortal.general.nome' :rules='required.concat(twoWords)' label='Nome Completo' required />
                <v-text-field v-model='$store.state.clientPortal.general.email' :rules='required.concat(emailRules)' label='E-mail' required/>

                <div v-if="this.$store.state.clientPortal.dialogDetail.range === 'Collaborator'">
                  <v-text-field v-model='$store.state.clientPortal.general.matricula' :rules='required' label='Matrícula' required />
                  <v-text-field v-model='$store.state.clientPortal.general.telefone' :rules='required.concat(numberRules)' label='Telefone (apenas números)' required  @input='telephoneCheck()'/>
                  <v-text-field v-model='$store.state.clientPortal.general.cpf' :rules='required' label='CPF' required />
                  <v-text-field v-model='$store.state.clientPortal.general.unidade' :rules='required' label='Planta/Unidade' required />
                  <v-text-field v-model='$store.state.clientPortal.general.observacao' label='Observação de Entrega' required />
                </div>

                 <v-card class="mt-4" width="250" elevation="0" :rules='required' v-if="this.$store.state.clientPortal.dialogDetail.range === 'MemberUser'">
                   <v-select v-model="choice" dense :items="['Pode visualizar', 'Pode editar']" outlined> </v-select>
                 </v-card>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class='pb-3'>
            <v-spacer></v-spacer>
            <v-btn color='azul' @click='close' rounded outlined class='mr-3 capitalize'> Cancelar </v-btn>
            <v-btn color='azul' :disabled='!valid' @mouseover='validate' @click='FormFunction()' rounded>
              <p class='my-auto txtwhite capitalize'> Confirmar </p>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

  <v-dialog v-model='Detail' max-width='610px' persistent class='dialogmargin'>
      <v-card color='branco'>
        <v-app-bar elevation="0" color="branco" class='mb-5'>
          <v-card-title class='CoHead my-0'> Colaborador </v-card-title> <v-spacer/> <v-icon @click='close()' color='preto'> mdi-close </v-icon>
        </v-app-bar>
        <v-card-text class='pl-2'>
           <v-row class='rows' v-for="item in this.details" :key="item.variable">
             <v-icon color="azul"> {{item.icon}} </v-icon> <p class='detailstxt'> {{$store.state.clientPortal.general[item.variable]}} </p>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='azul' outlined rounded @click='openEdit()' class='capitalize'>Editar Informações</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='Confirm'
      :max-width="(this.$store.state.clientPortal.dialogDetail.name === 'delete'? '340px' : '498px')"
      max-height='296px' persistent
    >
      <v-card>
        <v-card-title class='CoHead pr-16 mr-10'>  {{this.$store.state.clientPortal.dialogDetail.title}} </v-card-title>
        <v-card-text>
           {{this.$store.state.clientPortal.dialogDetail.text}}

          <div v-if="this.$store.state.clientPortal.dialogDetail.name === 'email'">
            <v-row class='mt-1'>   <v-icon class='mr-8'> mdi-account </v-icon>  <p class='dialogtext'> {{ $store.state.clientPortal.general.nome }} </p> </v-row>
            <v-row>   <v-icon class='mr-8'> mdi-email </v-icon>  <p class='dialogtext'> {{ $store.state.clientPortal.general.email }} </p> </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color='azul' outlined @click='close' rounded class='mr-1'><p class='my-auto capitalize'>Cancelar</p></v-btn>
          <v-btn color='azul' @click='ConfirmFunction()' rounded><p class='txtwhite my-auto capitalize'>Confirmar</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import regex from '../../../general/regexrules'

export default {
  computed: {
    Wait () {
      return this.$store.state.clientPortal.dialogDetail.type === 'Wait'
    },
    Success () {
      return this.$store.state.clientPortal.dialogDetail.type === 'Success'
    },
    Form () {
      return this.$store.state.clientPortal.dialogDetail.type === 'Form'
    },
    Confirm () {
      return this.$store.state.clientPortal.dialogDetail.type === 'Confirm'
    },
    Detail () {
      return this.$store.state.clientPortal.dialogDetail.type === 'Detail'
    }
  },
  data: () => ({
    seguro: {},
    url: '',
    body: '',
    choice: 'Pode visualizar',
    details: [{ icon: 'mdi-briefcase-variant', variable: 'matricula' }, { icon: 'mdi-account', variable: 'nome' }, { icon: 'mdi-email', variable: 'email' }, { icon: 'mdi-phone', variable: 'telefone' }, { icon: 'mdi-clipboard-account', variable: 'cpf' }, { icon: 'mdi-map-marker', variable: 'unidade' }, { icon: 'mdi-clipboard-alert', variable: 'observacao' }],
    newcollaborator: { telefone: null },
    valid: true,
    required: [v => !!v || 'Campo Obrigatório'],
    onlyLetters: [v => regex.justLetters.test(v) || 'Sem caracteres especiais e acentos'],
    twoWords: [v => regex.twoWords.test(v) || 'Nome e Sobrenome'],
    emailRules: [v => regex.emailFormat.test(v) || 'O email deve ser válido'],
    numberRules: [v => regex.numberFormat.test(v) || 'Digite apenas números'],
    nameRules: [v => !!v || 'Campo Obrigatório']
  }),

  methods: {
    validate () {
      this.$refs.form.validate()
    },
    async ConfirmFunction () {
      if (this.$store.state.clientPortal.dialogDetail.name === 'update') {
        this.$store.commit('dialogseditclient', [{ update: true }])
        this.$store.commit('dialogDetail', {})
        return true
      }
      const config = this.$store.state.clientPortal.config
      config.headers.id = this.$store.state.clientPortal.general.id
      if (this.$store.state.clientPortal.dialogDetail.name === 'delete') {
        this.url = this.$store.state.clientPortal.login.idCompany + '/delete' + this.$store.state.clientPortal.dialogDetail.range + '/' + this.$store.state.clientPortal.opendetail.id
        if (this.$store.state.clientPortal.dialogDetail.range === 'Promoter') {
          this.url = `v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Promoter/${this.$store.state.clientPortal.general.idAccess}`
        }
        if (this.$store.state.clientPortal.dialogDetail.range === 'Collaborator') {
          this.url = `v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators/${this.$store.state.clientPortal.general.id}`
        }
        if (this.$store.state.clientPortal.dialogDetail.range === 'MemberUser') {
          this.url = `v0/company/${this.$store.state.clientPortal.login.idCompany}/member/${this.$store.state.clientPortal.general.id}`
        }
        if (this.$store.state.clientPortal.dialogDetail.range === 'Delivery') {
          this.url = `v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}`
        }
        config.headers.token = this.$store.state.clientPortal.general.token
        this.$store.commit('dialogDetail', { type: 'Wait', extra: this.$store.state.clientPortal.dialogDetail.range === 'Delivery' })
        await this.$functions.deleteCall('/' + this.url, config)
          .then(() => {
            const obj = { type: 'Success', title: 'Cadastro excluído', text: 'O cadastro foi excluído com sucesso' }
            if (this.$store.state.clientPortal.dialogDetail.extra) { obj.extra = 'closeDetail' }
            this.$store.commit('dialogDetail', obj)
          })
          .catch(() => {
            this.$store.commit('dialogDetail', { type: 'Success', title: 'Algo deu errado!', text: 'Houve um erro na exclusão do cadastro, caso isso persista entre em contato com o suporte' })
          })
      } else if (this.$store.state.clientPortal.dialogDetail.name === 'email') {
        if (this.$store.state.clientPortal.dialogDetail.range === 'Collaborator') {
          this.url = `v0/Company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/collaborators/${this.$store.state.clientPortal.general.id}/qrcode/`
        } else if (this.$store.state.clientPortal.dialogDetail.range === 'Promoter') {
          this.url = 'v0/company/' + this.$store.state.clientPortal.login.idCompany + '/accesses/' + this.$store.state.clientPortal.general.idAccess + '/newPassword'
        } else {
          this.url = 'v0/company/' + this.$store.state.clientPortal.login.idCompany + '/accesses/' + this.$store.state.clientPortal.general.id + '/newPassword'
        }
        this.$store.commit('dialogDetail', { type: 'Wait' })
        await this.$functions.get('/' + this.url, config)
          .then(() => {
            this.$store.commit('dialogDetail', { type: 'Success', title: 'Cadastro Enviado', text: 'O cadastro foi enviado com sucesso' })
          })
          .catch(() => {
            this.$store.commit('dialogDetail', { type: 'Success', title: 'Algo deu errado!', text: 'Houve um erro no envio do cadastro, caso isso persista entre em contato com o suporte' })
          })
      }
    },
    async FormFunction () {
      if (this.$store.state.clientPortal.dialogDetail.name === 'edit') {
        this.Save('patch', 'update', 'editado')
      } else if (this.$store.state.clientPortal.dialogDetail.name === 'add') {
        this.Save('post', 'register', 'adicionado')
      }
    },
    async Save (call, compl, type) {
      const config = this.$store.state.clientPortal.config

      this.body = this.$store.state.clientPortal.general
      if (this.$store.state.clientPortal.dialogDetail.range === 'MemberUser') {
        this.body.permissao = this.choice === 'Pode editar'
        config.headers.id = this.$store.state.clientPortal.general.id
        if (compl === 'register') {
          this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/member`
        } else if (compl === 'update') {
          this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/member/${this.body.id}`
        } else {
          this.url = '/' + this.$store.state.clientPortal.login.idCompany + '/' + compl + 'MemberUser'
        }
      } else if (this.$store.state.clientPortal.dialogDetail.range === 'Collaborator' && compl === 'register') {
        this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators`
      } else if (this.$store.state.clientPortal.dialogDetail.range === 'Collaborator' && compl === 'update') {
        this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators/${this.body.id}`
      } else if (this.$store.state.clientPortal.dialogDetail.range === 'Promoter' && compl === 'register') {
        this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Promoter`
      } else if (this.$store.state.clientPortal.dialogDetail.range === 'Promoter' && compl === 'update') {
        this.url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Promoter/${this.$store.state.clientPortal.general.idAccess}`
      } else {
        this.url = '/' + this.$store.state.clientPortal.login.idCompany + '/' + compl + this.$store.state.clientPortal.dialogDetail.range + '/' + this.$store.state.clientPortal.opendetail.id
      }
      this.$store.commit('dialogDetail', { type: 'Wait' })
      await this.$functions[call](this.url, this.body, config)
        .then((r) => {
          this.$store.commit('dialogDetail', { type: 'Success', title: 'Cadastro ' + type, text: 'O cadastro foi ' + type + ' com sucesso' })
        })
        .catch(() => {
          this.$store.commit('dialogDetail', { type: 'Success', title: 'Algo deu errado!', text: 'Houve um erro no ' + type + ' do cadastro, caso isso persista entre em contato com o suporte' })
        })
    },
    async openEdit () {
      const range = this.$store.state.clientPortal.page ? 'Collaborator' : 'Promoter'
      this.$store.commit('dialogDetail', { name: 'edit', range, type: 'Form', title: 'Editar colaborador', text: 'Para editar o colaborador preencha o formulário abaixo e depois clique em salvar.' })
    },
    telephoneCheck () {
      const x = this.$store.state.clientPortal.general.telefone.replace(/[^0-9.]/g, '').match(/(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
      this.$store.state.clientPortal.general.telefone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + ' ' + x[3] + (x[4] ? '-' + x[4] : '')
    },

    async RefreshDetails () {
      this.$store.commit('RefreshClientPortalData')
      let holder = {}
      if (this.$store.state.clientPortal.dialogDetail.extra === 'closeDetail') {
        this.$store.commit('clearclientdetail')
        this.$store.commit('editclientdetails', { isOpen: false, data: [] })
      }
      if (this.$store.state.clientPortal.opendetail.id) {
        holder.collaborators = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators`, this.$store.state.clientPortal.config)
        holder.promoters = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/promoter/`, this.$store.state.clientPortal.config)
        holder = { id: this.$store.state.clientPortal.opendetail.id, collaborators: holder.collaborators.data, promoters: holder.promoters.data }
        this.$store.commit('clientdetail', holder)
      } else {
        await this.$store.commit('refreshAccess')
      }
    },

    async close (NeedRefresh) {
      if (NeedRefresh) { this.RefreshDetails() }
      this.$store.commit('generalchangesclient', {})
      this.$store.commit('dialogDetail', {})
      this.body = this.url = ''
    }

  }
}
</script>

<style scoped>
  .detailstxt{
    margin-bottom: 0px;
    margin-left: 32px;
font-size: 16px;
line-height: 150%;
display: flex;
align-items: center;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
  }
  .rows{
    height: 48px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 16px;
  }

  .container{
  max-width: 501px;
  margin-top: 0px !important;
  }
  .margin{
    margin-left: 12px;
    margin-right: 0px;
    margin-top: 16px;
    margin-bottom: 0px;
  }
</style>
