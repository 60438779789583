import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import store from './store.js'
import axios from 'axios'
import functions from './general/functions.js'
import msal from 'vue-msal'
import { Chart } from 'chart.js'
import Chartkick from 'vue-chartkick'
import config from './config'
import bases from './general/bases'

// Configurando as variáveis globais abaixo

// o "$config" possue as variáveis de ambiente
Vue.prototype.$config = config

// o "$functions" armazena funções padrões mt ultilizadas
Vue.prototype.$functions = functions

// o "$http" armazena o axios, o método que usamos para fetch requests
Vue.prototype.$http = axios

// o "$bases" armazena objetos de base para alguns usos, como para os diálogos
Vue.prototype.$bases = bases

Vue.use(Chartkick.use(Chart))
Vue.use(VueResource, axios)
Vue.config.productionTip = false
Vue.use(msal, {
  auth: {
    clientId: config.auth.clientId,
    authority: config.auth.authority
  },
  framework: {
    globalMixin: true
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      profile: '/me',
      photo: { url: '/me/photo/$value', force: true, responseType: 'blob' }
    }
  }
}
)

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
