/* eslint-disable no-extra-boolean-cast */
<template>
  <v-card class='branco a pl-6' height="371">
    <DownloadIcon v-bind:data="data.download" v-if="isClient"/>

    <v-card-title class='title ml-n4'>
      Quantidade de entregas
    </v-card-title>
    <v-card class='nomargin branco' width="407" height="66" elevation="0">
    <v-row class='nomargin'>
      <v-tabs v-model="tab" :color="red" class='numDelTabs' background-color="branco">
          <v-tab class='numDelTab2 capitalize'>
            Semana
          </v-tab>
          <v-tab  class='numDelTab1 capitalize'>
            Mês
          </v-tab>
          <v-tab  class='numDelTab1 capitalize'>
            Dia
          </v-tab>
      </v-tabs>
      <v-select :menu-props="{offsetY: true}" :items="selectItem" v-model="selected" outlined class='numDelSelect nomargin mt-2' dense>
      </v-select>
            </v-row>
    </v-card>

    <v-card  height="216" class='mt-2 branco numDel' elevation="0">
      <column-chart :discrete="true" :legend="false" :colors="[redrgba]" height="216px" :data="data.chart[selected][tabed]" color/>
    </v-card>

  </v-card>
</template>

<script>
import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  props: ['data', 'isClient'],
  data: () => ({
    selected: 'Todas',
    tab: 0,
    settings: {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    },
    eko: '150px',
    blue: '#134B97',
    blue1rgba: 'rgba(5,94,169,1)',
    bluergba: 'rgba(19,75,151,1)',
    lightbluerba: 'rgba(198,206,226,1)',
    grey1rgba: 'rgba(189,189,189,1)',
    red: '#d2232a',
    redrgba: 'rgba(210,35,42,1)',
    tamanho: '600px'
  }),
  methods: {
  },
  computed: {
    selectItem () {
      const array = []
      for (const [key] of Object.entries(this.data.chart)) {
        array.push(key)
      }
      return array
    },
    tabed () {
      if (this.tab === 0) {
        return 'Semana'
      }
      if (this.tab === 1) {
        return 'Mes'
      }
      return 'Dia'
    }
  }
}
</script>

<style scoped>
.a{
  min-width: 610px;
}
.numDel{
  width: 539px;
}
@media screen and (max-width: 1359px) {
  .a{
  min-width: 510px;
}
.numDel{
  width: 439px !important;
}
}
@media screen and (max-width: 1100px) {
  .a{
  min-width: 475px;
}
.numDel{
  width: 400px !important;
}
}
.numDelTab1{
  width: 54px;
}
.numDelTab2{
  width: 79px
}
.numDelTabs{
  width: calc(54px + 54px + 79px);
}
.numDelSelect{
  width: 200px;
  height: 40px !important
}

.title{
font-family: 'Co Headline Corp' !important;
font-size: 18px;
line-height: 22px;
color: #000000;
}

</style>
