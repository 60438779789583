<template>
  <div>
    <div>
      <v-slide-y-transition>
          <div v-show="error" class='alert'>
            <v-row>
              <v-icon color="#F44336" class='nomargin my-2 ml-3'>
                mdi-close-circle-outline
              </v-icon>
              <div class='errou'>
                <p class='alertxt mr-3'> A credencial inserida está incorreta. <br> Por favor confira e tente novamente. </p>
              </div>
            </v-row>
          </div>
          </v-slide-y-transition>
          <div class='txtfield'>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-text-field :error='error' dense color="laranja" :rules='emailRules' outlined label="Email" height="40" v-model="emailform"> </v-text-field>
              <v-text-field :error='error' type="password" dense color="laranja" :rules='nameRules' outlined label="Senha" height="40" v-model="passwordform"> </v-text-field>
            </v-form>
          </div>
          <v-btn color="azul" :disabled='!valid' class="rounded-xl butao" @mouseover='validate()' @click="login()"> <p class='txtwhite capitalize my-1 px-0'> Login </p></v-btn>
      </div>
      <div class='mb-0'>
        <p class='linktxt mt-8'>
        Se você é colaborador,<a @click='setcompanydetails()' class='linkNormalization'> clique aqui</a>   para gerar o QR Code para retirada do seu kit. <br>
        Se você é administrador BRF,<router-link to="/brf" class='linkNormalization'> clique aqui </router-link> para acessar seu portal.
        </p>
    </div>
  </div>
</template>

<script>

export default {

  data: () => ({
    error: false,
    passwordform: '',
    emailform: '',
    valid: true,
    imagemQRCode: '',
    pass1: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    pass2: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ],
    emailRules: [
      v => !!v || 'Campo Obrigatório',
      v => /.+@.+\..+/.test(v) || 'Preencha com um email válido'
    ]
  }),
  methods: {
    login (event) {
      this.$emit('startLogin', { email: this.emailform, password: this.passwordform })
    },
    async setcompanydetails () {
      this.$emit('openCompany', true)
    },
    validate () {
      this.$refs.form.validate()
    }
  },
  async mounted () {
    const self = this

    window.addEventListener('keyup', async function (event) {
      if (event.keyCode === 13) {
        await self.validate()
      }
    })
  }
}
</script>

<style scoped>
.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 354px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.errou{
  width: 290px;
}
 .linkNormalization{
   color: #134B97 !important;
      text-decoration: none;
   }

.txtfield{
    margin-top: 32px;
    width: 252px;
    height: 104px;
    margin-bottom: 48px;
}
.butao{

}

.colabTitle{
    font-size: 30px;
line-height: 123.5%;
letter-spacing: 0.25px;
color: #D2232A;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.colabtxt{
    font-size: 12px;
line-height: 156%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.sub{
font-size: 20px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: rgba(0, 0, 0, 0.54);

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.linktxt{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.54);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
</style>
