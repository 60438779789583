<template>
  <div>
    <v-data-table
      @update:items-per-page='updatepage()'
      @update:page='updatepage()'
      class='size2off'
      mobile-breakpoint="10"
      calculate-widths:true
      :headers='headers'
      :items='this.data'
      :hidden='this.List.length < 1'
      :loading='!$store.state.adminPortal.ClientData.refreshed'
      :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[6,12,18]}"
    >

      <!-- Essas templates possuem cada uma das colunas da tabela -->
      <template v-slot:item.name="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' class='tabs'>
          <p class='empresa capitalize my-2' style="text-align: left !important;"> {{ item.name }} </p> <v-spacer/>
        </v-tab>
      </template>

      <template  v-slot:item.responsavel="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' class=tabs>
          <p class='capitalize my-2 vendedor'> {{ item.nameUser }} </p> <v-spacer/>
        </v-tab>
      </template>

      <template v-slot:item.sellerdetails="{ item }">
        <v-tab :ripple="false" @click='opendialog (item, "detail")' class='tabs'>
          <div class='my-auto divs'>
            <p class='vendedor capitalize my-2'> {{ item.seller }} </p>
            <p class='start regional capitalize mb-2'> {{ item.regional }} </p>
          </div>
        </v-tab>
      </template>

      <template v-slot:item.accessType="{ item }">
          <p class="pill" :style="`color: ${accessTypeTEMP[item.accessType].textColor}; background-color: ${accessTypeTEMP[item.accessType].bdColor};`"> {{item.accessTypeName}} </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tab :ripple="false" class='tabs' v-if="$store.state.adminPortal.ClientData.refreshed == true" >
          <v-spacer/>

          <!-- <v-icon class='mx-0' @click='opendialog (item, "edit")' color='azul'>
            mdi-pencil
          </v-icon> -->
          <!-- <v-spacer/> -->

          <v-icon @click='opendialog (item, "delete")' class='mx-0' color='azul'>
            mdi-delete
          </v-icon>
          <v-spacer/>

        </v-tab>
      </template>

    </v-data-table>

    <!-- O aviso -->
    <div :hidden='this.List.length >= 1' class='centertxt'>
      <div class='spacervertical' />
      <h1 class='CoHead centertxt'> Nenhum resultado </h1>
      <p> O filtro aplicado não retornou nenhum resultado, tente alterá-lo </p>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    true: true,
    accessTypeTEMP: [{ name: 'Admin', bdColor: '#F6BEC1', textColor: '#d2232a' }, { name: 'Cliente', bdColor: '#ffe28a', textColor: '#ff860e' }, { name: 'Promotor', bdColor: '#e6ecf4', textColor: '#00428a' }, { name: 'Promotor Mestre', bdColor: '#e6ecf4', textColor: '#00428a' }],
    render: true,
    headers: [
      { text: 'RESPONSÁVEL', sortable: false, align: 'center', value: 'name', width: '25%' },
      { text: 'E-MAIL', sortable: false, align: 'center', value: 'email', width: '25%' },
      { text: 'CRIADOR', align: 'center', sortable: false, value: 'createdBy', width: '25%' },
      { text: 'ACESSOS', align: 'center', sortable: false, value: 'accessType', width: '10%' },
      { text: 'AÇÕES', align: 'center', sortable: false, value: 'actions', width: '15%' }
    ]
  }),

  computed: {
    List () { return this.$store.getters.Filteredclients },
    config () { return { headers: { Access_Token: this.msal.idToken } } }
  },
  methods: {
    opendialog (item, dialog) {
      const obj = {}
      obj[dialog] = { show: true, info: item }
      this.$store.commit('editAccessDialog', obj)
    },

    // Para avisar a página quando temos que puxar ela pra cima
    updatepage () {
      this.$store.commit('adminOptions', { temp: { update: true } })
    },

    // Um refresh da lista, com um pequeno delay para evitar alguns bugs
    async refreshlist () {
      await this.$functions.sleep(1000)
      this.$store.commit('RefreshAdminPortalData', this.msal.idToken)
    },

    // A função conectada nos switchs, como essa parte é problemática fizemos diversos workarounds
    async situationChanger (item) {
      if (this.$store.state.adminPortal.options.filter.situacao != null) {
        this.render = false
        await this.$store.commit('adminOptions', { temp: { deleted: true } })
        this.render = true
        this.updatepage()
      }
      const holder = item
      holder.situation = holder.situation ? 1 : 0
      await this.$functions.patch('/Companies/' + item.id + '/situation', holder, this.config)
    }
  },

  props: {
    data: Array
  }
}
</script>

<style scoped>

  /* Classes para posicionamento / tamanho */
  .divs {
    width: 100%;
  }

  .tabs {
    padding-right: 10%;
    height: 64px;
    width: 100%;
  }

  .spacervertical{
    margin: 1px;
    min-height: calc(50vh - 200px);
  }

  .start{
    text-align: start !important;
  }

  /* Classes para formatação de texto */
  .empresa{
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #D2232A;
    }

  .vendedor{
    margin: 0px;
    font-size: 12px;
    line-height: 166%;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
  }

  .regional{
    margin: 0px;
    padding: 0px;
    font-size: 12px !important;
    line-height: 166% !important;
    letter-spacing: 0.4px !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .pill{
    font-size: 12px;
    width: 106px;
    height: 22px;
    background-color: #d0d0d0;
    border-radius: 30px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 2px;
  }
</style>
