const generic = {
  success: {
    email: { type: 'success', title: 'E-mail enviado', text: 'Os dados do login foram enviados para a caixa de entrada com sucesso' },
    delete: { type: 'success', title: 'Cadastro excluído', text: 'O cadastro foi excluído do sistema com sucesso' },
    create: { type: 'success', title: 'Cadastro criado', text: 'O cadastro foi criado com sucesso' },
    edit: { type: 'success', title: 'Cadastro editado', text: 'O cadastro foi alterado com sucesso' }
  }
}

const adminPortal = {
  dialogs: {
    create: { type: 'form', name: 'create', title: 'Cadastrar', text: 'Para a adição de um novo cadastro de empresa preencha os campos e clique em salvar' },
    edit: { type: 'form', name: 'edit', title: 'Edição de cadastro', text: 'Para editar o cadastro da empresa preencha os campos e clique em salvar' },
    delete: { type: 'confirm', name: 'email', title: 'Gostaria de enviar os dados do login novamente para esse contato?' },
    sendEmail: { type: 'confirm', name: 'delete', title: 'Tem Certeza?', text: 'Tem certeza que deseja excluir? Essa ação não poderá ser desfeita' },
    detail: { type: 'detail' }
  }
}

const ClientPortal = {
  dialogs: {

  }
}

export default ({ adminPortal, ClientPortal, generic })
