<template>
<div>
    <v-dialog v-model='war' max-width='610px'>
      <v-card color="cinza">
        <v-card-title class='CoHead mx-16 text-center px-8 pt-16'>Ops, parece que ainda não foi feita a <br> configuração para o acesso aos QR Codes</v-card-title>
        <v-card-text><p class='text-center bay'> Essa etapa de personalização é necessária antes de se fazer o envio dos <br> QR Codes. Para configurar, acesse o botão na barra de navegação <br> lateral ou pelo botão abaixo.</p></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='roxo' rounded @click='changepagination()' class='capitalize mb-12'> <p class='txtwhite my-auto capitalize'> <v-icon class='mr-2'> mdi-brush </v-icon> Configuração</p></v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='sending' max-width='580px'>
      <v-card>
        <v-card-title class='CoHead mb-3'>Enviar QR Codes</v-card-title>
        <v-card-text><p> Enviar acessos aos QR Codes para os colaboradores utilizarem na retirada dos kits.</p></v-card-text>
        <v-card-text>
        <v-row class='ma-0 pa-0 center'>
          <v-spacer></v-spacer>
          <v-col class='ma-0 pa-0'>
          <v-btn color='azul' rounded @click='copy()' class='capitalize py-6'> <v-icon color="branco"> mdi-file-multiple </v-icon> <p class='txtwhite my-auto capitalize ml-2'>Copiar link de acesso aos QR Codes</p></v-btn> <br>
           <a class='pt-4' :href="configInput + '/colaborador/' + this.$store.state.clientPortal.login.company">  {{configInput + '/colaborador/' + this.$store.state.clientPortal.login.company }} </a>

            <p class='center mt-4'> Ou </p>
           <v-btn color='azul' outlined rounded @click='send()'  class='capitalize py-6 mb-7'> <v-icon color="azul"> mdi-email </v-icon><p class='my-auto capitalize ml-2'>Enviar QR Codes por e-mail</p></v-btn>

          </v-col>
          <v-spacer/>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar color="branco" elevation="0" height='72px'>
      <div :hidden="!detailtab || $store.state.clientPortal.pagination !== 0">
      <v-icon @click='closedetail'> mdi-chevron-left </v-icon>
    </div>
      <!-- o texto -->
      <div class='my-auto'>
        <p class='headertext my-auto'> {{changes.Title}} </p>
      </div>

      <v-spacer/>
      <div>
      <v-btn
       v-if="!detailtab && $store.state.clientPortal.pagination === 0"
       :disabled="!$store.state.clientPortal.login.permissao"
      color="vermelho"
      class="ma-2 capitalize"
      outlined
      href="Planilhas.zip"
      download
      rounded>
      <v-icon class=''> mdi-format-vertical-align-bottom </v-icon>
      <label class="ml-2 size1off"> Baixar modelo de planilha </label>
      </v-btn>
      <v-btn
      v-if="changes.isButton && (!detailtab || $store.state.clientPortal.pagination !== 0)"
      :disabled="!$store.state.clientPortal.login.permissao"
        color='roxo'
        dark
        class='my-auto capitalize'
        rounded
        @click='chooser'
        right>
         <v-icon class=''> {{changes.Icon}} </v-icon>
        <label class="ml-2 size2off"> {{changes.Button}} </label>
      </v-btn>
      </div>
      <div v-if="detailtab && $store.state.clientPortal.pagination === 0">
      <v-btn
      :disabled="!$store.state.clientPortal.login.permissao"
      color="vermelho"
      class="ma-2 capitalize"
      @click='open()'
      rounded>
      <v-icon color="branco" class='my-auto mr-2'> mdi-send </v-icon>
      <p class='capitalize my-auto txtwhite'>
       Enviar qr Code
      </p>
      </v-btn>
      </div>
    </v-app-bar>
</div>
</template>

<script>

export default {
  data: () => ({
    sending: false,
    details: '',
    war: false
  }),
  computed: {
    configInput () {
      return window.location.origin
    },
    apptxt () {
      // eslint-disable-next-line no-extra-boolean-cast
      if (this.$store.state.clientPortal.clientdetails.isOpen) { return this.$store.state.clientPortal.lists[0].identidade } else { return 'Entregas' }
    },
    detailtab () {
      return this.$store.state.clientPortal.opendetail.id
    },
    changes () {
      const list = [
        { Title: 'Entregas', isButton: true, Button: 'Cadastrar Entrega', Icon: 'mdi-plus' },
        { Title: 'Dashboards', isButton: false },
        { Title: 'Configuração do acesso dos colaboradores ao QR Code', isButton: false },
        { Title: 'Sobre', isButton: false },
        { Title: 'Acesso', isButton: true, Button: 'Compartilhar acessos', Icon: 'mdi-account-plus' }]
      const holder = list[this.$store.state.clientPortal.pagination]
      return holder
    }
  },
  methods: {
    changepagination () {
      this.$store.commit('changepagination', 2)
      this.closef()
    },
    copy () {
      navigator.clipboard.writeText(this.configInput + '/colaborador/' + this.$store.state.clientPortal.login.company)
      this.closef()
    },
    send () {
      this.closef()
      this.$store.commit('dialogDetail', { type: 'Wait' })
      this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/qrcodes/gen`, this.$store.state.clientPortal.config)
        .then(() => {
          this.$store.commit('dialogDetail', { type: 'Success', title: 'QR Codes enviados', text: 'Os QR Codes foram enviados com sucesso.' })
        })
    },
    open () {
      if (!this.$store.state.clientPortal.login.personalization) {
        this.war = true
      } else {
        this.sending = true
      }
    },
    closedetail () {
      this.$store.commit('clearclientdetail')
      this.$store.commit('editclientdetails', { isOpen: false, data: [] })
    },
    chooser () {
      if (this.$store.state.clientPortal.pagination === 0) {
        this.$store.commit('dialogseditclient', [{ create: true }])
      } else if (this.$store.state.clientPortal.pagination === 4) {
        this.$store.commit('generalchangesclient', {})
        this.$store.commit('dialogDetail', { name: 'add', range: 'MemberUser', type: 'Form', title: 'Compartilhar acesso', text: 'Para que outras pessoas possam acessar a plataforma, compartilhe o acesso fazendo o cadastro abaixo.' })
      }
    },
    closef () {
      this.war = false
      this.sending = false
    }
  }
}
</script>

<style scoped>
.icone{
  font-size: 40px !important
}
 .headertext{
    font-family: 'Co Headline Corp';
    font-size: 20px;
    line-height: 100%;
    color: #134b97;
  }
  .center{
    text-align: center;
  }
  .bay{
    font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

text-align: center;
letter-spacing: 0.4px;

/* palette / text / primary */

color: rgba(0, 0, 0, 0.87);

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
  }
</style>
