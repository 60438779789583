<template>
    <v-slide-y-transition>
        <div v-show="show" class='alert2'>
            <v-row style="display: flex">
                <v-icon color="#F44336" class='nomargin my-2 ml-3'>
                mdi-close-circle-outline
                </v-icon>
                <div class='errou'>
                <p class='alert2xt mr-3'> {{ text }} </p>
                </div>
            </v-row>
        </div>
    </v-slide-y-transition>
</template>

<script>
export default {

  props: {
    show: Boolean,
    text: String
  },
  data: () => ({
    erro: { show: true, text: 'A credencial inserida está incorreta' },
    loginInfo: { array: '', options: '', dialog: '' },
    choice: 0,
    stermo: false,
    termo: false,
    passSucess: false,
    valid2: true,
    newPassword: { first: '', second: '', error: false, errorMessage: '' },
    changingTable: false,
    colabSelected: '',
    colabArray: [],
    colabBtn: true,
    colabItems: [],
    error: false,
    passwordform: '',
    emailform: '',
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false
    },
    colabDialog: false,
    noauth: true,
    valid: true,
    imagemQRCode: '',
    buya: true,
    pass1: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    pass2: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ],
    emailRules: [
      v => !!v || 'Campo Obrigatório',
      v => /.+@.+\..+/.test(v) || 'Preencha com um email válido'
    ]
  }),
  methods: {
    term () {
      this.stermo = !this.stermo
    },
    go () {
      this.$router.push('/clientes')
    },
    async colabChanges () {
      this.colabBtn = false
    },
    async setcompanydetails () {
      this.colabDialog = true
      const array = []
      const obj = await this.$functions.get('/v0/companies/CustomPages')
      obj.data.forEach(element => {
        array.push(element.nome)
      })
      this.colabArray = obj.data
      this.colabItems = array
    },
    validate () {
      this.$refs.form.validate()
    },
    async validate2 () {
      this.newPassword.error = false
      await this.$refs.form2.validate()
    },
    async goToColab () {
      this.$router.push('/colaborador/' + this.colabSelected)
    },
    async finishLogin (choice) {
      this.loginInfo.array.forEach(async element => {
        if (element.info.name === choice) {
          await this.$store.commit('editIsAuth', true)
          await this.$store.commit('updatelogin', { name: element.info.name, company: element.info.nameCompany, senhaTemporaria: element.info.isPasswordTemporary, permissao: element.info.canEdit, token: element.token, idCompany: element.info.idCompany, personalization: element.info.personalization })
          if (element.info.isPasswordTemporary) {
            this.loginInfo.dialog = false
            this.changingTable = true
          } else {
            this.go()
          }
        }
      })
    },
    async Login (n) {
      let holder
      this.buya = !this.buya
      await this.$http.post(this.$config.url_backend + '/v0/Login', {}, { headers: { email: n.email, password: n.password } })
        .then(res => { holder = res })
        .catch(err => {
          holder = err
        })

      if (holder.status === 200) {
        if (holder.body.accesses.length === 1) {
          // if length === 1 finish login
        } else {
          // get infos when needed
          // sendit to login options
          // open it
        }
        // else get info login options
      } else {
        // show error
        // go back error
        // clear the child
      }
    }
  },
  async mounted () {
    const self = this

    window.addEventListener('keyup', async function (event) {
      if (event.keyCode === 13) {
        await self.validate()
        if (self.valid) {
          self.Login()
        }
      }
    })
  }
}
</script>

<style scoped>
.alert2xt{
    padding-top: 16px;
    padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 166%;
  color: #F44336;
  letter-spacing: 0.4px;
  width: 400px;
  }
  .alert2{
    background-color: #FEECEB;
    border: 2px solid #F44336;
    border-radius: 12px;
    left: 32px;
    top: 0px;
    z-index: 99;
    padding-top: 13px;
    padding-left: 18px;
    padding-bottom: 13px;
    width: 474px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  </style>
