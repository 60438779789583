<template>
    <div class='fullwidth main'>
        <!-- Primeira linha de elementos -->
        <v-row class='firstLine'>
            <!-- Wireframe do logo da brf -->
            <v-img
                v-show="wireFrameLogoIntern"
                max-height='32'
                max-width='68'
                contain
                src='@/assets/img/BrfFrame.png'/>
            <v-img
                v-show="!wireFrameLogoIntern"
                max-height='32'
                max-width='68'
                contain
                src='@/assets/img/Brf.png'/>
            <v-spacer/>
            <!-- Div com os botões para futuros routers -->
            <div class='size2off' v-show="changePages">
                <v-btn v-for="(item, index) in $store.state.generic.pages"  :key="item" color="#EFEFEF" class="mr-3" @click="changepagination(index)" outlined rounded>
                    <p class="my-auto txtblack capitalize" style="font-size: 12px;"> {{ item.name }} </p>
                </v-btn>
            </div>

            <div class='conbox' v-show="!changePages">
                    <p class='contxt mr-4'> CONTATO </p>
                    <p class='contxt2 mt-1'> <a :href='"mailto:"+email || "suportebrfcode@brf.com"'> {{email || 'suportebrfcode@brf.com'}} </a> </p>
            </div>
        </v-row>
        <!-- A divisão das duas Linhas -->
        <v-divider class='divider'/>
        <!-- Segunda linha de elemtnos -->
        <v-row class='secondLine'>
            <p class='bottomText nomargin'>BRF © 2021. Todos os Direitos Reservados.</p>
            <v-spacer/>
            <a class="suport" href='mailto:suportebrfcode@brf.com'> Suporte </a>
        </v-row>
    </div>
</template>

<script>
export default {
  props: [
    'email',
    'changePages',
    'wireFrameLogo'
  ],
  computed: {
    changePagesIntern () {
      return this.changePages || []
    },
    wireFrameLogoIntern () {
      return !!this.wireFrameLogo
    }

  },
  created () {

    // this.wireFrameLogo = true
  },
  data: () => ({
  }),
  methods: {
    changepagination (item) {
      this.$store.commit('changeCurrentPage', item)
    }
  }
}
</script>

<style scoped>
.main{
    z-index: 22;
    background-color: #FAFAFA !important;
    height: 90px;
    padding: 12px 32px 12px 32px;
}
.conbox{
    margin-top: 4px;
        /* position: absolute;
        top: 12px;
        right: 32px;
        width: 570px;
        height: 52px; */
    display: flex;
    }
    .firstLine {
        margin: 0px !important;
        padding: 0px !important;
        height: 32px;
        max-height: 32px;
    }

    .secondLine {
        margin: 0px !important;
        padding-top: 16px;
        min-height: calc(32px) !important;
        padding-bottom: 16px;
    }

    /* Classes para cor / detalhes */
    .divider{
        margin-top: 8px;
        color: #EFEFEF;
    }

    /* Classes para customização de texto */
    .contxt{
        font-family: Noto Sans;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 266%;
text-align: right;
letter-spacing: 1px;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 0px;
    }
    .contxt2 {
        text-align: right;
        margin-right: 0px;
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 0px;
    }
    .suport {
        font-size: 12px;
        text-decoration: none !important;
        text-align: right;
    }
    /* Classes para tamanho / posição */
    .firstLine {
        margin: 0px !important;
        padding-top: 16px;
        height: calc(32px);
        padding-bottom: 16px;
    }

    .secondLine {
        margin: 0px !important;
        padding-top: 8px;
        height: 16px;
        padding-bottom: 16px;
    }

    /* Classes para cor / detalhes */
    .divider{
        color: #EFEFEF;
    }

    /* Classes para customização de texto */
    .bottomText{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.15px;
    }

    .linkcolor{
        color: #1976D2;
    }
@media screen and (max-width: 370px) {
  p,a{
    font-size: 10px !important;
  }

}
</style>
