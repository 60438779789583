<template>
  <v-card>
    <v-card-title class='CoHead'>
      Detalhamento das listas de entrega
    </v-card-title>
    <v-data-table
      mobile-breakpoint="10"
        class='tabe'
      calculate-widths:true
        :headers='headers'
        :items='data'
        sort-by='name'
        :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[4,8,12]}"
    >
      <template v-slot:item.colaboradores="{ item }">
          <v-card elevation="0" width="47" height="32" rounded="pill" class='text-center first'> <p class='txtred pt-1'> {{ item.colaboradores }} </p> </v-card>
      </template>
      <template v-slot:item.Gerados="{ item }">
          <v-card elevation="0" width="47" height="32" rounded="pill" class='text-center second'> <p class='txtblue pt-1'> {{ item.Gerados }} </p> </v-card>
      </template>
      <template v-slot:item.Lidos="{ item }">
          <v-card elevation="0" width="47" height="32" rounded="pill" color="vermelho" class='text-center'> <p class='txtwhite pt-1'> {{ item.Lidos }} </p> </v-card>
      </template>
      <template v-slot:no-data>

      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ['data'],
  data: () => ({
    headers: [
      { text: 'Lista de entrega', sortable: false, value: 'name', width: '25%', align: 'start' },
      { text: 'Colaboradores', sortable: false, value: 'colaboradores', width: '25%', align: 'start' },
      { text: 'QR Codes Gerados', sortable: false, value: 'Gerados', width: '25%', align: 'start' },
      { text: 'QR Codes Lidos', sortable: false, value: 'Lidos', width: '25%', align: 'start' }
    ]
  })
}
</script>

<style scoped>
.tabe{
    width: 1060px;
}
@media screen and (max-width: 1359px) {
.tabe{
    width: calc(100vw - 32px - 262px);
}
}
.first{
    background-color: #D2232A25;
    }
.second{
    background-color: #134a9721;
}
@media screen and (max-width: 1100px) {
.tabe{
  width: 475px;
}
}
</style>
