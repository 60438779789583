<template>
    <div class='mb-0 ml-4 mt-4'>
      <v-row class='ml-0 pr-2' v-if="this.$store.state.clientPortal.opendetail.id">
        <div class=searchbar>
        <v-text-field
        class=''
              label="Pesquisar"
              outlined
              dense
               v-model="filter"
           @input="ordernar"
            ></v-text-field>
        </div>
        <v-col class='tab2 px-0 mr-5'>
          <v-select
                      :items="array2"
                              v-model="order"
                              :menu-props="{offsetY: true}"
           @input="ordernar"
            label="Ordenar por"
            class='mr-4'
          ></v-select>
        </v-col>

        <v-spacer/>
        <v-col class='px-0 mt-9 mr-2'>
          <v-row>
          <v-spacer/>
          <v-btn text class='mt-n2' @click="flipOptions" :disabled="!$store.state.clientPortal.login.permissao">
            <p class='my-auto capitalize'> Mais opções </p> <v-icon class='my-auto mr-1'> {{isOptionOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
          </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-slide-y-transition>
        <div v-show="isOptionOpen && this.$store.state.clientPortal.opendetail.id && $store.state.clientPortal.login.permissao" class='option'>
          <v-divider class='my-6'/>
          <v-row class='ml-3'>
            <v-btn rounded outlined color="azul" class='mr-2 capitalize mb-2' @click='addone()'>
              <v-icon color="azul" class='nomargin my-1'> mdi-plus </v-icon> <p class='optiontxt size2off'> <label class='size5off mr-1'> Adicionar </label>  novo cadastro </p>
            </v-btn>
            <v-btn rounded outlined color="azul" class='mr-2 capitalize' @click='download()'>
              <v-icon color="azul" class='nomargin my-1'> mdi-tray-arrow-down </v-icon> <p class='size2off optiontxt'> <label class='size5off mr-1'> Fazer </label> download xls. </p>
            </v-btn>
            <v-btn rounded outlined color="azul" class='mr-2 capitalize' @click='uploadlist()'>
              <v-icon color="azul" class='nomargin my-1'> mdi-file-plus-outline </v-icon> <p class='optiontxt size2off'> <label class='size5off mr-1'> Fazer upload de  </label> Nova lista </p>
            </v-btn>
            <v-btn rounded outlined color="azul" class='mr-2  capitalize'  @click='changename()'>
              <v-icon color="azul" class='nomargin my-1'> mdi-pencil-outline </v-icon> <p class='size2off optiontxt'> Renomear <label class='size5off ml-1'> pasta </label> </p>
            </v-btn>
            <v-btn rounded outlined color="azul" class=' capitalize' @click='deleteList()' >
              <v-icon color="azul" class='nomargin my-1'> mdi-delete </v-icon> <p class='optiontxt size2off'> Excluir <label class='size5off mx-1'> toda a </label> pasta </p>
            </v-btn>
          </v-row>
        </div>
      </v-slide-y-transition>

    </div>
</template>

<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export default {
  computed: {
    array2 () {
      if (this.$store.state.clientPortal.page) {
        return ['E-mail', 'Nome Completo']
      } else {
        return ['Nome Completo', 'E-mail']
      }
    },
    orderobj () {
      if (this.$store.state.clientPortal.page) {
        return { Matrícula: 'matricula', 'E-mail': 'email', 'Nome Completo': 'nome' }
      } else {
        return { 'E-mail': 'email', 'Nome Completo': 'nome' }
      }
    }
  },
  data: () => ({
    filter: '',
    isOptionOpen: false,
    order: ''
  }),
  methods: {
    async download () {
      const holder = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators`, this.$store.state.clientPortal.config)
      const wb = XLSX.utils.book_new()
      wb.props = {
        Title: 'Lista de Entrega - QRCodeKit',
        Subject: 'BRF',
        Author: 'BRF'

      }
      wb.SheetNames.push('Promotores')
      wb.SheetNames.push('Colaboradores')
      wb.Sheets.Colaboradores = this.sheetcreate(holder.data)
      wb.Sheets.Promotores = this.sheetcreate(this.$store.state.clientPortal.opendetail.promoters)
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
      FileSaver.saveAs(new Blob([this.convert(wbout)], { type: 'application/octet-stream' }), 'QRCodeKit_Relatório.xlsx')
    },
    convert (s) {
      const buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
      const view = new Uint8Array(buf) // create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF // convert to octet
      return buf
    },
    sheetcreate (obj) {
      const wsData = obj
      const ws = XLSX.utils.json_to_sheet(wsData)
      return ws
    },
    addone () {
      const compl = this.$store.state.clientPortal.page ? 'colaborador' : 'promotor'
      const range = this.$store.state.clientPortal.page ? 'Collaborator' : 'Promoter'
      this.$store.commit('generalchangesclient', {})
      this.$store.commit('dialogDetail', { name: 'add', range, type: 'Form', title: 'Adicionar novo ' + compl, text: 'Para adicionar um novo ' + compl + ' à lista preencha o formulário abaixo e depois clique em salvar.' })
    },
    async ordernar () {
      let holder = 'name'
      for (const [key, value] of Object.entries(this.orderobj)) {
        if (key === this.order) { holder = value }
      }
      if (this.$store.state.clientPortal.page) {
        await this.$store.commit('changeorderclient', holder)
        await this.$store.commit('changefilterclient', this.filter)
      } else {
        await this.$store.commit('changeorderpromoter', holder)
        await this.$store.commit('changefilterpromoter', this.filter)
      }
    },
    flipOptions () {
      this.isOptionOpen = !this.isOptionOpen
      this.$store.commit('flipDetail')
    },
    changename () {
      this.$store.commit('dialogseditclient', [{ name: true }])
    },
    uploadlist () {
      this.$store.commit('dialogDetail', { type: 'Confirm', range: 'client', name: 'update', title: 'Tem Certeza?', text: 'Fazer o upload de uma nova lista de entrega apenas adicionara novos cadastros, possibilitando a duplicidade de de colaboradores.' })
    },
    async deleteList () {
      this.$store.commit('dialogDetail', { type: 'Confirm', range: 'Delivery', name: 'delete', title: 'Tem Certeza?', text: 'Tem certeza que deseja excluir toda a lista? Essa ação não poderá ser desfeita' })
    }
  }
}
</script>

<style scoped>
.searchbar{
  margin-top: 25px;
  margin-right: 16px;
  width: 14vw;
  height: 40px;
}
.filtertxt{
font-size: 16px;
line-height: 175%;
letter-spacing: 2px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.tab1{
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .tab2{
    min-width: 23% !important;
    max-width: 23% !important;
  }
  .tab3{
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .activetab3{
    background-color: #0000001f;
  }
  .option{
  width: calc(100% - 16px);
  margin-bottom: 32px;
  margin-top: -16px;
}
.optiontxt{
  font-family: Noto Sans;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;

/* palette / primary / main */

color: #134B97;

/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 10px 0px;
}
</style>
