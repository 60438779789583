// cheat sheet: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Cheatsheet
//

// Regex para um campo obrigatório
const required = /(^[\S])+/g

// Confirmação de apenas um tipo de caracteres
const justLetters = /^[a-zA-Z]*$/
const justNumbers = /^[0-9]*$/

// Para formatação seguindo padrões
const twoWords = /.+ +./
const emailFormat = /.+@.+\..+/
const numberFormat = /^\([0-9]{2}\) [0-9]{1} [0-9]{4}-[0-9]{4}$/
const cpfFormat = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/

export default { required, justLetters, justNumbers, twoWords, emailFormat, numberFormat, cpfFormat }
