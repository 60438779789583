import { render, staticRenderFns } from "./LoginOptions.vue?vue&type=template&id=bfd79ba6&scoped=true"
import script from "./LoginOptions.vue?vue&type=script&lang=js"
export * from "./LoginOptions.vue?vue&type=script&lang=js"
import style0 from "./LoginOptions.vue?vue&type=style&index=0&id=bfd79ba6&prod&scoped=true&lang=css"
import style1 from "./LoginOptions.vue?vue&type=style&index=1&id=bfd79ba6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd79ba6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VIcon,VSlideXTransition})
