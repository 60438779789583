<template>
  <v-row class='cinza nomargin'>
    <Navbar class='navbar'/>
    <div class='mainsize'>
      <Appbar/>
      <vue-custom-scrollbar id='scrollbar' class="scrollAreaclient nomargin"  :settings="settings">

          <div v-if="page===0" class='mainArea'>
            <Filterbar class='marginside'/>
            <ClientsTable :hidden='table === 1' class='marginside size2off'/>
            <ClientsCards :hidden='table === 0' class='marginside size2' />
          </div>

          <div v-if="page===1" class='mainArea'>
            <LocalController class="marginside"></LocalController>
          </div>

          <div v-if="page===2" class='mainArea'>
            <Dashboard />
          </div>

          <div v-if="page ===3" class='mainArea'>
            <About type="admin" />
          </div>

          <CustomFooter :change-pages="true" :wire-frame-logo="true"/>
        </vue-custom-scrollbar>
    </div>
  </v-row>
</template>

<script>
import LocalController from './TabAcess/LocalController.vue'
import Dashboard from '../Dashboard/CompleteBoard.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import Appbar from './Fixed/AppBar.vue'
import ClientsTable from './TabCompanies/ClientsTable.vue'
import ClientsCards from './TabCompanies/ClientsCards.vue'
import CustomFooter from '../shared/NewFooter.vue'
import Filterbar from './TabCompanies/FilterBar.vue'
import Navbar from './Fixed/NavBar.vue'
import About from '../sobre/AboutGroup.vue'

export default {
  async created () {
    const configPages = [
      { name: 'Empresas', link: '', appBarButton: true, icon: 'mdi-briefcase-variant', index: 0 },
      { name: 'Acesso Geral', link: '', appBarButton: true, icon: 'mdi-account', index: 1 },
      { name: 'Dashboard', link: '', appBarButton: false, icon: 'mdi-poll', index: 2 },
      { name: 'Sobre', link: '', appBarButton: false, icon: 'mdi-information-outline', index: 3 }
    ]
    this.$store.commit('setPages', configPages)
  },
  watch: {
    // Gatilho pra puxar a página
    update () {
      this.updated()
      this.$store.commit('adminOptions', { update: false })
    },

    // Gatilho para remover os filtros quando a pessoa troca de página
    page () {
      this.$store.commit('adminOptions', { filter: { regional: null, vendedor: null, situacao: null, type: null } })
    }
  },
  methods: {
    // Função para puxar a página pra cima
    updated: function () {
      setTimeout(() => {
        try {
          const elementScroll = document.getElementById('scrollbar')
          elementScroll.scrollTop = 0
        } catch (err) { }
      }, 0)
    }

  },
  computed: {
    update () {
      return (this.$store.state.adminPortal.options.temp.update === true)
    },
    table () {
      if (this.$store.state.adminPortal.options.tab === 0) { return 0 } else {
        return 1
      }
    },
    page () {
      return this.$store.state.generic.currentPage
    }
  },
  components: {
    LocalController,
    vueCustomScrollbar,
    About,
    Appbar,
    Filterbar,
    ClientsTable,
    ClientsCards,
    CustomFooter,
    Navbar,
    Dashboard
  },

  data () {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      }
    }
  }
}
</script>

<style scoped>
  .mainArea {
    margin: 0;
    margin-bottom: 32px;
    padding: 0;
    min-height: calc(100vh - 227px + 32px);
    min-width: 100% !important;
  }
  .scrollAreaclient {
    position: relative;
    max-width: 100%;
    max-height: calc(100vh - 72px);
  }
  .scrollArea {
    position: relative;
    max-width: 100%;
    height: calc(100vh - 69px) !important;
  }
  .marginside{
    margin-left: 32px;
    margin-right: 32px;
  }
  .navbar {
    width: 242px !important;
    height: 100vh !important;
  }

  .mainsize{
    width: calc(100vw - 242px);
  }

  @media screen and (max-width: 940px) {
      .navbar {
        width: 70px !important;
      }
      .mainsize {
        width: calc(100vw - 70px);
      }
  }
</style>
