<template>
    <div>
      <v-tab v-show="stermo" class='goback' @click="term()">
        <v-icon large class='icon'>mdi-chevron-left </v-icon>
      </v-tab>
        <v-slide-x-transition>
            <div  v-show="passSucess">
                <p class='sub2 CoHead mb-1'> Senha redefinida com sucesso! </p>
                <p class='colabtxt mb-5'> Clique no botão abaixo para acessar a plataforma </p>
                <v-btn color="azul" class="rounded-xl butao" @mouseover='go()' @click="ChangePass()"> <p class='txtwhite capitalize my-1 px-0'> Entrar </p></v-btn>
            </div>
        </v-slide-x-transition>

        <v-slide-x-transition>
            <div >
               <v-slide-y-transition>
                <div v-show="newPassword.error" class='alert'>
                    <v-row>
                    <v-icon color="#F44336" class='nomargin my-2 ml-3'>
                        mdi-close-circle-outline
                    </v-icon>
                    <p class='alertxt mr-3'> As senhas não coincidem. Por favor, revise. </p>
                    </v-row>
                </div>
                </v-slide-y-transition>

                        <div class='txtfield2' v-show="!stermo">
                            <v-form
                    ref='form2'
                    v-model='valid2'
                    lazy-validation>
                            <v-text-field type="password" :error='newPassword.error' dense color="vermelho" :rules='pass1' outlined label="Senha" height="40" v-model="newPassword.first"> </v-text-field>
                            <v-text-field type="password" :error='newPassword.error' dense color="vermelho" :rules='pass2' outlined label="Confirmar senha" height="40" v-model="newPassword.second"> </v-text-field>
                            </v-form>

                        <v-row class='ml-n1'> <v-checkbox v-model="termo" color="azul"> </v-checkbox> <p class='colabtxt mt-6'> Li e concordo com os <a class='link' @click="term()"> termos de uso </a> </p> </v-row>
                        </div>

                        <v-btn v-show="!stermo" color="azul" :disabled='!(valid2 && termo)' class="rounded-xl butao" @mouseover='validate2()' @click="ChangePass()"> <p class='txtwhite capitalize my-1 px-0'> Continuar </p></v-btn>

                </div>
            </v-slide-x-transition>
            <v-slide-x-transition>
            <div  v-show="stermo" class="stermo">
              <termos />
            </div>
          </v-slide-x-transition>
    </div>
</template>

<script>
import termos from '../../sobre/tabs/TabTermosDeUso.vue'

export default {
  components: { termos },
  props: { data: Object },

  data: () => ({
    loginInfo: { array: '', options: '', dialog: '' },
    choice: 0,
    stermo: false,
    termo: false,
    passSucess: false,
    valid2: true,
    newPassword: { first: '', second: '', error: false, errorMessage: '' },
    changingTable: false,
    colabSelected: '',
    colabArray: [],
    colabBtn: true,
    colabItems: [],
    error: false,
    passwordform: '',
    emailform: '',
    colabDialog: false,
    noauth: true,
    valid: true,
    imagemQRCode: '',
    buya: true,
    pass1: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    pass2: [
      v => v.length > 7 || 'A senha deve ter, no mínimo, 8 caracteres '
    ],
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ],
    emailRules: [
      v => !!v || 'Campo Obrigatório',
      v => /.+@.+\..+/.test(v) || 'Preencha com um email válido'
    ]
  }),
  methods: {
    term () {
      this.stermo = !this.stermo
      let ph
      if (this.stermo) { ph = {} } else {
        ph = { title: 'Bem vindo(a),', title2: 'Para continuar, defina uma senha', desc: 'Para ter os próximos acessos de forma segura defina uma senha própria, com, no mínimo, 8 caracteres incluindo letras e numerais.' }
      }
      this.$emit('screenControl', { intro: ph })
    },
    async ChangePass () {
      if (this.newPassword.first !== this.newPassword.second) {
        this.newPassword.error = true
        await this.$functions.sleep(4000)
        this.newPassword.error = false
      } else {
        const obj = { senha: this.newPassword.first, confirmaSenha: this.newPassword.second, termoUso: true }
        const r = await this.$http.patch(this.$config.url_backend + '/v0/account/00/password', obj, { headers: { email: this.data.email, password: this.data.password, newpassword: this.newPassword.first } })
        if (r.status === 200) {
          this.$emit('finish')
        }
      }
    },
    async validate2 () {
      this.newPassword.error = false
      await this.$refs.form2.validate()
    }
  },
  async mounted () {
    const self = this

    window.addEventListener('keyup', async function (event) {
      if (event.keyCode === 13) {
        await self.validate()
        if (self.valid) {
          self.Login()
        }
      }
    })
  }
}
</script>

<style scoped>
.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 354px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.link{
  text-decoration: none;
  font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
/* or 20px */

letter-spacing: 0.4px;

/* palette / text / secondary */

color: #134B97 !important;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}
.txtfield2{
    margin-top: 32px;
    width: 252px;
    height: 154px;
    margin-bottom: 48px;
}
.stermo{
  min-height: 400px;
  height: calc(100vh - (90px + 191px));
  overflow-y: scroll;
}
.colabtxt{
    font-size: 12px;
line-height: 156%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.sub2{
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 133.4%;
/* or 32px */

/* palette / text / secondary */

color: #D2232A;

/* Inside Auto Layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 0px 0px;
}

.goback{
  position: absolute;
  top: 40px;
  left: 65px;

}

</style>
