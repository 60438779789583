import axios from 'axios'
import router from '../router'
import config from '../config'

async function sleep (time) {
  const s = ms => new Promise(resolve => setTimeout(resolve, ms))
  await s(time)
  return true
}

async function get (url, header) {
  let holder
  await axios.get(config.url_backend + url, header)
    .then(response => { holder = response })
    .catch((status) => {
      const r = JSON.stringify(status).match(/[0-9]+/)
      router.push('/error/' + r[0])
    })
  return holder.data
}

async function patch (url, info, header) {
  let holder
  await axios.patch(config.url_backend + '' + url, info, header)
    .then(response => { holder = JSON.stringify(response) })
    .catch(err => {
      const r = JSON.stringify(err).match(/[0-9]+/)
      router.push('/error/' + r[0])
    })
  return holder
}

async function put (url, info, header) {
  let holder
  await axios.put(config.url_backend + '' + url, info, header)
    .then(response => { holder = JSON.stringify(response) })
    .catch(err => {
      const r = JSON.stringify(err).match(/[0-9]+/)
      router.push('/error/' + r[0])
    })
  return holder
}

async function deleteCall (url, header) {
  let holder
  await axios.delete(config.url_backend + '' + url, header)
    .then(response => { holder = JSON.stringify(response) })
    .catch(err => {
      const r = JSON.stringify(err).match(/[0-9]+/)
      router.push('/error/' + r[0])
    })
  return holder
}

async function post (url, Obj, header) {
  let holder
  await axios.post(config.url_backend + url, Obj, header)
    .then(response => { holder = response })
    .catch(err => {
      const r = JSON.stringify(err).match(/[0-9]+/)
      router.push('/error/' + r[0])
    })
  return JSON.stringify(holder)
}

export default { get, put, deleteCall, post, sleep, patch }
