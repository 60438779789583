/* eslint-disable no-extra-boolean-cast */
<template>
    <div class="base">
        <v-form
            ref='form'
            v-model='valid'
            lazy-validation
            class="form"
        >
            <v-row class="form" >
                <v-text-field class="inputField" v-for="item in this.buildArray" @input="item.func"  :rules='nameRules' :disabled="item.disabled" :key="item.index" outlined dense :label="item.label" height="10" v-model="item.model" style="margin-right: 32px; margin-bottom: -8px;" :counter="item.counter"/>
            </v-row>
        </v-form>
        <v-btn color="#134b97" class="rounded-xl butao" :disabled='(!valid)' @mouseover='validate()' @click="GeraQRCode()"> <p class='txtwhite capitalize my-1'> Gerar QR Code </p></v-btn>
    </div>
</template>

<script>
export default {
  created () {
    this.buildArray[3].func = this.verifyCEP
    this.buildArray[11].func = this.telephoneCheck
    this.buildArray[1].func = this.cpfCheck
    if (this.isExample) {
      this.configs = {
        bigimg: '{ margin-left: 0px;  width: 40%; height: 648px; }',
        smallimg: '{ max-height: 61px; max-width: 117px; margin-top: 28px; margin-bottom: 16px; }',
        colabTitle: '.colabTitle{font-size: 24px;line-height: 123.5%;letter-spacing: 0.25px;color: #134B97;flex: none;order: 0;flex-grow: 0;margin: 0px 0px;}'
      }
    }
  },
  props: ['homeDeliveryForm'],
  components: {
  },
  data: () => ({
    buildArray: [
      { index: 0, label: 'Nome Completo', model: '', func: null, disabled: false, name: 'name', counter: 50 },
      { index: 1, label: 'CPF', model: '', func: null, disabled: false, name: 'cpf', counter: 11 },
      { index: 2, label: 'Data de nascimento', model: '', func: null, disabled: false, name: 'date', counter: 10 },
      { index: 3, label: 'CEP do endereço de entrega', model: '', func: null, disabled: false, name: 'cep', counter: 8 },
      { index: 4, label: 'Rua', model: '', func: null, disabled: false, name: 'street', counter: 100 },
      { index: 5, label: 'Número', model: '', func: null, disabled: false, name: 'num', counter: 10 },
      { index: 6, label: 'Complemento', model: '', func: null, disabled: false, name: 'number', counter: 50 },
      { index: 7, label: 'Bairro', model: '', func: null, disabled: false, name: 'region', counter: 75 },
      { index: 8, label: 'Cidade', model: '', func: null, disabled: false, name: 'city', counter: 50 },
      { index: 9, label: 'Estado', model: '', func: null, disabled: false, name: 'state', counter: 20 },
      { index: 10, label: 'Nome para contato', model: '', func: null, disabled: false, name: 'contactName', counter: 50 },
      { index: 11, label: 'Telefone para contato', model: '', func: null, disabled: false, name: 'contactTelephone', counter: 13 },
      { index: 12, label: 'Email para contato', model: '', func: null, disabled: false, name: 'contactEmail', counter: 50 },
      { index: 13, label: 'Observações', model: '', func: null, disabled: false, name: 'obs', counter: 200 }
    ],
    wait: false,
    configs: {
      bigimg: '{ margin-left: -38px;  width: 50%; height: 768px; }',
      smallimg: '{ max-height: 123px; max-width: 234px; margin-top: 56px; margin-bottom: 32px; }',
      colabTitle: '.colabTitle{font-size: 38px;line-height: 123.5%;letter-spacing: 0.45px;color: #134B97;flex: none;order: 0;flex-grow: 0;margin: 0px 0px;}'
    },
    warning: false,
    fieldnome: '',
    fieldemail: '',
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false
    },
    valid: true,
    imagemQRCode: '',
    buya: true,
    login: {},
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ]
  }),

  methods: {
    async verifyCEP (string) {
      let returnString = ''
      const validInputs = '1234567890'
      for (let index = 0; index < string.length; index++) {
        const element = string[index]
        if (validInputs.includes(element)) {
          returnString += element
        }
      }
      this.buildArray[3].model = returnString

      if (string.length === 8) {
        try {
          const r = await this.$http.get(`https://viacep.com.br/ws/${string}/json/`)

          const array = [['logradouro', 4], ['bairro', 7], ['localidade', 8], ['uf', 9]]

          if (r.body.erro) {
            return false
          }

          for (let index = 0; index < array.length; index++) {
            const element = array[index]
            if (r.body[element[0]] && r.body[element[0]] !== '') {
              this.buildArray[element[1]].model = r.body[element[0]]
              this.buildArray[element[1]].disabled = true
            }
          }
        } catch (err) { }
      } else {
        this.buildArray[4].disabled = false
        this.buildArray[6].disabled = false
        this.buildArray[7].disabled = false
        this.buildArray[8].disabled = false
      }
    },
    telephoneCheck (string) {
      let returnString = ''
      const validInputs = '1234567890'
      for (let index = 0; index < string.length; index++) {
        const element = string[index]
        if (validInputs.includes(element)) {
          returnString += element
        }
      }
      this.buildArray[11].model = returnString
    },

    cpfCheck (string) {
      let returnString = ''
      const validInputs = '1234567890'
      for (let index = 0; index < string.length; index++) {
        const element = string[index]
        if (validInputs.includes(element)) {
          returnString += element
        }
      }
      this.buildArray[1].model = returnString
    },

    validate () {
      this.$refs.form.validate()
    },
    async GeraQRCode () {
      this.$emit('done', this.buildArray)
    }
  }
}
</script>

<style scoped>

.base{
  margin-top: 12px;
  margin-left: calc((100% - 580px)/2);
}
.form{
  width: 600px
}

@media screen and (max-width: 700px) {
  .base{
    margin-left: 32px;
    margin-bottom: 32px;
    width: 300px;
  }

  .form{
    width: 300px;
  }

}

@media screen and (max-width: 400px) {
  .form{
    width: 240px;
  }

}

.center{
  justify-content: center;
}
.UniqueFooter{
  bottom: 0px;
}
   .linkNormalization{
      text-decoration: none !important;
   }
.scrollareacolab{
    max-height: calc(100vh);
}
.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 300px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.butao{
    margin-top: 18px;
}
.size30{
  padding-left: 80px;
  padding-right: 80px;
    width: 100%;
    margin-top: 8%;
}
.colab2t{
    font-size: 20px;
line-height: 123.5%;
letter-spacing: 0.25px;
margin: 0px 0px;
}
.colabtxt{
    font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
margin: 0px 0px;
}
.name{
font-size: 16px;
line-height: 175%;
letter-spacing: 2px;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.87);
}
.lista{
font-size: 16px;
line-height: 150%;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 700px) {
  .sizedOff {
    display: none !important;
  }
  .sized {
    display: flex !important;
  }
  .size30 {
    width: 200% !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .smollimg {
    margin-left: calc(50vw - 117px) !important;
  }
}
@media screen and (max-width: 1110px) {
  .size30{
    padding-left: 42px;
    padding-right: 42px;
    width: 80%;
  }
}
@media screen and (max-width: 940px) {
  .size30{
    padding-left: 32px;
     padding-right: 32px;
  }
}
.sized{
  display: none
}
.sizedbanner{
  width: 100vw;
}
.relative{
  position: relative;
}
.f50{
  width: 50%;
}
.loading{
  left: 100px;
  top: 100px;
  position: absolute;
}
</style>
