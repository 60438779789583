<template>
<div class='mb-4 pr-4'>
      <v-row>
         <v-col v-for="item in $store.getters.FilteredList" :key="item.id" class='cards tab3'>
           <v-card class='tab3 ml-2' height="96" @click='opendetail(item.id)'>
             <v-card-text class='ma-0 pa-0'>
              <v-row class='ma-0 pa-0'>   <v-icon class='mr-8 ml-4' large color='azul'> mdi-domain </v-icon>
                <div class='line'> <p class='titletxt'> {{ item.nomeEntrega }} </p>
                <p class='mt-0'> Cadastrada por {{item.nome}} <br> em {{item.data.substr(0,10)}}</p>
                <p class='sidetxt'> {{item.numeroFuncionarios}} funcionarios </p>
                <v-icon large class='icon'>mdi-chevron-right </v-icon>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
  </v-col>
  <v-col v-if="($store.state.clientPortal.lists.length % 2) === 1">
          <v-spacer/>
  </v-col>
      </v-row>
</div>
</template>

<script>

export default {
  empresa: 'ClientsTable',
  created () {
    // this.refreshlist()
  },
  data: () => ({
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Cadastrar' : 'Edição de cadastro'
    }
  },
  methods: {
    async opendetail (item) {
      let holder = {}
      holder.collaborators = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${item}/Collaborators`, this.$store.state.clientPortal.config)
      holder.promoters = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${item}/promoter/`, this.$store.state.clientPortal.config)
      await holder.promoters.data.forEach(element => {
        element.check = false
      })
      await holder.collaborators.data.forEach(element => {
        element.check = false
      })
      holder = { id: item, collaborators: holder.collaborators.data, promoters: holder.promoters.data }
      this.$store.commit('clientdetail', holder)
    },
    refreshlist () {
      this.$store.commit('RefreshAdminPortalData', this.msal.idToken)
    },
    detail (item) {
      this.detailstab = true
      this.detaillist = item.list
      this.detailworkers = item.workers
    }

  }
}
</script>

<style scoped>
.titletxt{
  margin-top: 12px;
font-size: 16px;
line-height: 150%;
display: flex;
align-items: center;
letter-spacing: 0.15px;

color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 0px;
}
.cardtxt{
  font-family: Noto Sans;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 143%;
/* or 20px */

display: flex;
align-items: center;
letter-spacing: 0.15px;

/* palette / text / secondary */

color: rgba(0, 0, 0, 0.54);

/* Inside Auto Layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 0px;
}
  .cards{
    padding: 0px;
    margin-left: 16px;
    margin-bottom: 16px;
  }
  .sidetxt{
    color: #134B97;
    position: absolute;
    right: 62px;
    bottom: 0px;
  }
  .line{
    height: 96px;
  }
  .heightt{
    height:  calc(100vh - 193px);
  }
  .tabela{
    width: 100%;
  }
  .empresa{
font-weight: 500;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5px;
color: #D2232A;
margin-top: 19px;
  }
  .vendedor{
font-size: 14px;
line-height: 100%;
letter-spacing: 0.4px;
margin-top: 14px;
margin-bottom: 4px;
  }
  .regional{
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.54);
  }
  .t{
    max-height: 48px !important;
  }
  .icon {
    position: absolute;
    top: 15px;
    right: 48px;
  }
  .tab3 {
    position: relative;
    min-width: calc(50vw - 150px);
    max-width: calc(50vw - 150px);
  }
   @media screen and (max-width: 1150px) {

.icon {
    right: 15px;
  }
   }
  @media screen and (max-width: 1040px) {
  .tab3 {
    min-width: calc(50vw - 40px);
    max-width: calc(50vw - 40px);
  }
  }

    @media screen and (max-width: 820px) {
  .tab3 {
    min-width: calc(100vw - 64px);
    max-width: calc(100vw - 64px);
  }}
</style>
