<template>
  <v-app class='app'>
    <v-dialog v-model='loading' max-width='400px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>Espere um segundo <v-progress-circular
        class='ml-5'
        :size="20"
        color="azul"
        indeterminate
      ></v-progress-circular></v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Carregando os dados da página </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="auth">
      <Dialogs/>
      <DialogCreate/>
      <ContentView/>
    </div>
  </v-app>
</template>

<script>
import ContentView from '../components/client/ContentView.vue'
import DialogCreate from '../components/client/Dialogs/DialogCreate.vue'
import Dialogs from '../components/client/Dialogs/DialogsGroup.vue'

export default {
  computed: {
    auth () {
      return this.$store.state.clientPortal.auth.isAuth
    }
  },
  created () {
    this.initiate()
  },
  methods: {
    initiate () {
      if (!this.auth) {
        this.$router.push('/')
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    onSubmitLogin () {
      const dataLog = JSON.stringify(this.dataLogin)
      let userInput = { email: this.Email.replace(' ', ''), password: this.Senha }
      userInput = JSON.stringify(userInput)
      const authorized = dataLog.includes(userInput)

      if (authorized) {
        this.auth = true
      }
    }
  },
  data: () => ({
    loading: false
  }),
  components: {
    DialogCreate,
    Dialogs,
    ContentView
  }
}
</script>

<style scoped>
  .batata{
    width: 300px;
  }
  .app{
    min-height: 500px;
    min-width: 320px;
  }
</style>
