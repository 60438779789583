<template>
  <div class='fullsize'>
      <v-card class='mx-auto warninga centertxt' width="590" color="cinza" elevation="0">
        <h1 class='CoHead centertxt'> Ainda não há nenhuma entrega cadastrada </h1>
        <v-card-text class='pa-0'> Para iniciar o processo do cadastro, é necessário baixar os modelos de planilhas para lista de entrega e promotores, preenchê-los e cadastrar os arquviso pelo botão abaixo. </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="vermelho" class="ma-2 capitalize pr-2" outlined href="Planilhas.zip" download rounded>
                <v-icon class='mr-2'> mdi-format-vertical-align-bottom </v-icon>
                Baixar modelo de planilha
            </v-btn>

            <v-btn color='roxo' dark class='my-auto capitalize pr-2' rounded @click='chooser' right>
                <v-icon class='mr-2'> mdi-plus </v-icon>
                Cadastrar entrega
            </v-btn>
            <v-spacer />
      </v-card-actions>
      </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    chooser () {
      this.$store.commit('dialogseditclient', [{ create: true }])
    }
  }
}
</script>

<style scoped>
.warninga{
    padding-top: calc(50vh - 150px);
}
.fullsize{
    width: 100%;
    height: 100%;
}
</style>
