<template>
    <div class='mb-0 ml-4 mt-1'>
      <v-row class='ml-0 mr-5' v-if="!this.$store.state.clientPortal.opendetail.id">
        <p class='mt-8 filtertxt CoHead'> PASTAS DE ENTREGA </p>
        <v-spacer/>
        <v-col class='tab2 pr-5'>
          <v-select
            label="Ordenar por"
            :items="array2"
            v-model="order"
           @input="ordernar"
            class=''
          ></v-select>
        </v-col>
      </v-row>
    </div>
</template>

<script>

export default {
  computed: {
  },
  data: () => ({
    order: '',
    array2: ['Criador', 'Nome', 'Data', 'Id', 'Tamanho']
  }),
  methods: {
    async ordernar () {
      let holder = 'name'
      const orderobj = { Criador: 'nome', Id: 'id', Data: 'data', Nome: 'nomeEntrega', Tamanho: 'numeroFuncionarios' }
      for (const [key, value] of Object.entries(orderobj)) {
        if (key === this.order) { holder = value }
      }
      await this.$store.commit('changeordergeral', holder)
    }
  }
}
</script>

<style scoped>
.searchbar{
  margin-top: 25px;
  margin-right: 16px;
  width: 14vw;
  height: 40px;
}
.filtertxt{
font-size: 16px;
line-height: 175%;
letter-spacing: 2px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
}
.tab1{
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .tab2{
    min-width: 23% !important;
    max-width: 23% !important;
  }
  .tab3{
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .activetab3{
    background-color: #0000001f;
  }
  .option{
  height: 61px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  margin-top: -32px;
}
.optiontxt{
  font-family: Noto Sans;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;

/* palette / primary / main */

color: #134B97;

/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 10px 0px;
}
</style>
