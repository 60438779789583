<template>
  <v-app>
    <!-- Este diálogo é chamado no caso do login da pessoa demorar muito,
    assumindo que houve um erro do msal, Para a pessoa poder se desconectar e refazer o login -->
    <v-dialog v-model='errorDialog' max-width='400px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>
         Há problemas com seu login
        </v-card-title>
        <v-card-text>
          Por favor, desconecte-se pelo botão abaixo e faça o login denovo
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="azul" @click="$msal.signOut()">
            <p class='txtwhite my-auto capitalize'> Sair </p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Dialogs/>

    <div v-if="!($store.state.adminPortal.options.temp.page === 'off') && !errorDialog" :hidden="!this.$msal.isAuthenticated()">
      <ContentView/>
    </div>

  </v-app>
</template>

<script>
import Dialogs from '../components/admin/Dialogs/DialogsGroup.vue'
import ContentView from '../components/admin/ContentView.vue'

export default {
  async mounted () {
    await this.$msal.signIn()
    let failTimer = 0
    while (this.msal.idToken === '') {
      await this.$functions.sleep(100)
      failTimer = failTimer + 1
      if (failTimer > 200) {
        this.$store.commit('dialogEdit', { })
        this.errorDialog = true
        return
      }
    }
    await this.$store.commit('RefreshAdminPortalData', this.msal.idToken)
    while (this.$store.state.adminPortal.ClientData.refreshed === '') {
      await this.$functions.sleep(100)
    }
    this.$store.commit('adminOptions', { })
    this.$store.commit('dialogEdit', { })
  },

  data: () => ({
    errorDialog: false
  }),
  computed: { waitDialogText () { return !this.$msal.isAuthenticated() ? 'Redirecionando para a página de login' : 'Carregando as informações' } },

  components: {
    ContentView,
    Dialogs
  }
}
</script>
