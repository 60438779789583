// colocar aqui as variaveis
const config = {
  url_backend: '#{url_backend}#',
  url_QRCodeAPI: '#{url_QRCodeAPI}#',
  url_blobs: '#{url_blobs}#',
  auth: {
    clientId: '#{clientId}#',
    authority: '#{authority}#'
  }
}
export default config
