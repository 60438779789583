<template>
<div>
    <v-app-bar v-if="detailtab && this.$store.state.clientPortal.opendetail.id" height="48px" elevation="0" color="branco">
      <v-tabs color="vermelho" class='ml-6' v-model="details" @change="changetab()">
        <v-tab class='capitalize' > Entrega </v-tab>
        <v-tab class='capitalize' > Promotores </v-tab>
      </v-tabs>
    </v-app-bar>
</div>
</template>

<script>
export default {
  data: () => ({
    details: 0
  }),
  computed: {
    detailtab () {
      return this.$store.state.clientPortal.opendetail.id
    }
  },
  methods: {
    changetab () {
      const holder = !this.$store.state.clientPortal.page
      this.$store.commit('changepage', holder)
    }
  },
  async mounted () {
    await this.$store.commit('changepage', 1)
  }
}
</script>

<style scoped>
.icone{
  font-size: 40px !important
}
 .headertext{
    font-family: 'Co Headline Corp';
    font-size: 20px;
    line-height: 100%;
    color: #134b97;
  }
</style>
