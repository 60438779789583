<template>
  <div>

    <!-- O diálogo de espera -->
    <v-dialog v-model='waitDialog' max-width='430px' persistent>
      <v-card>
        <v-card-title class='CoHead mt-0'>
          Carregando a sua requisição
          <v-progress-circular class='ml-5' :size="20" color="azul" indeterminate > </v-progress-circular>
        </v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> Aguarde um segundo </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- O diálogo de formulário para editar e criar novos cadastros -->
    <v-dialog v-model='formDialog' max-width='610px' persistent >
      <vue-custom-scrollbar class="scrollAreas nomargin">
        <v-card>

          <v-card-title class='ml-3 CoHead mt-0'>{{ $store.state.adminPortal.dialog.title }}</v-card-title>

          <v-card-text class='mx-0 my-0'>
            <p class='ml-3 mb-0'> {{ $store.state.adminPortal.dialog.text }} </p>

            <v-card width="516" elevation="0" class='ml-3 mt-0  mx-0 pa-0'>
              <v-form ref='form' v-model='valid' lazy-validation>
                <v-text-field v-model='editedItem.name' :rules='required' label='Empresa' required />
                <v-text-field v-model='editedItem.nameUser' :rules='required.concat(twoWords)' label='Nome do Responsável' required />
                <v-text-field v-model='editedItem.email' :rules='required.concat(emailRules)' label='E-mail' required/>
                <v-text-field v-model='editedItem.telephone' :rules='required.concat(numberRules)' label='Telefone (apenas números)' required  @input='telephoneCheck()'/>
                <v-text-field v-model='editedItem.seller' :rules='required' label='Vendedor' required />
                <v-text-field v-model='editedItem.regional' :rules='required' label='Regional' required />
                <v-select class='fullwidth nomargin'
                  :items="$store.state.adminPortal.ClientData.type"
                  label="Área"
                  v-model="editedItem.typeName"
                  :menu-props="{offsetY: true}"
                  color="vermelho"
                ></v-select>
                <v-checkbox  v-model='editedItem.isHomeDelivery' color="vermelho" label="Entrega Porta a Porta"></v-checkbox>
              </v-form>
            </v-card>

          </v-card-text>

          <v-card-actions class='pb-3'>
            <v-spacer></v-spacer>
            <v-btn color='azul' @click='close' rounded outlined class='mr-3 capitalize'> Cancelar </v-btn>
            <v-btn color='azul' :disabled='!valid' @mouseover='validate' @click='save()' rounded>
              <p class='my-auto txtwhite capitalize'> Confirmar </p>
            </v-btn>
          </v-card-actions>

        </v-card>
      </vue-custom-scrollbar>
    </v-dialog>

    <!-- O diálogo para confirmar -->
    <v-dialog v-model='confirmDialog' :max-width="($store.state.adminPortal.dialog.name === 'delete' ?  '340px' : '498px')" max-height='296px' persistent>
      <v-card>
        <v-card-title class='CoHead pr-16 mr-12'> {{ $store.state.adminPortal.dialog.title }} </v-card-title>

        <!-- puxando a lista de dados da pessoa se for um de envio de email  -->
        <v-card-text  v-if="$store.state.adminPortal.dialog.name === 'email' ">
          <v-row  v-for="item in detailsArray" :key="item.index" class='rows'>
            <v-icon class='mr-8'> {{item.icon}} </v-icon> <p class='cardtxt mt-3 mb-2'> {{editedItem[item.variable]}} </p>
          </v-row>
        </v-card-text>

        <v-card-text v-else >
          {{ $store.state.adminPortal.dialog.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color='azul' outlined @click='close' rounded class='mr-1'><p class='my-auto capitalize'>Cancelar</p></v-btn>
          <v-btn color='azul' @click='confirm' rounded><p class='txtwhite my-auto capitalize'>Confirmar</p></v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- O diálogo com detalhes da empresa -->
    <v-dialog v-model='detailDialog' max-width='520px' persistent>
      <v-card color='branco'>

        <!-- o botãozinho para fechar o diálogo -->
        <v-app-bar elevation="0" color="branco">
          <v-card-title class='CoHead my-0'>{{editedItem.name}}</v-card-title> <v-spacer/> <v-icon @click='close' color='preto'> mdi-close </v-icon>
        </v-app-bar>

        <!-- a lista com os detalhes do user -->
        <v-card-text class='pl-0'>
          <v-row v-for="item in detailsArray" :key="item.index" class='rows'>
            <v-icon color="azul"> {{item.icon}} </v-icon> <p class='cardtxt mb-0 ml-8'> {{editedItem[item.variable]}} </p>
          </v-row>
          <v-row class='rows'>
             <v-icon color="azul" class='mt-n2'> mdi-clipboard-account </v-icon>
            <div class='line ml-8 nomargin'>
              <p class='cardtxt nomargin'> {{ editedItem.seller }} </p>
              <p class='nomargin'>{{editedItem.regional}} </p>
            </div>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color='azul' rounded @click="downloadHomeDelivery(editedItem.id)" class='capitalize txtwhite' style="color: white" :disabled="!editedItem.isHomeDelivery || false">Baixar Relatório</v-btn>
          <v-spacer></v-spacer>
          <v-btn color='azul' outlined rounded @click="($store.commit('dialogEdit', $bases.adminPortal.dialogs.edit))" class='capitalize'>Editar Informações</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- O diálogo de aviso, com apenas um botão -->
    <v-dialog v-model='successDialog' max-width='340px' persistent>
      <v-card>
        <v-card-title class='CoHead mb-3'> {{ $store.state.adminPortal.dialog.title }} </v-card-title>
        <v-card-text><p> {{ $store.state.adminPortal.dialog.text }} </p></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='roxo' rounded @click='close()' class='capitalize'><p class='txtwhite my-auto capitalize'>Entendido</p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import regex from '@/general/regexrules.js'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
  components: { vueCustomScrollbar },
  computed: {
    // Os bolleans que controlam a presença dos diálogos
    formDialog () { return (this.$store.state.adminPortal.dialog.type === 'form') },
    confirmDialog () { return (this.$store.state.adminPortal.dialog.type === 'confirm') },
    detailDialog () { return (this.$store.state.adminPortal.dialog.type === 'detail') },
    successDialog () { return (this.$store.state.adminPortal.dialog.type === 'success') },
    waitDialog () { return (this.$store.state.adminPortal.dialog.type === 'wait') },

    editedItem () { return this.$store.state.adminPortal.EditedClient },
    config () { return { headers: { Access_Token: this.msal.idToken } } }
  },
  data: () => ({
    detailsArray: [
      { icon: 'mdi-account', variable: 'nameUser' },
      { icon: 'mdi-email', variable: 'email' },
      { icon: 'mdi-phone', variable: 'telephone' },
      { icon: 'mdi-briefcase-variant', variable: 'typeName' }
    ],
    valid: true,

    // Os Regex
    required: [v => !!v || 'Campo Obrigatório'],
    onlyLetters: [v => regex.justLetters.test(v) || 'Sem caracteres especiais e acentos'],
    twoWords: [v => regex.twoWords.test(v) || 'Nome e Sobrenome'],
    emailRules: [v => regex.emailFormat.test(v) || 'O email deve ser válido'],
    numberRules: [v => regex.numberFormat.test(v) || 'Digite apenas números']
  }),

  methods: {
    async downloadHomeDelivery (id) {
      const r = await this.$functions.get('/v0/companies/' + id + '/homeDelivery', this.config)

      const wb = XLSX.utils.book_new()
      wb.props = {
        Title: 'Lista de Porta a Porta - BRFCode',
        Subject: 'BRF',
        Author: 'BRF'

      }
      wb.SheetNames.push('Colaboradores')
      wb.Sheets.Colaboradores = this.sheetcreate(r)
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
      FileSaver.saveAs(new Blob([this.convert(wbout)], { type: 'application/octet-stream' }), 'BRFCode_Relatório.xlsx')
    },

    convert (s) {
      const buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
      const view = new Uint8Array(buf) // create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF // convert to octet
      return buf
    },
    sheetcreate (obj) {
      const wsData = obj
      const ws = XLSX.utils.json_to_sheet(wsData)
      return ws
    },
    // As funções principais dos diálogos
    async confirm () {
      if (this.$store.state.adminPortal.dialog.name === 'email') {
        await this.$functions.deleteCall('/v0/Companies/' + this.editedItem.id, this.config)
          .then(this.$store.commit('dialogEdit', this.$bases.generic.success.delete))
      } else if (this.$store.state.adminPortal.dialog.name === 'delete') {
        await this.$functions.get(`/v0/account/${this.editedItem.idUser}/newPassword`, this.config)
          .then(this.$store.commit('dialogEdit', this.$bases.generic.success.email))
      }
    },

    async save () {
      for (let index = 0; index < this.$store.state.adminPortal.ClientData.type.length; index++) {
        const element = this.$store.state.adminPortal.ClientData.type[index]
        if (this.editedItem.typeName === element) { this.editedItem.type = index + 1 }
      }

      if (this.$store.state.adminPortal.dialog.name === 'edit') {
        await this.$functions.patch('/v0/Companies/' + this.editedItem.id, this.editedItem, this.config)
          .then(this.$store.commit('dialogEdit', this.$bases.generic.success.edit))
      } else if (this.$store.state.adminPortal.dialog.name === 'create') {
        this.editedItem.situation = 1
        await this.$functions.post('/v0/Companies', this.editedItem, this.config)
          .then(this.$store.commit('dialogEdit', this.$bases.generic.success.create))
      }
    },

    close () {
      if (this.$store.state.adminPortal.dialog.extra === 'return') {
        this.$store.commit('dialogEdit', this.$bases.adminPortal.dialogs[this.$store.state.adminPortal.dialog.option])
      } else {
        this.$store.commit('dialogEdit')
        this.$store.commit('RefreshAdminPortalData', this.msal.idToken)
        this.clearForm()
      }
    },

    // Funções complementares
    telephoneCheck () {
      const x = this.editedItem.telephone.replace(/[^0-9.]/g, '').match(/(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
      this.editedItem.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + ' ' + x[3] + (x[4] ? '-' + x[4] : '')
    },

    async validate () {
      if (this.$store.state.adminPortal.dialog.name === 'create') {
        this.$store.state.adminPortal.ClientData.body.forEach(async element => {
          if (element.name === this.editedItem.name) {
            this.$store.commit('dialogEdit', { type: 'success', extra: 'return', option: 'create', title: 'Erro no Cadastro', text: 'O campo de nome da empresa deve ser único' })
          }
        })
      }
      this.$refs.form.validate()
    },

    clearForm () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style scoped>

  .scrollAreas {
    position: relative;
    max-width: 610px;
    max-height: calc(98vh);
  }

  .cardtxt {
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .rows {
    height: 48px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: 16px;
  }
</style>
