<template>
  <div>

    <v-dialog v-model="dialog.show" width="500">
      <v-card width="500">
        <v-card-title class='CoHead mt-0'>
          Tem certeza que deseja {{ dialog.action }}?
        </v-card-title>
        <v-card-text class='mx-0 my-0'>
          <p class=' mb-3'> essa ação não poderá ser desfeita </p>
        </v-card-text>

        <v-card-actions class='pb-3'>
          <v-spacer></v-spacer>
          <v-btn color='azul' @click='cancel()' rounded outlined class='mr-3 capitalize'> Cancelar </v-btn>
          <v-btn color='azul' @click='save()' rounded>
            <p class='my-auto txtwhite capitalize'> Confirmar </p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-slide-y-transition>
        <div v-show="newbar" class='alerto'>
          <v-row class='pt-1'>
            <v-btn text class='capitalize' color="vermelho" @click="selectAll"> Selecionar tudo </v-btn>
            <v-btn text class='capitalize' color="vermelho" @click="sendAll"> Enviar QRCode </v-btn>
            <v-btn text class='capitalize' color="vermelho" @click="deleteAll"> Excluir </v-btn>
            <v-btn text class='capitalize' color="azul" @click="clearDelete"> Cancelar </v-btn>
          </v-row>
        </div>
      </v-slide-y-transition>

      <div :hidden='($store.getters.FilteredCollaborator.length !== 0 && $store.state.clientPortal.page) || ($store.getters.FilteredPromoters.length !== 0 && !$store.state.clientPortal.page)' class='centertxt'>
      <div class='spacervertical px-16' />
      <h1 class='CoHead centertxt'> Ainda não há nenhuma lista cadastrada </h1>
      <p class='px-16 mx-16'> Para iniciar o processo do cadastro, é necessário baixar os modelos de planilhas para lista de {{$store.state.clientPortal.page? 'colaboradores' : 'promotores'}}, preencher e cadastrar o arquivo pelo botão abaixo. </p>
        <v-btn
       v-if="$store.state.clientPortal.pagination === 0"
       :disabled="!$store.state.clientPortal.login.permissao"
      color="vermelho"
      class="ma-2 capitalize"
      outlined
      href="Planilhas.zip"
      download
      rounded>
      <v-icon class='mr-2'> mdi-format-vertical-align-bottom </v-icon>
       Baixar modelos
      </v-btn> <v-btn rounded color="vermelho" @click='addone()'>  <v-icon class='mr-2' color="branco"> mdi-plus </v-icon> <p class='capitalize txtwhite mt-4'>  Cadastrar entrega </p> </v-btn>
    </div>

      <v-data-table
      mobile-breakpoint="10"
      v-if="!!$store.state.clientPortal.page && this.reset && $store.getters.FilteredCollaborator.length !== 0"
        class='mx-4 mb-4'
       calculate-widths:true
        :loading='$store.state.clientPortal.loading'
       :headers='headers'
        :items='$store.getters.FilteredCollaborator'
        sort-by='name'
        :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[4,8,12]}"
      >
        <template v-slot:item.company="{ item }">
           <v-row> <v-checkbox v-model="item.check" @click='controlDelete(item)' color="vermelho"></v-checkbox> <p class='empresa'> {{ item.matricula }} </p> </v-row>
        </template>
        <template v-slot:item.nome="{ item }">
           <v-row @click='openDetail(item)' class='px-3'> <p class='my-3'> {{ item.nome }} </p> </v-row>
        </template>
        <template v-slot:item.email="{ item }">
           <v-row @click='openDetail(item)' class='px-3'> <p class='my-3'> {{ item.email }} </p> </v-row>
        </template>
         <template v-slot:item.entrega="{ item }">
          <div :disabled='item.isRead === 0' @click='openDetail(item)'>
           <v-icon
            v-show='item.isRead'
            v-model='item.entrega'
            color='green'> mdi-check-circle-outline </v-icon>
             <v-icon
          v-show='!item.isRead'
          :disabled='!item.isGenerated'
            v-model='item.entrega'
            color='blue'> mdi-check-circle-outline </v-icon>
            </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
          <v-row class='t'>
          <v-spacer/>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            class='mx-0'
            @click='editItem(item)'
            color='azul'
          >
            mdi-pencil
          </v-icon>
          <v-spacer/>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            @click='SendEmail(item)'
            class='mx-0'
            color='azul'
          >
            mdi-email
          </v-icon>
          <v-spacer/>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            @click='deleteItem(item)'
            class='mx-0'
            color='azul'
          >
            mdi-delete
          </v-icon>
          <v-spacer/>
          </v-row>
          </div>
        </template>
        <template v-slot:no-data>

        </template>
      </v-data-table>
      <v-data-table
      mobile-breakpoint="10"
      v-if="!!!$store.state.clientPortal.page && this.reset && this.reset && $store.getters.FilteredPromoters.length !== 0"
        class='mx-4 mb-4'
       calculate-widths:true
        :loading='$store.state.clientPortal.loading'
        :headers='headers2'
        :items='$store.getters.FilteredPromoters'
        sort-by='name'
        :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[4,8,12]}"
      >
        <template v-slot:item.company="{ item }">
           <v-row> <v-checkbox v-model="item.check" @click='controlDelete(item)' color="vermelho"></v-checkbox> <p class='empresa'> {{ item.name }} </p> </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-tab :ripple="false" class='tabs' >
          <div>
          <v-row class='t start'>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            class='mx-0 mr-2'
            @click='editItem(item)'
            color='azul'
          >
            mdi-pencil
          </v-icon>
          <v-spacer/>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            @click='SendEmail(item)'
            class='mx-0 mr-2'
            color='azul'
          >
            mdi-email
          </v-icon>
          <v-spacer/>
          <v-icon
          :disabled="!$store.state.clientPortal.login.permissao"
            @click='deleteItem(item)'
            class='mx-0'
            color='azul'
          >
            mdi-delete
          </v-icon>
          <v-spacer/>
          </v-row>
          </div>
            </v-tab>
        </template>
        <template v-slot:no-data>

        </template>
      </v-data-table>
    </div>
</template>

<script>
export default {
  empresa: 'ClientsTable',
  created () {
    // this.refreshlist()
  },
  data: () => ({
    dialog: { show: false },
    deleteDialog: false,
    reset: true,
    newbar: false,
    deleteArray: [],
    VARNAME: 'NOME',
    key: 'lists',
    headers: [
      { text: 'Matrícula', sortable: false, value: 'company', width: '25%', align: 'start' },
      { text: 'NOME COMPLETO', sortable: false, value: 'nome', width: '25%' },
      { text: 'E-MAIL', value: 'email', sortable: false, width: '25%' },
      { text: 'SITUAÇÃO', align: 'center', sortable: false, value: 'entrega', width: '10%' },
      { text: 'AÇÕES', value: 'actions', sortable: false, width: '15%' }
    ],
    headers2: [
      { text: 'NOME COMPLETO', sortable: false, value: 'company', width: '25%', align: 'center' },
      { text: 'E-MAIL', value: 'email', sortable: false, width: '25%', align: 'center' },
      { text: 'AÇÕES', value: 'actions', sortable: false, width: '15%', align: 'center' }
    ],
    detaillist: {},
    detailworkers: {},
    detailstab: false,
    headerwidth: '220',
    valid: true,
    examples: [],
    editedIndex: -1,
    editedItem: {
      empresa: '',
      name: '',
      email: '',
      telephone: '',
      vendedor: '',
      entrega: true
    },
    defaultItem: {
      empresa: '',
      name: '',
      email: '',
      telephone: '',
      vendedor: '',
      entrega: true
    }
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Cadastrar' : 'Edição de cadastro'
    }
  },
  methods: {
    async save () {
      this.$store.commit('changeLoading', true)
      const array = this.deleteArray
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        this.url = `v0/Company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/collaborators/${element + this.dialog.sufix}`
        await this.dialog.function('/' + this.url, this.dialog.header)
      }
      this.clearDelete()
    },

    closeDelete () {
      this.deleteDialog = false
    },
    addone () {
      this.$store.commit('dialogseditclient', [{ create: true }])
    },
    async deleteAll () {
      this.dialog = {
        show: true,
        action: 'deletar',
        function: this.$functions.deleteCall,
        header: this.$store.state.clientPortal.config,
        sufix: ''
      }
    },
    async cancel () {
      this.dialog = { show: false }
    },
    async sendAll () {
      this.dialog = {
        show: true,
        action: 'enviar QRCode',
        function: this.$functions.get,
        header: this.$store.state.clientPortal.config,
        sufix: '/qrcode'
      }
    },
    async selectAll () {
      if (this.$store.state.clientPortal.page) {
        await this.$store.getters.FilteredCollaborator.forEach(element => {
          this.deleteArray.push(element.token)
        })
      } else {
        await this.$store.getters.FilteredPromoters.forEach(element => {
          this.deleteArray.push(element.id)
        })
      }
      this.$store.commit('allCheck')
    },
    async clearDelete () {
      this.$store.commit('changeLoading', true)
      this.dialog = { show: false }
      await this.$functions.sleep(500)
      this.$store.commit('RefreshClientPortalData')
      this.deleteArray = []
      this.newbar = false
      this.reset = false
      await this.$functions.sleep(1)
      this.reset = true
      this.$store.commit('clearCheck')
      let holder = {}
      holder.collaborators = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators`, this.$store.state.clientPortal.config)
      holder.promoters = await this.$functions.get(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/promoter/`, this.$store.state.clientPortal.config)
      holder = { id: this.$store.state.clientPortal.opendetail.id, collaborators: holder.collaborators.data, promoters: holder.promoters.data }
      this.$store.commit('clientdetail', holder)
      this.$store.commit('changeLoading', false)
    },
    controlDelete (item) {
      const id = item.id
      const index = this.deleteArray.indexOf(id)
      if (index !== -1) {
        this.deleteArray.splice(index, 1)
      } else {
        this.deleteArray.push(id)
      }
      if (this.deleteArray.length > 0) {
        this.newbar = true
      } else { this.newbar = false }
    },
    detail (item) {
      this.detailstab = true
      this.detaillist = item.list
      this.detailworkers = item.workers
    },
    async editItem (item) {
      const url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators/${item.id}`
      if (this.$store.state.clientPortal.page) {
        const holder2 = this.$store.state.clientPortal.config
        holder2.headers.token = this.$store.state.clientPortal.general.token
        const holder = await this.$functions.get(url, holder2)
        await holder.data.forEach(element => {
          if (element.matricula === item.matricula) {
            item = element
          }
        })
      }
      item.observacao = item.obs
      this.$store.commit('generalchangesclient', item)
      const compl = this.$store.state.clientPortal.page ? 'colaborador' : 'promotor'
      const range = this.$store.state.clientPortal.page ? 'Collaborator' : 'Promoter'
      this.$store.commit('dialogDetail', { name: 'edit', range, type: 'Form', title: 'Editar ' + compl, text: 'Para editar o ' + compl + ' preencha o formulário abaixo e depois clique em salvar.' })
    },

    deleteItem (item) {
      this.$store.commit('generalchangesclient', item)
      const range = this.$store.state.clientPortal.page ? 'Collaborator' : 'Promoter'
      this.$store.commit('dialogDetail', { type: 'Confirm', range, name: 'delete', title: 'Tem Certeza?', text: 'Tem certeza que deseja excluir? Essa ação não poderá ser desfeita' })
    },

    SendEmail (item) {
      this.$store.commit('generalchangesclient', item)
      const range = this.$store.state.clientPortal.page ? 'Collaborator' : 'Promoter'
      this.$store.commit('dialogDetail', { type: 'Confirm', range, name: 'email', title: 'Gostaria de enviar os dados do login novamente para esse contato?', text: '' })
    },

    async openDetail (item) {
      const url = `/v0/company/${this.$store.state.clientPortal.login.idCompany}/delivery/${this.$store.state.clientPortal.opendetail.id}/Collaborators/${item.id}`
      if (this.$store.state.clientPortal.page) {
        const holder2 = this.$store.state.clientPortal.config
        holder2.headers.token = this.$store.state.clientPortal.general.token
        const holder = await this.$functions.get(url, holder2)
        await holder.data.forEach(element => {
          if (element.matricula === item.matricula) {
            item = element
          }
        })
      }
      item.observacao = item.obs
      this.$store.commit('generalchangesclient', item)
      this.$store.commit('dialogDetail', { type: 'Detail' })
    }
  }
}
</script>

<style scoped>

.spacervertical{
  height: calc(50vh - 300px);
}
.alerto{
 background: #E8DEE5;
  border-radius: 0px;
  position: absolute;
  left: 16px;
  top: 72px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  height: 48px;
  width: calc(100vw - 272px);
}
.tabs {
  height: 64px;
  width: 100%;
}
  .start{
    text-align: start !important;
    align-items: start !important;
  }
  .sidetxt{
    color: #134B97;
    position: absolute;
    right: 62px;
    bottom: 0px;
  }
  .line{
    height: 96px;
  }
  .heightt{
    height:  calc(100vh - 187px);
  }
  .tabela{
    width: 100%;
  }
  .empresa{
font-weight: 500;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5px;
color: #D2232A;
margin-top: 19px;
  }
  .vendedor{
font-size: 14px;
line-height: 100%;
letter-spacing: 0.4px;
margin-top: 14px;
margin-bottom: 4px;
  }
  .regional{
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.54);
  }
  .t{
    max-height: 48px !important;
  }
</style>
