<template>
  <v-row class='cinza nomargin'>
    <Navbar class='navbar'/>
    <div class='mainsize'>
    <Appbar/>
    <vue-custom-scrollbar id='scrollbar' class="scrollAreaclient nomargin"  :settings="settings">

      <div v-if="!this.$store.state.clientPortal.opendetail.id && this.$store.state.clientPortal.pagination===0" class='mainArea mainsize'>
        <div v-if="$store.getters.FilteredList.length > 0">
        <Filterbar />
        <ClientsCards />
      </div>
      <div v-else>
        <EmptyWarning />
      </div>
      </div>
      <div v-if="this.$store.state.clientPortal.opendetail.id && this.$store.state.clientPortal.pagination===0" class='mainArea mainsize'>
        <DetailsTabs />
        <FilterDetails/>
        <TableDetails class='minwid'/>
      </div>

      <div v-if="this.$store.state.clientPortal.pagination === 1" class='mainArea'>
        <CompleteBoard isClient=true />
      </div>

      <div v-if="this.$store.state.clientPortal.pagination === 2" class='mainArea'>
        <PageConfig class='ma-0 pa-7'/>
      </div>

      <div v-if="this.$store.state.clientPortal.pagination === 3" class='mainArea'>
        <About />
      </div>

      <div v-if="this.$store.state.clientPortal.pagination === 4" class='mainArea'>
        <AcessManager />
      </div>

      <CustomFooter :change-pages="['Entregas', 'Dashboard', 'Configuração', 'Sobre', 'Acessos']" :wire-frame-logo="true"/>
    </vue-custom-scrollbar>
    </div>
  </v-row>
</template>

<script>
import About from '../sobre/AboutGroup.vue'
import Navbar from './fixed/NavegationBar.vue'
import CustomFooter from '../shared/NewFooter.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import ClientsCards from './tabEntregas/ClientsTable.vue'
import Appbar from './fixed/AppBar.vue'
import Filterbar from './tabEntregas/FilterBar.vue'
import FilterDetails from './tabEntregas/tabdetails/FilterDetails.vue'
import TableDetails from './tabEntregas/tabdetails/TableDetails.vue'
import DetailsTabs from './tabEntregas/tabdetails/DetailsTabs.vue'
import AcessManager from './tabAccess/accessManager.vue'
import CompleteBoard from '../Dashboard/CompleteBoard.vue'
import PageConfig from './tabConfig/pageConfig.vue'
import EmptyWarning from './tabEntregas/emptyWarning.vue'

export default {
  components: {
    EmptyWarning,
    CompleteBoard,
    Navbar,
    About,
    CustomFooter,
    AcessManager,
    vueCustomScrollbar,
    Filterbar,
    TableDetails,
    ClientsCards,
    FilterDetails,
    DetailsTabs,
    Appbar,
    PageConfig
  },
  mounted () {
    this.$store.commit('RefreshClientPortalData')
  },
  computed: {
  },
  data () {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      }
    }
  }
}
</script>

<style scoped>

  .minwid{
    min-width: 775px;
  }
.mainArea{
    margin: 0;
  padding: 0;
  min-height: calc(100vh - 152px);
}
.scrollAreaclient {
  position: relative;
  max-width: 100%;
  min-height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
}
.scrollArea {
  position: relative;
  max-width: 100%;
  height: calc(100vh - 69px) !important;
}
 .marginside{
   margin-left: 32px;
   margin-right: 32px;
 }
.navbar {
  width: 242px !important;
  height: 100vh !important;
}

.mainsize{
  width: calc(100vw - 242px);
}
@media screen and (max-width: 940px) {
  .navbar {
    width: 70px !important;
  }
  .mainsize{
  width: calc(100vw - 70px);
}}
</style>
