<template>
  <div>
    <v-dialog v-model="this.$store.state.adminPortal.AccessDialog.create.show" max-width="610">
        <v-card class="createDialog">
            <v-card-title> Criar Novo Cadastro </v-card-title>
            <v-card-text> Para criar novos cadastros, preencha o formulário abaixo e depois clique em salvar </v-card-text>
            <v-form ref='form' v-model='valid' lazy-validation>
                <v-text-field v-model="data.name" label="Nome" :rules='rules.required'/>
                <v-text-field v-model="data.email" label="Email" :rules='rules.required.concat(rules.emailRules)'/>
                <v-select v-model="data.accessType" persistent-placeholder placeholder="Brf"  :items="['Brf', 'Promotor Mestre']" label="Tipo de Acesso" :v-model="selected" :menu-props="{offsetY: true}"/>
                <v-select v-model="data.accessLevel" persistent-placeholder :placeholder="data.accessType === 'Promotor Mestre' ?  'Editor' : 'Visualizador' "  :items="['Visualizador', 'Editor']" label="Nível do Acesso" :v-model="accessLevel" :menu-props="{offsetY: true}" :disabled="data.accessType == 'Promotor Mestre'"/>
            </v-form>

            <v-card-actions class='pb-3'>
                <v-spacer></v-spacer>
                <v-btn color='azul' @click='close' rounded outlined class='mr-3 capitalize'> Cancelar </v-btn>
                <v-btn color='azul' :disabled='!valid' @mouseover='validate' @click='save()' rounded>
                <p class='my-auto txtwhite capitalize'> Confirmar </p>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

     <v-dialog v-model="this.$store.state.adminPortal.AccessDialog.delete.show" max-width="410">
        <v-card class="createDialog">
            <v-card-title> Deletar Cadastro </v-card-title>
            <v-card-text> Não há retorno para essa ação, tem certeza que deseja continuar? </v-card-text>
            <v-card-actions class='pb-3'>
                <v-spacer></v-spacer>
                <v-btn color='azul' @click='close' rounded outlined class='mr-3 capitalize'> Cancelar </v-btn>
                <v-btn color='azul' @click='del()' rounded>
                <p class='my-auto txtwhite capitalize'> continuar </p>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import regex from '@/general/regexrules.js'

export default {
  data: () => ({
    accessLevels: { Editor: 1, Visualizador: 2, Admin: 0 },
    accessTypes: { Brf: 0, 'Promotor Mestre': 3 },
    selected: 1,
    accessLevel: 0,
    valid: true,
    data: {},
    rules: {
      required: [v => !!v || 'Campo Obrigatório'],
      emailRules: [v => regex.emailFormat.test(v) || 'O email deve ser válido']
    }
  }),
  props: [
    'getInfo'
  ],
  computed: {
    config () { return { headers: { Access_Token: this.msal.idToken } } }
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    close () {
      this.data = {}
      this.$store.commit('editAccessDialog', { create: { show: false }, delete: { show: false } })
      this.getInfo()
    },
    async del () {
      try {
        const info = this.$store.state.adminPortal.AccessDialog.delete.info
        const url = `/v0/accounts/${info.idAccount}/accesses/${info.idAccess}`
        await this.$functions.deleteCall(url, this.config)
        this.close()
      } catch (err) {
        // TODO: treat both ways to show
      }
    },
    async save () {
      try {
        // TODO: rever a lógica aqui
        const newObject = JSON.parse(JSON.stringify(this.data))
        const def = !newObject.accessType ? 2 : 1
        newObject.level = (newObject.accessLevel ? this.accessLevels[newObject.accessLevel] : def)
        newObject.type = this.accessTypes[newObject.accessType || 'Brf']
        await this.$functions.post('/v0/accounts/accesses', newObject, this.config)
        this.close()
        // TODO: show success
      } catch (err) {
        // TODO: show error
      }
    }
  }
}
</script>

<style scoped>
    .createDialog{
        width: 610px !important;
    }

    form{
        padding: 32px;
    }
</style>
