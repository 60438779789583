<template>
   <v-navigation-drawer color="branco" permanent class='minsize'>
      <div>
         <router-link to='/' class='a'>
         <div class='minosize size1off'>
         <v-img
            max-height='36'
            max-width='141'
            class='IBAGENS'
            src='@/assets/img/BRFCode.png'></v-img>
         </div>
         <div class='minosizebox size1'>
         <v-img
            max-height='36'
            max-width='36'
            class='IBAGENS'
            src='@/assets/img/BoxBRFCode.png'></v-img>
         </div>
         </router-link>
         <v-divider/>
         <v-tabs
         v-model="$store.state.clientPortal.pagination"
         vertical
            color='roxo'
            active-class='activeone'>
            <v-tab v-for="item in this.item" :key="item.index">
               <v-icon v-if="$store.state.clientPortal.pagination === item.index" class='mr-8 my-4' color="vermelho"> {{item.icon}} </v-icon>
               <v-icon v-if="$store.state.clientPortal.pagination !== item.index" class='mr-8 my-4'> {{item.icon}} </v-icon>
               <p  active-class='activeone' class='my-4 tabText capitalize'> {{item.name}} </p> <v-spacer/>
            </v-tab>

            <div class='spacerheight'>  </div>
            <div>
            <v-row class='mb-3 kkk'>
             <v-card  rounded='10' width="30" height="30" color="azul" class='rounded-circle ml-7 chip'>
                  <p class='txtwhite chiptxt mx-2 my-2'> {{$store.state.clientPortal.login.name.substr(0,1)}} </p>
                </v-card>
                <div class='size1off'>
            <p class='navtext'> {{$store.state.clientPortal.login.name}} </p>
            <p class='navtext cygrey'> {{$store.state.clientPortal.login.company}} </p>
                </div>
            </v-row>
            </div>
            <v-divider/>
            <v-tab>
               <v-icon class='mr-6 my-4 txtblue'> mdi-account-plus </v-icon>
               <p class='my-4 capitalize txtblue size1off'> compartilhar acesso </p> <v-spacer/>
            </v-tab>
         </v-tabs>
         <div class=''>
                 <v-divider> </v-divider>
                 <router-link to='/' class='a'>
            <v-tab>
               <v-icon class='my-4'> mdi-logout </v-icon>
               <p class='mt-4 ml-6 capitalize size1off'> Sair </p> <v-spacer/>
            </v-tab>
                 </router-link>
         </div>
      </div>
   </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    item: [
      { icon: 'mdi-clipboard-check', name: 'Entregas', index: 0 },
      { icon: 'mdi-poll', name: 'dashboard', index: 1 },
      { icon: 'mdi-brush', name: 'Configuração', index: 2 },
      { icon: 'mdi-information-outline', name: 'Sobre', index: 3 }]
  }),
  methods: {
    changepagination (item) {
      this.$store.commit('changepagination', item)
    }
  },
  computed: {
    user () {
      const user = null
      if (this.$msal.isAuthenticated) {
        user.profile = this.msal.user
      }
      return user
    }
  }
}
</script>

<style scoped>
.IBAGENS{
   margin: auto;
}
.spacerheight{
  min-height: calc(100vh - 72px - 192px - 112px - 33px)
}
.chip{
   position: relative;
   top:5px;
}
   .minsize{
      min-height: 360px;
   }
    .minosize{
       padding-top: 18px !important;
      min-height: 72px;
       align-content: center;
   }
    .minosizebox{
       padding-top: 0px !important;
      min-height: 72px;
       align-content: center;
   }
   .a{
      text-decoration: none;
      color: #504e4e;
   }
   .activeone{
      background-color: #EFEFEF;
      color: #D2232A !important;
   }
   .cygrey{
     color: #0000008a;
   }
   .navtext{
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
margin-bottom: 0px;
margin-left: 20px;
   }
   .tabText{
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.15px;
      margin-bottom: 0px;
   }
       .chiptxt{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    margin-top: 8px;
    margin-left: 10px;
  }
  .kkk{
height: 38px;

  }
</style>
