<template>
  <v-card class='branco a pl-6' height="371">
    <DownloadIcon v-bind:data="data.download" v-if="isClient"/>

    <v-card-title class='title ml-n4'>
      Colaboradores cadastrados
    </v-card-title>
    <v-card width="200" height="40" elevation="0">
      <v-select v-model="escolhido" :items=list :menu-props="{offsetY: true}" outlined class='nomargin mt-2' dense>
        </v-select>
    </v-card>

    <v-card width="390" height="216" class='mt-7 colabInfo branco' elevation="0">
      <v-row class='mx-auto mb-3'>
        <v-spacer/>
      <v-icon class='colabIcon'> mdi-account </v-icon>
      <p class='colabTitle'> {{data.chart[escolhido]}} </p>
      <p class='colabTxt'> Colaboradores </p>
      <v-spacer/>
      </v-row>
    </v-card>

  </v-card>
</template>

<script>

import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  props: ['data', 'isClient'],
  data: () => ({
    escolhido: 'Total',
    settings: {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    },
    eko: '150px'
  }),
  computed: {
    list () {
      const array = []
      if (typeof this.data.chart !== 'undefined') {
        for (const [key] of Object.entries(this.data.chart)) {
          array.push(key)
        }
      }
      return array
    }
  }
}
</script>

<style scoped>
.a{
  width: 434px;
}
@media screen and (max-width: 1100px) {
  .a{
  width: 475px !important;
}
}
@media screen and (max-width: 1359px) {
  .a{
  width: calc(100vw - 242px - 72px - 510px);
}
}
/* colabdata */
.colabInfo{
   display: flex;
    align-items: flex-end;
}
.colabTxt{
  font-family: 'Noto Sans';
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
  color: #000000;
  flex: none;
  margin-left: 8px;
  margin-bottom: 30px;
}
.colabTitle{
  margin-left: 10px;
font-family: 'Co Headline Corp' !important;
font-size: 60px;
line-height: 120%;
letter-spacing: -0.5px;
color: #303086;
}
.colabIcon{
font-family: 'Co Headline Corp' !important;
font-size: 58px;
line-height: 120%;
letter-spacing: -0.5px;
color: #303086;
margin-bottom: 8px;
}

.title{
font-family: 'Co Headline Corp' !important;
font-size: 18px;
line-height: 22px;
color: #000000;
}
</style>
