<template>
    <div class='minsize'>
        <div class='explanation'>
           Aqui você pode personalizar a página que os colaboradores terão acesso ao solicitarem o QR Code. Insira o logotipo da empresa, um texto de apresentação, a imagem personalizada dos Kits BRF e defina os parâmetros de login. O logotipo e o e-mail de contato também serão usados caso os QR Codes sejam enviados para o e-mail do colaborador.
        </div>

        <v-row class='row10'>
            <v-card class='center' elevation="0" height="88" width="610" color="cinza">
            <v-stepper class='mb-2' elevation="0" :value="stepper">
              <v-stepper-header class='cinza'>
                <v-stepper-step class='ma-2' step="1" color="vermelho">
                  Personalizar
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2" color="vermelho">
                Definir parâmetros de login
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3" color="vermelho">
                  Confirmar
              </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            </v-card>
        </v-row>

        <v-slide-y-transition>
        <div v-show="alerted" class='alert'>
          <v-row>
          <v-icon color="green" class='nomargin my-2 ml-2'>
            mdi-check-circle-outline
          </v-icon>
          <p class='alertxt'> A página foi salva com sucesso! </p>
          </v-row>
        </div>
      </v-slide-y-transition>

        <v-row v-if="stepper==1">
        <v-col class='sz1'>

            <v-card width="625px" color="cinza" elevation="0">
                <v-row class='row1'>
                  <div class='inputsize'>
                    <v-file-input v-model="configurationObject.logo" dense prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Logotipo" outlined/>
                  </div>
                    <div class='divider cblue'/>
                    <div class='tutorial sz2'>
                        O logotivo deve estar, no máximo, em 300x300px e ter no máximo 6Mb. são aceitos arquivos em .jpg e .png
                    </div>
                </v-row>
            </v-card>

            <v-card width="625px" color="cinza" elevation="0">
                <v-row class='row1'>
                  <div class='inputsize'>
                    <v-file-input v-model="configurationObject.apresentacao" dense prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Imagem de apresentação" outlined/>
                  </div>
                    <div class='divider cred'>
                    </div>
                    <div class='tutorial'>
                        A imagem de apresentação dos Kits deve estar em formato 683x768px e ter no máximo 15Mb. são aceitos arquivos em .jpg e .png
                    </div>
                </v-row>
            </v-card>

            <v-card width="625px" color="cinza" elevation="0">
                <v-row class='row1'>
                  <div class='inputsize'>
                    <v-text-field v-model="configurationObject.email" dense label="E-mail de contato" outlined/>
                  </div>
                    <div class='divider cgray'/>
                    <div class='tutorial'>
                        O colaborador entrará em contato com esse endereço de e-mail em caso de dúvidas
                    </div>
            </v-row>
            </v-card>

            <v-card width="625px" color="cinza" elevation="0">
                <v-row>
                    <div class='flinput'>
                    <v-textarea v-model="configurationObject.text" height="222" full-width class='flinput' label='Texto de apresentação' outlined/>
                    </div>
                    <div class='divider cdarkred'/>
                    <div class='tutorial mt-7'>
                        Esse texto servirá para orientar os colaboradores (máx. 215 caracteres). Se preferir use um texto padrão clicando no botão abaixo <br>
                    <v-btn text small color="azul" class='capitalize mt-2' @click='setText()'> Usar texto padrão</v-btn>
                    </div>
                </v-row>
            </v-card>
        </v-col>

        <v-col>
            <div >
                <v-img
                max-height='222'
                max-width='308'
                contain
                src='@/assets/img/clientportal/PageExample.png'/>
            </div>
        </v-col>
        </v-row>

        <v-col  v-if="stepper==2">
        <p class='txt2'> PARÂMETROS DE LOGIN </p>
        <p class='txt3'> Você poderá escolher por quais dados os colaboradores poderão acessar o QR Code. Dentre as opções, escolha uma chave principal (obrigatório) e um segundo campo como nome, telefone ou e-mail(opcional). O colaborador deverá preencher o(s) campos escolhidos para realizar o login</p>
        <v-row class='row23'>
        <div class="inputsize2">
         <v-select class="inputsize2" :items="array1" outlined v-model="configurationObject.mainKey"> </v-select>
        </div>
        <v-spacer/>
        <div class="inputsize2">
         <v-select class="inputsize2" :items="array2" outlined v-model="configurationObject.secondaryKey"> </v-select>
        </div>
        </v-row>
        </v-col>

        <v-col  v-if="stepper==3" style="position: relative">
        <p class='txt2'> PRÉ-VISUALIZAR PÁGINA </p>
        <p class='txt3'> Os colaboradores irão visualizar essa página ao solicitarem o QR Code. Caso queira fazer mais alguma alteração, volte e corrija pelo botão a baixo</p>
        <v-btn rounded outlined color='azul' class='capitalize' @click="edit" style="position: absolute; right: 24px; top: 24px;">
                    Editar
        </v-btn>
        <v-row>
          <v-card class='pre mb-10'>
            <MainColab v-bind:company="company" isExample="true" />
          </v-card>
        </v-row>
        </v-col>
                    <v-row>
                <v-spacer/>
                <v-btn v-if="this.stepper === 3" rounded outlined color='azul' class='capitalize' @click="edit">
                    Editar
                </v-btn>
                <v-btn :disabled='valid3 || !$store.state.clientPortal.login.permissao' rounded color='azul' class='capitalize' @click="next">
                  <p class='txtwhite my-3'>  {{stepper === 3 ? 'Confirmar':'Seguinte'}} <v-icon class='icon ml-2'> mdi-arrow-right </v-icon> </p>
                </v-btn>
            </v-row>
    </div>
</template>

<script>
import MainColab from '../../colab/ColabMain.vue'

export default {
  components: {
    MainColab
  },
  mounted () {
    this.initialize(true)
  },
  data: () => ({
    alerted: false,
    configurationObject: { logo: null, apresentacao: null, email: '', text: '', mainKey: 'matricula', secondaryKey: 'email', isNew: 'true' },
    stepper: 1,
    array1: ['email', 'telefone', 'matricula', 'cpf'],
    company: { email: null, mainKey: '', secondaryKey: '', presentationText: '', base64logo: '', base64: '' }
  }),
  methods: {
    edit () {
      this.stepper = 1
      this.initialize(false)
    },
    setText () {
      this.configurationObject.text = 'Caro colaborador, um Feliz Natal e um próspero 2022! Imprima ou tire um print de seu QRCode, apresente ao promotor no ponto de entrega e retire o seu Kit de Natal. Imagem meramente ilustrativa.'
    },
    async getBase64 (file) {
      let holder
      const reader = new FileReader()
      await reader.readAsDataURL(file)
      reader.onload = async function () {
        holder = reader.result
      }
      reader.onerror = function (error) {
        holder = error
      }
      // eslint-disable-next-line no-unmodified-loop-condition
      while (typeof holder === 'undefined') {
        await this.$functions.sleep(100)
      }
      return holder
    },
    async next () {
      if (this.stepper === 2) {
        Object.assign(this.company, this.configurationObject)
        this.company.presentationText = this.company.text
        if (this.configurationObject.logo) {
          this.company.base64logo = await this.getBase64(this.configurationObject.logo)
        } else {
          this.company.base64logo = this.$config.url_blobs + 'logo_empresa_' + this.$store.state.clientPortal.login.idCompany + '.png?dummy=' + Date.now()
        }
        if (this.configurationObject.apresentacao) {
          this.company.base64 = await this.getBase64(this.configurationObject.apresentacao)
        } else {
          this.company.base64 = this.$config.url_blobs + 'apresentacao_empresa_' + this.$store.state.clientPortal.login.idCompany + '.png?dummy=' + Date.now()
        }
      }
      this.stepper += 1
      if (this.stepper === 4) {
        this.configurationObject.logo = this.configurationObject.logo ? await this.getBase64(this.configurationObject.logo) : null
        this.configurationObject.apresentacao = this.configurationObject.apresentacao ? await this.getBase64(this.configurationObject.apresentacao) : null
        if (this.configurationObject.isNew) {
          await this.$functions.post(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/CustomPage`, this.configurationObject, this.$store.state.clientPortal.config)
        } else {
          await this.$functions.put(`/v0/company/${this.$store.state.clientPortal.login.idCompany}/CustomPage`, this.configurationObject, this.$store.state.clientPortal.config)
        }
        const obj = this.$store.state.clientPortal.login
        obj.personalization = true
        await this.$store.commit('updatelogin', obj)
        this.stepper = 3
        this.alerted = true
      }
    },
    async initialize (item) {
      let r = await this.$http.get(this.$config.url_backend + `/v0/company/${this.$store.state.clientPortal.login.idCompany}/CustomPage`, this.$store.state.clientPortal.config)
      r = r.data
      const isNew = (r.data.length === 0)
      Object.assign(this.configurationObject, r.data[0], { isNew })
      if (!this.configurationObject.isNew) { this.configurationObject.text = this.configurationObject.presentationText }
      if (this.configurationObject.mainKey === 'registration') { this.configurationObject.mainKey = 'matricula' }
      if (this.configurationObject.mainKey === 'telephone') { this.configurationObject.mainKey = 'telefone' }
      if (this.configurationObject.secondaryKey === 'telephone') { this.configurationObject.secondaryKey = 'telefone' }
      if (this.configurationObject.secondaryKey === 'registration') { this.configurationObject.secondaryKey = 'matricula' }
      if (item && !this.configurationObject.isNew) {
        await this.next()
        await this.next()
      }
    }
  },
  computed: {
    valid3 () {
      if (this.stepper === 1) {
        if ((this.configurationObject.email && this.configurationObject.logo && this.configurationObject.text) || !this.configurationObject.isNew) {
          return false
        }
        return true
      } else if (this.stepper === 2) {
        if (this.configurationObject.mainKey && this.configurationObject.secondaryKey) {
          return false
        }
        return true
      }
      return false
    },
    array2 () {
      const holder = ['email', 'telefone', 'matricula', 'cpf', 'nenhum']
      const index = holder.indexOf(this.configurationObject.mainKey)
      if (index !== -1) {
        holder.splice(index, 1)
      }
      return holder
    }
  }
}
</script>

<style scoped>
.alertxt{
  padding-top: 12px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #3b863e;
letter-spacing: 0.4px;
}
.alert{
  background-color: #c8e6c9c7;
  border: 1px solid #4CAF50;
  border-radius: 12px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: calc(100% - 64px);
  margin-top: 13px;
  margin-bottom: 16px;
}
.row23{
    margin-left: 0px;
    margin-top: 21px;
    max-width: 610px;
    max-height: 79px;
}
.txt2{
font-weight: 510;
font-size: 14px;
line-height: 266%;
letter-spacing: 1px;
color: rgba(0, 0, 0, 0.87);
margin-bottom: 16px;
}
.txt3{
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: #616161;
}
.icon{
    font-size: 18px;
}
.row10{
    width: 100%;
}
.sz1{
    width: 635px !important
}
.cdarkred{
    background: #662B83;
}
.cred{
    background: #d2232a;
}
.cblue{
    background: #134B97;
}
.cgray{
    background: #9E9E9E;
}
.inputsize{
    width: 282px !important;
    position: relative;
    left: 12px;
    top: 6px;
    margin-right: 32px;
}

.inputsize2{
    width: 250px !important;
}

.flinput{
    height: 222px !important;
    width: 282px !important;
    position: relative;
    left: 6px;
    margin-right: 32px;
}

.row1{
    margin-bottom: 36px;
    padding: 0px !important;
    height: 53px !important;
}
.col1{
    height: 53px !important;
    padding: 0px !important;
    margin-right: 16px;
}

.center{
    margin: auto;
}

.divider{
    align-self: center;
    position: relative;
    height: 53px;
    width: 3px;
    bottom: 6px;
    margin-right: 32px;
}

.explanation{
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
letter-spacing: 0.15px;
color: #616161;
width: 100%;
margin-bottom: 16px;
}

.canva{
    position: relative;
}

.tutorial{
    align-self: center;
    width: 282px;
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    bottom: 6px;
}
.minsize{
  min-width: 530px;
}
.pre{
  height: 648px;
}

@media screen and (max-width: 904px) {
  .pre{
    min-height: 874px !important;
  }
}
</style>
