/* eslint-disable no-extra-boolean-cast */
<template>
  <div class='fullwidth'>
    <v-dialog v-model="chooseDialog.show" height="400px" width="600px">
      <v-card>
        <v-card-title>
          Escolha qual entrega
        </v-card-title>
        <v-card-text>
          Achamos mais de um login com os mesmo dados nessa empresa, escolha de qual lista você deseja ver o qrcode:
        </v-card-text>
        <v-select
          style="width: 60%; margin-left: 24px; margin-bottom: -32px;"
          :items="lists"
          v-model="chooseDialog.select"
          label="Lista & Nome"
          clearable
          :menu-props="{offsetY: true}"
          color="vermelho"
        />

        <v-card-actions>
          <v-spacer/>
          <v-btn :color="company.primaryColor" class="rounded-xl butao" style="color: #FAFAFA; text-transform: capitalize;" @click="Continue()">
            Continuar
          </v-btn>
        </v-card-actions>
        <!-- @input="filter"
          v-model="filter.accessType" -->

      </v-card>
    </v-dialog>

    <vue-custom-scrollbar class="scrollareacolab nomargin"  :settings="settings">
      <v-row class='ma-0'>
        <!-- <div id="capture"> -->
        <!-- <v-img
          v-show="!simple"
          class='sized sizedbanner'
          :src='this.company.base64'
        /> -->
        <div class='f50'>
          <v-img
            class='smollimg mx-auto'
            height="100"
            :style="configs.smallimg"
            contain
            :src='this.company.base64logo'
          />
          <div class='size30 mt-0'>

            <v-slide-y-transition>
              <div v-show="wait">
                <v-card elevation="0" >
                  <v-card-title class='CoHead mt-0 pl-0'>
                    Conferindo os dados
                    <v-progress-circular class='ml-5' :size="20" :color="company.primaryColor" indeterminate > </v-progress-circular>
                  </v-card-title>
                  <v-card-text class='mx-0 my-0 pl-0'>
                    <p class=' mb-3'> Aguarde um segundo </p>
                  </v-card-text>
                </v-card>
              </div>
            </v-slide-y-transition>

            <div v-if="buya && !wait" class='mb-2' v-show="!homeDeliveryForm.show">

              <h1 class='CoHead' :style="style.colabTitle"> QR Code para retirada do Kit </h1>
              <p class='colabtxt'> {{company.presentationText}} </p>

              <v-slide-y-transition>
                <div v-show="warning" class='alert'>
                  <v-row>
                    <v-icon color="#F44336" class='nomargin my-2 ml-3'>
                      mdi-close-circle-outline
                    </v-icon>
                    <div class='errou'>
                    <p class='alertxt mr-3'> A credencial inserida está incorreta. Por favor confira e tente novamente. </p>
                    </div>
                  </v-row>
                </div>
              </v-slide-y-transition>

              <div class='txtfield'>
                <v-form
                  ref='form'
                  v-model='valid'
                  lazy-validation
                >
                    <v-text-field :type="company.mainKey === 'CPF' ? 'password': 'text'" class='capitalize' :rules='nameRules' outlined :label="company.mainKey" height="40" v-model="fieldnome"> </v-text-field>
                    <v-text-field :type="company.secondaryKey === 'CPF' ? 'password': 'text'" v-if="company.secondaryKey !== 'nenhum'" class='capitalize' :rules='nameRules' outlined :label="company.secondaryKey" height="40" v-model="fieldemail"> </v-text-field>
                </v-form>
              </div>
              <v-btn :color="company.primaryColor" :disabled='(!valid || !!isExample) || warning' class="rounded-xl butao" @mouseover='validate()' @click="GeraQRCode()"> <p class='txtwhite capitalize my-1'> Gerar QR Code </p></v-btn>
            </div>
            <homeDeliveryForm  v-show="homeDeliveryForm.show" v-bind:homeDeliveryForm="homeDeliveryForm" @done="relogin"></homeDeliveryForm>
          </div>

          <v-slide-x-transition>
            <div class='size30 mb-2' v-show="!buya">
              <p class='name CoHead text-center pa-0 ma-0'> {{login.name || 'Error: Data Not Found'}} </p>
              <p class='lisst text-center pa-0 ma-0'> Lista: {{login.nameDelivery || 'Error: Data Not Found'}} </p>
              <v-card height="250" width="250" class="mx-auto relative" elevation="0">
                <v-progress-circular class='loading' :size="50" :color="company.primaryColor" indeterminate > </v-progress-circular>
                <v-img :src="imagemQRCode" class='mx-auto' height="250" width="250" download/>
              </v-card>
              <div class='mt-n1'>
                <h1 :style="style.color" class='colab2t CoHead text-center mx-8'> Prontinho, agora é só baixar seu QR Code ou tirar um print dessa tela. </h1>
                <p class='colabtxt text-center'> Não se esqueça de apresentar esse QR Code ao promotor quando for retirar o seu Kit BRF. </p>
                <v-card elevation="0" class='mx-auto' width="153" height="56">
                  <a download="QRcode.png" :href="imagemQRCode">
                  <v-btn :color="company.primaryColor" class="rounded-xl butao"> <p class='txtwhite capitalize my-1 btn linkNormalization'> Baixar QR Code</p> </v-btn>
                  </a>
                </v-card>
              </div>
            </div>
          </v-slide-x-transition>
        </div>
        <!-- </div> -->
        <v-img
        v-show="!simple"
        class='sizedOff'
          :style="configs.bigimg"
          :src='this.company.base64'
        />
      </v-row>
      <UniqueFooter v-bind:email="company.email" class='UniqueFooter'/>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import UniqueFooter from '../shared/NewFooter.vue'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import homeDeliveryForm from './homeDeliveryForm.vue'

export default {
  computed: {
    lists () {
      const array = []
      for (let index = 0; index < this.allLogins.length; index++) {
        const element = this.allLogins[index]
        array.push(element.nameDelivery + ' & ' + element.name)
      }

      return array
    },

    style () {
      const str = this.company.primaryColor ? this.company.primaryColor.trim() : '#134B97'
      let holder = {}
      if (this.isExample) {
        holder = {
          bigimg: '{; margin-left: 0px;  width: 40%; height: 648px; }',
          smallimg: '{; max-height: 61px; max-width: 117px; margin-top: 28px; margin-bottom: 16px; }',
          colabTitle: '{; font-size: 24px;line-height: 123.5%;letter-spacing: 0.25px;color: ' + str + ';flex: none;order: 0;flex-grow: 0;margin: 0px 0px;}'
        }
      } else {
        holder = {
          bigimg: '{; margin-left: -38px;  width: 50%; height: 538px; }',
          smallimg: '{; max-height: 103px; max-width: 194px; margin-top: 28px; margin-bottom: 32px; }',
          colabTitle: '{; font-size: 34px; line-height: 123.5%;letter-spacing: 0.45px;color: ' + str + ';flex: none;order: 0;flex-grow: 0;margin: 0px 0px 16px 0px;}'
        }
      }
      holder = { ...holder, color: '{;color: ' + str + ';}' }
      return holder
    }
  },
  created () {
    if (this.isExample) {
      this.configs = {
        bigimg: '{ margin-left: 0px;  width: 40%; height: 648px; }',
        smallimg: '{ max-height: 61px; max-width: 117px; margin-top: 28px; margin-bottom: 16px; }',
        colabTitle: '.colabTitle{font-size: 24px;line-height: 123.5%;letter-spacing: 0.25px;color: #134B97;flex: none;order: 0;flex-grow: 0;margin: 0px 0px;}'
      }
    }
  },
  props: ['company', 'isExample', 'id'],
  components: {
    vueCustomScrollbar,
    UniqueFooter,
    homeDeliveryForm
  },
  data: () => ({
    simple: false,
    chooseDialog: { show: false, select: null },
    allLogins: [],
    homeDeliveryForm: {},
    data: [],
    wait: false,
    configs: {
      bigimg: '{ margin-left: -38px;  width: 50%; height: calc(100vh - 90px); }',
      smallimg: '{ max-height: 123px; max-width: 234px; margin-top: 28px; margin-bottom: 32px; }',
      colabTitle: '.colabTitle{font-size: 38px;line-height: 123.5%;letter-spacing: 0.45px;color: #134B97;flex: none;order: 0;flex-grow: 0;margin: 0px 0px;}'
    },
    warning: false,
    fieldnome: '',
    fieldemail: '',
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false
    },
    valid: true,
    imagemQRCode: '',
    buya: true,
    login: {},
    nameRules: [
      v => !!v || 'Campo Obrigatório'
    ]
  }),
  async mounted () {
    const { firstField, secondField, simple } = this.$route.query
    if (firstField) {
      this.fieldnome = firstField
      this.fieldemail = secondField
      // eslint-disable-next-line no-unused-vars
      while (!this.company.id) {
        await this.$functions.sleep(100)
      }
      this.GeraQRCode()
    }

    if (simple) {
      this.simple = true
    }
  },
  methods: {
    async Continue () {
      for (let index = 0; index < this.allLogins.length; index++) {
        const element = this.allLogins[index]
        if (element.nameDelivery + ' & ' + element.name === this.chooseDialog.select) {
          this.choose(element)
        }
      }
    },
    async relogin (obj) {
      try {
        const obj1 = {}
        obj.forEach(element => {
          obj1[element.name] = element.model
        })
        this.wait = true
        const holder = { ...obj1, username: this.fieldnome, password: this.fieldemail, extraInfo: true, idColab: this.login.id }
        await this.$http.post(this.$config.url_backend + '/v0/company/' + this.company.id + '/collaborators/login', holder)
        this.showQrCode()
        this.homeDeliveryForm.show = false
      } catch (err) {
        this.wait = false
        this.warning = true
        await this.$functions.sleep(3000)
        this.warning = false
      }
    },
    validate () {
      this.$refs.form.validate()
    },
    async choose (obj) {
      this.login = obj
      this.data = obj.tokenUnico
      if (!obj.idHome && this.company.isHomeDelivery) {
        this.chooseDialog = { show: false, select: null }
        this.homeDeliveryForm.show = true
      } else {
        this.chooseDialog = { show: false, select: null }
        this.showQrCode()
      }
    },

    async showQrCode () {
      this.wait = true
      await this.$http.post(this.$config.url_QRCodeAPI, { content: this.data }).then((res) => (this.imagemQRCode = res.data.image))
      this.wait = false
      this.buya = !this.buya
    },
    async GeraQRCode () {
      this.wait = true
      const ph = this.secondaryKey !== 'cpf' ? this.fieldemail : this.fieldemail.replaceAll('.', '').replaceAll('-', '')
      const holder = { username: this.fieldnome, password: ph }
      await this.$http.post(this.$config.url_backend + '/v0/company/' + this.company.id + '/collaborators/login', holder)
        .then(async (res) => {
          this.wait = false
          if (res.data.array.length === 1) {
            this.choose(res.data.array[0])
          } else {
            this.allLogins = [...res.data.array]
            this.chooseDialog.show = true
          }
        }
        )
        .catch(async () => {
          this.wait = false
          this.warning = true
          await this.$functions.sleep(3000)
          this.warning = false
        })
    }
  }
}
</script>

<style scoped>
.f50{
  min-height: 400px;
  height: calc(100vh - 90px);
  width: 50%;
}
.fullwidth{
}

.center{
  justify-content: center;
}
.UniqueFooter{
  bottom: 0px;
}
   .linkNormalization{
      text-decoration: none !important;
   }
.scrollareacolab{
  min-height: calc(100vh);
    max-height: calc(100vh);
}
.alertxt{
  padding-top: 16px;
  padding-left: 20px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 166%;
color: #F44336;
letter-spacing: 0.4px;
}
.alert{
  background-color: #FEECEB;
  border: 2px solid #F44336;
  border-radius: 12px;
  left: 32px;
  top: 0px;
  z-index: 99;
  padding-top: 13px;
  padding-left: 18px;
  padding-bottom: 13px;
  width: 474px;
  margin-top: 32px;
  margin-bottom: 16px;
}
.txtfield{
    margin-top: 32px;
    width: 252px;
    margin-bottom: 18px;
}
.butao{
    margin-top: 0px;
}
.size30{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 90px - 156px);
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  margin-top: 8%;
}
.colab2t{
    font-size: 20px;
line-height: 123.5%;
letter-spacing: 0.25px;
margin: 0px 0px;
}
.colabtxt{
    font-size: 12px;
line-height: 166%;
letter-spacing: 0.4px;
color: rgba(0, 0, 0, 0.87);
margin: 0px 0px;
}
.name{
font-size: 16px;
line-height: 175%;
letter-spacing: 2px;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.87);
}
.lista{
font-size: 16px;
line-height: 150%;
letter-spacing: 0.15px;
color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 700px) {
  .f50{
    width: 90%;
  }
  .sizedOff {
    display: none !important;
  }
  .sized {
    display: flex !important;
  }
  .size30 {
    width: 100% !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .smollimg {
    margin-left: calc(50vw - 117px) !important;
  }
}
@media screen and (max-width: 1110px) {
  .size30{
    padding-left: 42px;
    padding-right: 42px;
    width: 80%;
  }
}
@media screen and (max-width: 940px) {
  .size30{
    padding-left: 32px;
     padding-right: 32px;
  }
}
.sized{
  display: none
}
.sizedbanner{
  width: 100vw;
}
.relative{
  position: relative;
}

.loading{
  left: 100px;
  top: 100px;
  position: absolute;
}
</style>
