/* eslint-disable no-extra-boolean-cast */
<template>
  <div class='total pa-4'>

    <v-row>
      <v-spacer/>
      <v-btn color="azul" class='mr-8 mt-4 mb-6 capitalize' @click='initialize' outlined rounded> <v-icon class='mr-2'> mdi-refresh </v-icon> Atualizar </v-btn>
    </v-row>
    <v-row v-if='!waitdialog' class='divzona ma-0'>
      <Chart1 v-bind:data="mixedData.chart1" v-bind:isClient="isClient" class='mb-5 mr-5'/>
      <Chart2 v-bind:data="mixedData.chart2" v-bind:isClient="isClient" class='mb-5 mr-5'/>
      <Chart3 v-bind:data="mixedData.chart3" v-bind:isClient="isClient" class='mb-5 mr-5'/>
      <Chart4 v-bind:data="mixedData.chart4" v-bind:isClient="isClient" class='mb-5 mr-5'/>
      <Chart5 v-bind:data="mixedData.chart5" v-bind:isClient="isClient" class='mb-5 mr-5'/>
      <Chart6 v-bind:data="mixedData.chart6" v-if="isClient" class='mb-5 mr-5'/>
      <Chart7 v-bind:data="mixedData.chart7" v-if="isClient" class='mb-5 mr-5'/>
    </v-row>
  </div>
</template>

<script>
import Chart1 from './ChartOne.vue'
import Chart2 from './ChartTwo.vue'
import Chart3 from './ChartThree.vue'
import Chart4 from './ChartFour.vue'
import Chart5 from './ChartFive.vue'
import Chart6 from './ChartSix.vue'
import Chart7 from './ChartSeven.vue'

export default {
  components: {
    Chart1,
    Chart2,
    Chart5,
    Chart4,
    Chart3,
    Chart6,
    Chart7
  },
  props: ['isClient'],
  data: () => ({
    waitdialog: false,
    settings: {
      suppressScrollY: false,
      suppressScrollX: false,
      wheelPropagation: false
    },
    eko: '150px',
    day: 1000 * 60 * 60 * 24,
    week: 1000 * 60 * 60 * 24 * 7,
    mixedData: { chart1: { chart: { Todas: { } } }, chart2: { chart: { Todas: [] } }, chart3: { chart: { Todas: { Semana: [] } } }, chart4: { chart: { Total: 0 } }, chart5: { chart: {} }, chart7: { chart: [], download: [] } },
    blue: '#134B97',
    blue1rgba: 'rgba(5,94,169,1)',
    bluergba: 'rgba(19,75,151,1)',
    lightbluerba: 'rgba(198,206,226,1)',
    grey1rgba: 'rgba(189,189,189,1)',
    red: '#d2232a',
    redrgba: 'rgba(210,35,42,1)',
    tamanho: '600px'
  }),
  methods: {
    async test () {
      this.data.donutData = { Feitos: 572, Faltantes: 688 }
    },
    async generateChart1Data (input) {
      let holder = { download: [], chart: { Todas: { Feitos: 0, Faltantes: 0 } } }
      holder = { download: [], chart: { Todas: { Feitos: 0, Faltantes: 0 } } }
      await input.forEach(element => {
        const obj = { Feitos: 0, Faltantes: 0 }
        element.lista.forEach(i => {
          holder.download.push(i)
          if (i.isRead) {
            obj.Feitos = obj.Feitos + 1
            holder.chart.Todas.Feitos = holder.chart.Todas.Feitos + 1
          } else {
            obj.Faltantes = obj.Faltantes + 1
            holder.chart.Todas.Faltantes = holder.chart.Todas.Faltantes + 1
          }
        }
        )
        holder.chart[element.nome] = obj
      })

      // for (const [key, value] of Object.entries(holder.chart)) {
      //   if (key !== 'Todas') { holder.download.push({ ...value, lista: key }) }
      // }
      return holder
    },
    async generateChart2Data (input, date) {
      const models = { Total: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }], Dia: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }], Semana: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }] }
      const expor = { chart: { Todas: models }, download: [] }

      await input.forEach(element => {
        const holder = { Total: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }], Dia: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }], Semana: [{ data: { Gerados: 0 } }, { data: { Cadastrados: 0 } }] }
        element.lista.forEach(i => {
          expor.download.push({ cpf: i.cpf, matricula: i.registration, lido: i.isRead ? 'Sim' : 'Não', gerado: i.isGenerated ? 'Sim' : 'Não', lista: element.nome })
          expor.chart.Todas.Total[1].data.Cadastrados += 1
          holder.Total[1].data.Cadastrados += 1
          if (i.isGenerated) {
            expor.chart.Todas.Total[0].data.Gerados += 1
            holder.Total[0].data.Gerados += 1
          }
          if (i.eTime + this.week > date) {
            if (i.isGenerated) {
              expor.chart.Todas.Semana[0].data.Gerados += 1
              holder.Semana[0].data.Gerados += 1
            } else {
              expor.chart.Todas.Semana[1].data.Cadastrados += 1
              holder.Semana[1].data.Cadastrados += 1
            }
          }
          if (i.eTime + this.day > date) {
            if (i.isGenerated) {
              expor.chart.Todas.Dia[0].data.Gerados += 1
              holder.Dia[0].data.Gerados += 1
            } else {
              expor.chart.Todas.Dia[1].data.Cadastrados += 1
              holder.Dia[1].data.Cadastrados += 1
            }
          }
        })
        expor.chart[element.nome] = JSON.parse(JSON.stringify(holder))
      })
      return expor
    },
    async generateChart3Data (input, date) {
      const expor = { chart: { Todas: { Semana: [], Mes: [], Dia: [] } }, download: [] }
      date = await this.getTime()
      let i = 0
      date = date.split('-')
      let datehour = date[2].slice(-8)
      datehour = datehour.split(':')
      date[2] = date[2].substring(0, 2)
      await date.forEach(element => {
        element = parseInt(element)
      })
      const dated = date
      dated[2] = dated[2].replace(' ', '')
      expor.chart.Todas.Semana.push([dated[2] + '/' + dated[1] + '/' + dated[0], 0])
      expor.chart.Todas.Mes.push([dated[2] + '/' + dated[1] + '/' + dated[0], 0])
      while (i < 30) {
        if (dated[2] > 1) {
          dated[2] = dated[2] - 1
        } else if (dated[1] > 1) {
          dated[1] = dated[1] - 1
          dated[2] = 31
        } else {
          dated[0] = dated[0] - 1
          dated[1] = 12
          dated[2] = 31
        }

        if (i < 6) {
          expor.chart.Todas.Semana.push([dated[2] + '/' + dated[1] + '/' + dated[0], 0])
        }
        expor.chart.Todas.Mes.push([dated[2] + '/' + dated[1] + '/' + dated[0], 0])
        i += 1
      }

      let f = 0
      while (f < 24) {
        let variable = parseInt(datehour[0]) - f
        let day = expor.chart.Todas.Mes[0][0]
        if (variable < 0) {
          variable = variable + 24
          day = expor.chart.Todas.Mes[1][0]
        }
        expor.chart.Todas.Dia.push([day + ' ' + variable, 0])
        f += 1
      }

      const base = JSON.parse(JSON.stringify({ Mes: expor.chart.Todas.Mes, Semana: expor.chart.Todas.Semana }))
      await input.forEach(element => {
        expor.chart[element.nome] = JSON.parse(JSON.stringify(base))
        element.lista.forEach(i => {
          if (i.isRead) {
            const array = i.updatedAt.split(' ')
            const obj = { cpf: i.cpf, lista: element.nome, matricula: i.registration, data: array[0], hora: array[1] }
            expor.download.push(obj)
            let x = 0
            while (x < 31) {
              if (expor.chart.Todas.Mes[x][0] === i.updatedAt.substring(0, 10).replace(' ', '')) {
                expor.chart[element.nome].Mes[x][1] = expor.chart[element.nome].Mes[x][1] + 1
                expor.chart.Todas.Mes[x][1] = expor.chart.Todas.Mes[x][1] + 1
                if (x < 7) {
                  expor.chart[element.nome].Semana[x][1] = expor.chart[element.nome].Semana[x][1] + 1
                  expor.chart.Todas.Semana[x][1] = expor.chart.Todas.Semana[x][1] + 1
                  if (x < 2) {
                    expor.chart.Todas.Dia.forEach(elem => {
                      if (elem[0] === i.updatedAt.substring(0, 12) && true) {
                        elem[1] = elem[1] + 1
                      }
                    })
                  }
                }
              }
              x += 1
            }
          }
        })
      })
      return expor
    },
    async generateChart4Data (input) {
      const holder = { download: [], chart: { Total: 0 } }
      await input.forEach(element => {
        let i = 0
        holder.chart[element.nome] = 0
        element.lista.forEach(x => {
          i += 1
          holder.chart[element.nome] += 1
          holder.chart.Total += 1
        })
        const obj = { nome: element.nome, Colaboradores: i }
        holder.download.push(obj)
      })
      holder.download.push({ nome: 'Total', Colaboradores: holder.chart.Total })
      return holder
    },
    async generateChart5Data (input, date) {
      const holder = { download: [{}], chart: { Total: [{ name: 'Lidos', data: {} }, { name: 'Não Lidos', data: {} }], Semana: [{ name: 'Lidos', data: {} }, { name: 'Não Lidos', data: {} }], Dia: [{ name: 'Lidos', data: {} }, { name: 'Não Lidos', data: {} }] } }
      holder.download[0] = { nome: 'Total', Total: 0, Lidos: 0, LidosSemana: 0, LidosDia: 0 }
      holder.total = 0
      await input.forEach(element => {
        const obj = { nome: element.nome, Total: 0, Lidos: 0, LidosSemana: 0, LidosDia: 0 }
        holder.chart.Total[0].data[element.nome] = 0
        holder.chart.Total[1].data[element.nome] = 0
        holder.chart.Semana[0].data[element.nome] = 0
        holder.chart.Dia[0].data[element.nome] = 0
        element.lista.forEach(i => {
          obj.Total += 1
          holder.download[0].Total += 1
          if (i.isRead) {
            holder.total += 1
            holder.chart.Total[0].data[element.nome] += 1
            holder.download[0].Lidos += 1
            obj.Lidos += 1
            if (i.eTime + this.week > date) {
              holder.chart.Semana[0].data[element.nome] += 1
              obj.LidosSemana += 1
              holder.download[0].LidosSemana += 1
              if (i.eTime + this.day > date) {
                holder.chart.Dia[0].data[element.nome] += 1
                obj.LidosDia += 1
                holder.download[0].LidosDia += 1
              }
            }
          } else {
            holder.chart.Total[1].data[element.nome] += 1
          }
        })
        holder.chart.Semana[1].data[element.nome] = holder.chart.Total[1].data[element.nome]
        holder.chart.Dia[1].data[element.nome] = holder.chart.Total[1].data[element.nome]
        holder.download.push(obj)
      })
      return holder
    },
    async generateChart6Data (input) {
      const array = []
      input.forEach(element => {
        const obj = { name: element.nome, colaboradores: 0, Gerados: 0, Lidos: 0 }
        element.lista.forEach(elemento => {
          obj.colaboradores = obj.colaboradores + 1
          if (elemento.isRead) { obj.Lidos = obj.Lidos + 1 }
          if (elemento.isGenerated) { obj.Gerados = obj.Gerados + 1 }
        })
        array.push(obj)
      })
      return array
    },
    async generateChart7Data (input, date) {
      const array = []
      const download = []
      let timing = await this.getTime2()
      timing = timing.split(' ')
      const errorArray = {}
      input.forEach(element => {
        // element.lista.forEach(colaborador => {
        //   let flip = true
        //   if (colaborador.readBy !== null) {
        //     element.listaPromotor.forEach(promotor => {
        //       if (colaborador.readBy === promotor.id) {
        //         flip = false
        //       }
        //     })
        //     if (flip) {
        //       if (typeof errorArray[colaborador.readBy] === 'undefined') {
        //         errorArray[colaborador.readBy] = []
        //       }
        //       errorArray[colaborador.readBy].push(colaborador.registration)
        //     }
        //   }
        // })
        element.lista.forEach(colaborador => {
          let flip = true
          if (colaborador.readBy !== null) {
            element.listaPromotor.forEach(promotor => {
              if (colaborador.readBy === promotor.id) {
                flip = false
              }
            })
            if (flip) {
              if (typeof errorArray[colaborador.readBy] === 'undefined') {
                errorArray[colaborador.readBy] = []
              }
              errorArray[colaborador.readBy].push(colaborador.registration)
            }
          }
        })
        element.listaPromotor.forEach(promotor => {
          const obj = { nomelista: element.nome, id: promotor.id, nome: promotor.nome, lidos: 0, lidos24: 0 }
          element.lista.forEach(colaborador => {
            if (colaborador.readBy === obj.id) {
              const datecolab = colaborador.updatedAt.split(' ')
              download.push({ Cpf: colaborador.cpf, Matricula: colaborador.registration, LidoPor: obj.nome, Quando: datecolab[0], Horário: datecolab[1] })
              obj.lidos = obj.lidos + 1
              if (timing[0] === datecolab[0]) {
                obj.lidos24 += 1
              }
            }
          })

          array.push(obj)
        })
      })

      return { chart: array, download }
    },
    async getCurrentTime () {
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time
      const dateObject = new Date(dateTime)
      const eTime = dateObject.getTime()
      return eTime
    },
    async getTime () {
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time
      return dateTime
    },
    async getTime2 () {
      const today = new Date()
      const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = date + ' ' + time
      return dateTime
    },
    async getRightDate (input) {
      await input.forEach(async element => {
        element.lista.forEach(async i => {
          let date = i.updatedAt
          const splitted1 = date.split(' ')
          let splitted = splitted1[1].split(':')
          const splittedDate = date.split('/')

          if (parseInt(splitted[0]) - 3 < 0) {
            if (splittedDate[0] === 1) {
              if (splittedDate[1] === 1) {
                splittedDate[2] = parseInt(splittedDate[2]) - 1
                splittedDate[1] = 12
                splittedDate[0] = 31
                splitted[0] = parseInt(splitted[0]) + 21
              } else {
                splittedDate[1] = parseInt(splittedDate[1]) - 1
                splittedDate[0] = 31
                splitted[0] = parseInt(splitted[0]) + 21
              }
            } else {
              splittedDate[0] = parseInt(splittedDate[0]) - 1
              splitted[0] = parseInt(splitted[0]) + 21
            }
          } else {
            splitted[0] = parseInt(splitted[0]) - 3
          }

          splitted = splitted.join(':')
          i.updatedAt = ([splittedDate[0], splittedDate[1], splittedDate[2]].join('/')) + ' ' + splitted
          date = [splittedDate[1], splittedDate[0], splittedDate[2]].join('/')
          const dateObject = new Date(date)
          i.eTime = dateObject.getTime()
        })
      })
      return true
    },
    async initialize () {
      this.$store.commit('dialogDetail', { type: 'Wait', extra: this.$store.state.clientPortal.dialogDetail.range === 'Delivery' })
      this.$store.commit('dialogEdit', { type: 'wait' })
      let holder
      if (this.isClient === 'true') {
        const h = await this.$functions.get('/v0/company/' + this.$store.state.clientPortal.login.idCompany + '/dashboard', this.$store.state.clientPortal.config)
        holder = h.data
      } else {
        holder = await this.$functions.get('/v0/company/dashboard', { headers: { Access_Token: this.msal.idToken } })
        const array = []
        await this.$store.state.adminPortal.ClientData.body.forEach(element => {
          let flip = false
          const obj = { nome: element.name, id: element.idUser, idCompany: element.id, lista: [] }
          holder.data.forEach(elemento => {
            if (element.id === elemento.idCompany) {
              elemento.lista.forEach(vamo => obj.lista.push(vamo))
              flip = true
            }
          })
          if (flip) { array.push(obj) }
        })
        holder = array
      }
      await this.getRightDate(holder)
      const currentETime = await this.getCurrentTime()
      this.mixedData.chart1 = await this.generateChart1Data(holder)
      this.mixedData.chart2 = await this.generateChart2Data(holder, currentETime)
      this.mixedData.chart3 = await this.generateChart3Data(holder, currentETime)
      this.mixedData.chart4 = await this.generateChart4Data(holder)
      this.mixedData.chart5 = await this.generateChart5Data(holder, currentETime)
      if (this.isClient === 'true') {
        this.mixedData.chart6 = await this.generateChart6Data(holder)
        this.mixedData.chart7 = await this.generateChart7Data(holder, currentETime)
      }
      this.$store.commit('dialogDetail', {})
      this.$store.commit('dialogEdit', {})
    }
  },
  async mounted () {
    this.initialize()
  }
}
</script>

<style scoped>
.total{
  min-width: 550px;
}
</style>
