<template>
  <v-card>
    <DownloadIcon v-bind:data="data.download"/>

    <v-card-title class='CoHead'>
      Perfomance por promotor
    </v-card-title>

    <v-data-table
      mobile-breakpoint="10"
      class='tabe'
      calculate-widths:true
      :headers='headers'
      :items='data.chart'
      sort-by='name'
      :footer-props="{itemsPerPageText: 'Linhas por Página', itemsPerPageOptions:[4,8,12]}"
    >
      <template v-slot:item.lidos24="{ item }">
           <v-card elevation="0" width="47" height="32" rounded="pill" class='text-center first'> <p class='txtred pt-1'> {{ item.lidos24 }} </p> </v-card>
        </template>
        <template v-slot:item.lidos="{ item }">
           <v-card elevation="0" width="47" height="32" rounded="pill" class='text-center second'> <p class='txtblue pt-1'> {{ item.lidos }} </p> </v-card>
        </template>
        <template v-slot:no-data>

        </template>
    </v-data-table>
  </v-card>
</template>

<script>

import DownloadIcon from './DownloadIcon.vue'

export default {
  components: { DownloadIcon },
  props: ['data'],
  data: () => ({
    headers: [
      { text: 'Colaborador', sortable: false, value: 'nome', width: '25%', align: 'start' },
      { text: 'Lista de entrega', sortable: false, value: 'nomelista', width: '25%', align: 'start' },
      { text: 'Leituras no Dia', sortable: false, value: 'lidos24', width: '25%', align: 'start' },
      { text: 'Leiturar totais', sortable: false, value: 'lidos', width: '25%', align: 'start' }
    ]
  })
}
</script>

<style scoped>
.tabe{
    width: 1060px;
}
@media screen and (max-width: 1359px) {
.tabe{
    width: calc(100vw - 32px - 262px);
}
}
.first{
    background-color: #D2232A25;
    }
.second{
    background-color: #134a9721;
}
@media screen and (max-width: 1100px) {
.tabe{
  width: 475px;
}
}
</style>
