<template>
<div :style="{ backgroundColor: errorIdChanges.bgc }" class='tela' >
    <div>
    <v-card width="570" height="229" class='card pr-3'>
    <p class='title29 CoHead'> {{errorIdChanges.routerheader}} </p>
    <p class='text29 ml-0 mt-3' > {{errorIdChanges.routertext}} </p>
     <v-row> <v-spacer/> <v-btn :to='errorIdChanges.btnlink' class='btn rounded-xl' color="azul" > <v-icon color="branco" class='icon'> {{errorIdChanges.routericon}} </v-icon> <p class='ml-2 capitalize my-1 btntxt'> {{errorIdChanges.btntxt}} </p> </v-btn> </v-row>
    </v-card>
    </div>
    <div :hidden='!($route.params.id === "401")'>
<v-img
                  max-height='calc(75vh)'
                  max-width='calc(90vw)'
                    contain
                  class='standing'
                  src="@/assets/img/errors/401/people.png"
               />
    </div>
    <div :hidden='!($route.params.id === "404")'>
<v-img
                max-height='calc(75vh)'
                  max-width='calc(90vw)'
                    contain
                  class='standing'
                  src="@/assets/img/errors/404/people.png"
               />
    </div>
    <!-- <div hidden>
<v-img
                  max-height='calc(70vh)'
                  max-width='calc(90vw)'
                    contains
                  class='standing'
                  src="@/assets/img/errors/511/people.png"
               />
    </div> -->
</div>
</template>

<script>

export default ({
  data: () => ({
    errorIdChanges: { btnlink: '/', btntxt: 'Suporte', bgc: '#134a9788', routericon: 'mdi-help-circle', routerheader: 'ERROR - OBJETO DE ERRO SEM HEADER', routertext: 'Em caso de dúvidas, entre em contato com o suporte pelo botão abaixo ou retorne' },
    pages: [
      { id: '401', routerheader: 'Você não está autorizado', bgc: '#D2232A86' },
      { id: '511', routerheader: 'Ops! Você não está conectado', routertext: 'Clique no botão abaixo para fazer seu login', bgc: '#055EA9', routericon: 'mdi-account', btntxt: 'Login', btnlink: '/brf' },
      { id: '404', routericon: 'mdi-help-circle', routerheader: 'Página não encontrada' }
    ],
    default: { btnlink: '/', btntxt: 'Suporte', bgc: '#134a9788', routericon: 'mdi-help-circle', routerheader: 'Essa página não foi encontrada', routertext: 'Em caso de dúvidas, entre em contato com o suporte pelo botão abaixo ou retorne' }
  }),
  async created () {
    await this.setRouteIdDetails()
  },
  methods: {
    setRouteIdDetails () {
      let flip = true
      this.pages.forEach(element => {
        if (this.$route.params.id === element.id) {
          Object.assign(this.errorIdChanges, element)
          flip = false
        }
      })
      if (flip) {
        const obj = { id: this.$route.params.id, info: '' }
        if (this.$route.params.info) {
          obj.info = this.$route.params.info
        } else {
          obj.info = 'none'
        }
        this.$router.push('/erroGeral/' + obj.id + '/' + obj.info)
      }
    }
  }
}
)
</script>

<style scoped>
  .btn{
    position: absolute;
     right: 30px;
     bottom: 30px;
  }
    .tela{
      height: 100vh;
      width: 100vw;
    }
    .icon{
        font-size:20px
    }
    .btntxt{
        font-size: 12px;
line-height: 24px;
color: #FAFAFA;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.5px;
    }
    .title29{
font-size: 34px;
line-height: 123.5%;
letter-spacing: 0.25px;
color: rgba(0, 0, 0, 0.87);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;
    }
    .text29{
font-weight: 500;
font-size: 18px;
line-height: 157%;
letter-spacing: 0.1px;
color: rgba(0, 0, 0, 0.54);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 1;
margin: 0px 0px;
    }
    .CoHead{
    font-family: 'Co Headline Corp'
  }
    .card{
      padding-top: 20px;
      padding-left: 50px;
      padding-right: 50px;
        z-index: 99;
        position: absolute;
     right: 98px;
     top: 91px;
    }
    .standing{
position: absolute;
     left: 32px;
     bottom: 6px;

    }
    @media screen and (max-width: 740px) {
  .card {
    position: relative;
    margin-left: 110px;
    margin-right: 5%;
    width: 80% !important;
    align-content: center;
  }
 .standing{
   position: relative;
 }
 .title29{
font-size: 25px;
 }
 .text29{
   font-size: 15px;
   margin: 1px;
 }
 .btn{
   bottom: 10px;
 }
}
</style>
